import axios from "axios";
import { server } from "../_constants";

export const getListAttendeesAPI = token => {
  return axios.post(
    `${server}/graphql`,
    {
      query: `
        query {
            attendees {
                id
                email
                firstName
                lastName
                events {
                id
                eventName
            }
            surveys {
                id
                submittedOn
            }
            }
        }
          `
    },
    {
      headers: {
        Authorization: "bearer " + token,
        "Content-Type": "application/json"
      }
    }
  );
};

export const addAttendeeAPI = (data, token,userId) => {

  return axios.post(
    `${server}/graphql`,
    {
      query: `
      mutation (
        $email: String!, 
        $firstName: String,
        $lastName: String
        $event: ID!,
        $author:ID,
        $eventId:Int) {
      createAttendee(input: {
        data: {
          email: $email,
          firstName: $firstName,
          lastName: $lastName
            events:[$event],
           author:$author,
           event:$eventId
        }
      }) {
        attendee {
          id
          email
          firstName
          lastName
          events {
              id
              eventName
          
          }
        }
      }
    }
      `,
      variables: {
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        event: data.event,
        author:userId,
        eventId:parseInt(data.event)
      }
    },
    {
      headers: {
        Authorization: "bearer " + token,
        "Content-Type": "application/json"
      }
    }
  );
};

export const editAttendeeAPI = (data, token) => {
  return axios.post(
    `${server}/graphql`,
    {
      query: `
        mutation (
            $id: ID!,
            $email: String!, 
            $firstName: String,
            $lastName: String) {
        updateAttendee(input: {
            where: {
                id: $id
            }
            data: {
                email: $email,
                firstName: $firstName,
                lastName: $lastName
            }
            }) {
            attendee {
                email
                firstName
                lastName
                events {
                    id
                    eventName
                }
            }
            }
        }
        `,
      variables: {
        id: data.id,
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName
      }
    },
    {
      headers: {
        Authorization: "bearer " + token,
        "Content-Type": "application/json"
      }
    }
  );
};

export const unAssignAttendeeAPI = (attendID, eventID, token) => {
  return axios.post(
    `${server}/graphql`,
    {
      query: `
        mutation (
          $id: ID!,
          $event: ID!) {
        unassign (
            id: $id,
            event: $event        
          ) {    
            id
            email    
          }
        }
        `,
      variables: {
        id: attendID,
        event: eventID
      }
    },
    {
      headers: {
        Authorization: "bearer " + token,
        "Content-Type": "application/json"
      }
    }
  );
};

export const findAttendeeByEmailAPI = (email, token) => {
  return axios.post(
    `${server}/graphql`,
    {
      query: `
        query {
          attendees (
              where: {
                email_contains: "${email}"
              }
            ) {
              id
              email
              firstName
              lastName
              events {
                id
                eventName
            }
            surveys {
                id
            }
          }
        }
        `
    },
    {
      headers: {
        Authorization: "bearer " + token,
        "Content-Type": "application/json"
      }
    }
  );
};

export const sendemailAttendeeAPI = (attendID, eventID,email, token) => {
  return axios.post(
    `${server}/graphql`,
    {
      query: `
        mutation (
          $id: ID!,
          $event: ID!,
          $email:string) {
            surveyEmail (
            id: $id,
            event: $event,
            email: $email       
          ) {    
            id
            email    
          }
        }
        `,
      variables: {
        id: attendID,
        event: eventID,
        email:email
      }
    },
    {
      headers: {
        Authorization: "bearer " + token,
        "Content-Type": "application/json"
      }
    }
  );
};