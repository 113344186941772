export const invoicesConstants = {
  GET_INVOICES_LIST_REQUEST: "GET_INVOICES_LIST_REQUEST",
  GET_INVOICES_LIST_SUCCESS: "GET_INVOICES_LIST_SUCCESS",
  GET_INVOICES_LIST_ERROR: "GET_INVOICES_LIST_ERROR",

  CREATE_INVOICE_REQUEST: "CREATE_INVOICE_REQUEST",
  CREATE_INVOICE_SUCCESS: "CREATE_INVOICE_SUCCESS",
  CREATE_INVOICE_ERROR: "CREATE_INVOICE_ERROR",

  EDIT_INVOICE_REQUEST: "EDIT_INVOICE_REQUEST",
  EDIT_INVOICE_SUCCESS: "EDIT_INVOICE_SUCCESS",
  EDIT_INVOICE_ERROR: "EDIT_INVOICE_ERROR",

  APPROVE_INVOICE_REQUEST: "APPROVE_INVOICE_REQUEST",
  APPROVE_INVOICE_SUCCESS: "APPROVE_INVOICE_SUCCESS",
  APPROVE_INVOICE_ERROR: " APPROVE_INVOICE_ERROR",
  
  PROCESS_INVOICE_REQUEST: "PROCESS_INVOICE_REQUEST",
  PROCESS_INVOICE_SUCCESS: "PROCESS_INVOICE_SUCCESS",
  PROCESS_INVOICE_ERROR: "PROCESS_INVOICE_ERROR"
};
