import { invoicesConstants } from "../_constants";

const initialState = {
  invoices: [],
  pending: false,
  error: ""
};

export const invoices = (state = initialState, action) => {
  switch (action.type) {
    case invoicesConstants.GET_INVOICES_LIST_REQUEST:
      return {
        ...state,
        pending: true,
        error: ""
      };

    case invoicesConstants.GET_INVOICES_LIST_SUCCESS:
      return {
        ...state,
        invoices: action.payload,
        pending: false,
        error: ""
      };

    case invoicesConstants.GET_INVOICES_LIST_ERROR:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    case invoicesConstants.CREATE_INVOICE_REQUEST:
      return {
        ...state,
        pending: true,
        error: ""
      };

    case invoicesConstants.CREATE_INVOICE_SUCCESS:
      return {
        ...state,
        invoices: action.payload,
        pending: false,
        error: ""
      };

    case invoicesConstants.CREATE_INVOICE_ERROR:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    case invoicesConstants.EDIT_INVOICE_REQUEST:
      return {
        ...state,
        pending: true,
        error: ""
      };

    case invoicesConstants.EDIT_INVOICE_SUCCESS:
      return {
        ...state,
        invoices: action.payload,
        pending: false,
        error: ""
      };

    case invoicesConstants.EDIT_INVOICE_ERROR:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

      case invoicesConstants.PROCESS_INVOICE_REQUEST:
      return {
        ...state,
        pending: true,
        error: ""
      };

    case invoicesConstants.PROCESS_INVOICE_SUCCESS:
      return {
        ...state,
        invoices: action.payload,
        pending: false,
        error: ""
      };

    case invoicesConstants.PROCESS_INVOICE_ERROR:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

      case invoicesConstants.APPROVE_INVOICE_REQUEST:
      return {
        ...state,
        pending: true,
        error: ""
      };

    case invoicesConstants.APPROVE_INVOICE_SUCCESS:
      return {
        ...state,
        invoices: action.payload,
        pending: false,
        error: ""
      };

    case invoicesConstants.APPROVE_INVOICE_ERROR:
      return {
        ...state,
        pending: false,
        error: action.payload
      };
    default:
      return {
        ...state
      };
  }
};
