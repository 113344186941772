import { pagesConstants } from "../_constants";
import { getPageByIDAPI } from "../_api/pages";
import { parseGraphQLError } from "../_helpers/errors";

export const getPageByID = id => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch({ type: pagesConstants.GET_PAGE_BY_ID_REQUEST });

    getPageByIDAPI(id)
      .then(data => {
        dispatch({
          type: pagesConstants.GET_PAGE_BY_ID_SUCCESS
        });

        resolve(data.data.data.page);
      })
      .catch(error => {
        let errorMessage = parseGraphQLError(error);

        dispatch({
          type: pagesConstants.GET_PAGE_BY_ID_ERROR,
          payload: errorMessage
        });

        reject(errorMessage);
      });
  });
};
