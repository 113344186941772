import { combineReducers } from "redux";
import { user } from "./user.reducer";
import { events } from "./events.reducer";
import { practices } from "./practices.reducer";
import { resources } from "./resources.reducer";
import { users } from "./users.reducer";
import { invoices } from "./invoices.reducer";
import { attendees } from "./attendees.reducer";
import { pages } from "./pages.reducer";
import { surveys } from "./survey.reducer";
export default combineReducers({
  user,
  events,
  practices,
  resources,
  users,
  invoices,
  attendees,
  pages,
  surveys
});
