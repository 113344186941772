import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Button,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Paper
} from "@material-ui/core";
import { Info, Edit, LocationOn, Print, Image } from "@material-ui/icons";

import { Link, useParams,useHistory } from "react-router-dom";
import { connect } from "react-redux";

import moment from "moment";

import { EventsMap } from "../../../components/Map";

import { Attendee } from "../../../components/Attendee";
import { getEventByID } from "../../../_actions/events.actions";
import { getListPractices } from "../../../_actions/practices.actions";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";

const ViewEvent = ({ getEventByID, user }) => {
  let { id } = useParams();
  const history = useHistory();
  useEffect(() => {
    const loadData = async () => {
      const event = await getEventByID(id, user.jwt);
     
      setEventData({
        id: event.data.data.event.id,
        eventName: event.data.data.event.eventName,
        expectedAttendees: event.data.data.event.expectedAttendees,
        eventStart: moment(event.data.data.event.eventStart).toDate(),
        additionalInfo: event.data.data.event.additionalInfo,
        eventDuration: event.data.data.event.eventDuration,
        attendees: event.data.data.event.attendees,
        invoice: event.data.data.event.invoice,
        status: event.data.data.event.status,

        email: event.data.data.event.practice.email,
        contactPhone: event.data.data.event.practice.contactPhone,
        contactPerson: event.data.data.event.practice.contactPerson,
        practiceName: event.data.data.event.practice.practiceName,
        address1: event.data.data.event.practice.address1,
        address2: event.data.data.event.practice.address2,
        practiceType: event.data.data.event.practice.practiceType,
        state: event.data.data.event.practice.state,
        city: event.data.data.event.practice.city,
        zip: event.data.data.event.practice.zip,
        latitude: event.data.data.event.practice.latitude,
        longitude: event.data.data.event.practice.longitude
      });
    };

    if (user.jwt) loadData();
  }, [getEventByID, id, user.jwt]);

  const [eventData, setEventData] = useState(null);

  const print = () => {
    const printContents = document.getElementById("print-data");
    const newWindow = window.open("");
    newWindow.document.write(printContents.outerHTML);
    newWindow.print();
    newWindow.close();
  }; 
 
  

  const direction = eventData
    ? eventData.latitude && eventData.longitude
      ? `${eventData.latitude},${eventData.longitude}`
      : eventData.address1
    : "";

  return (
    <React.Fragment>
      {eventData && (
        <Grid item xs={12} className="AddEvent">
          <Grid container justify="space-between">
            <Grid item xs={12} md={6}>
              <Typography variant="h5">{eventData.practiceName}</Typography>
              <Typography>
                {moment(eventData.eventStart).format("MM.DD.YYYY hh:mmA")}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} className="event-actions">
              {/* <a
                href={`https://www.google.com.ua/maps/dir//${direction}`}
                target="_blank"
                rel="noopener noreferrer"
                className="btn-link event-actions__btn"
                variant="contained"
                color="primary"
                style={{
                  display:
                    (eventData.latitude && eventData.longitude) ||
                    eventData.address1
                      ? "block"
                      : "none"
                }}
              > */}

<a  href={`https://www.google.com.ua/maps/dir//${direction}`}
                target="_blank"
                rel="noopener noreferrer"
                className="btn-link event-actions__btn"
                variant="contained"
                color="primary"
                style={{
                  display:
                    (eventData.latitude && eventData.longitude) ||
                    eventData.address1
                      ? "block"
                      : "none"
                }}>
              <Button variant="contained" color="primary">
              <LocationOn />
                  DIRECTIONS
              </Button>
            </a>
              
              {/* </a> */}
              {/* <Link to={`/editevent/${id}`} variant="contained"
                  color="primary" className="event-actions__btn btn-link"> */}
                <Button
                  variant="contained"
                  color="primary"
                  className="event-actions__btn"
                  onClick={() =>
                    history.push(`/editevent/${id}`)
                  }
                > 
                  <Edit />
                  EDIT
                </Button> 
              {/* </Link> */}
              <Button
                variant="contained"
                color="primary"
                className="event-actions__btn"
                onClick={() => print()}
              >
                <Print />
                PRINT
              </Button>
            </Grid>
          </Grid>
          <Grid container>
            <Typography variant="h6">Practice Information</Typography>
            <Grid container alignItems="flex-end">
              <Grid item xs={12} md={6} className="input-item">
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="select-practice-label">
                    Select Practice
                  </InputLabel>
                  <Select
                    labelId="select-practice-label"
                    id="select-practice"
                    fullWidth
                    value={eventData.practiceName}
                    disabled
                    variant="outlined"
                  >
                    <MenuItem value={eventData.practiceName}>
                      {eventData.practiceName}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} className="input-item">
                <TextField
                  id="new-practice"
                  fullWidth
                  label="Event name"
                  value={eventData.eventName}
                  disabled
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Grid container alignItems="flex-end">
              <Grid item xs={6} md={3} className="input-item">
                <TextField
                  id="address1"
                  fullWidth
                  label="Address 1"
                  value={eventData.address1}
                  disabled
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6} md={3} className="input-item">
                <TextField
                  id="address2"
                  fullWidth
                  label="Address 2"
                  value={eventData.address2}
                  disabled
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6} md={2} className="input-item">
                <TextField
                  id="city"
                  fullWidth
                  label="City"
                  value={eventData.city}
                  disabled
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6} md={2} className="input-item">
                <TextField
                  id="state"
                  fullWidth
                  label="State"
                  value={eventData.state}
                  disabled
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6} md={2} className="input-item">
                <TextField
                  id="zip"
                  fullWidth
                  label="Zip code"
                  value={eventData.zip}
                  disabled
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Grid container alignItems="flex-end">
              <Grid item xs={6} md={4} className="input-item">
                <TextField
                  id="email"
                  fullWidth
                  label="E-Mail"
                  value={eventData.email}
                  disabled
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6} md={4} className="input-item">
                <TextField
                  fullWidth
                  id="phone-number"
                  
                  value={eventData.contactPhone}
                  disabled
                  variant="outlined"
                  placeholder="+1 (___)__-__-___-__"
                  label="Contact phone"
                />
              </Grid>
              <Grid item xs={6} md={4} className="input-item">
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="practice-type-label">
                    Practice Type
                  </InputLabel>
                  <Select
                    labelId="practice-type-label"
                    id="practice-type"
                    fullWidth
                    value={eventData.practiceType}
                    disabled
                  >
                   <MenuItem value={"Endodontist"}>Endodontist</MenuItem>
                  <MenuItem value={"GeneralDentistry"}>General Dentistry</MenuItem>
                  <MenuItem value={"OralSurgeonPathologist"}>Oral Surgeon Pathologist</MenuItem>
                  <MenuItem value={"Orthodontist"}>Orthodontist</MenuItem>
                  <MenuItem value={"PediatricDentistry"}>Pediatric Dentistry</MenuItem>
                  <MenuItem value={"Prosthodontist"}>Prosthodontist</MenuItem>
                  <MenuItem value={"DDSDMDStudent"}>DDS/DMD Student</MenuItem>
                  <MenuItem value={"RDHStudent"}>RDH Student</MenuItem>
                  <MenuItem value={"Other"}>Other</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Typography variant="h6">Contact Person</Typography>
            <Grid container alignItems="flex-end">
              <Grid item xs={12} md={6} className="input-item">
                <TextField
                  id="full-name"
                  fullWidth
                  label="Full Name"
                  value={eventData.contactPerson}
                  disabled
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Typography variant="h6">Map</Typography>
            <EventsMap
              selectedEvent={{
                ...eventData,
                practice: {
                  address1: eventData.address1,
                  address2: eventData.address2,
                  city: eventData.city
                }
              }} 
            />
          </Grid>
          <Grid container>
            <Typography variant="h6">Event Information</Typography>
            <Grid container>
              <Grid item xs={12} md={3} className="input-item">
                <TextField
                  id="NumberExpectedAttendees"
                  fullWidth
                  label="Number of expected Attendees"
                  value={eventData.expectedAttendees}
                  disabled
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} md={3} className="input-item">
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="event-duraton-label">
                    Duration of Event
                  </InputLabel>
                  <Select
                    labelId="event-duraton-label"
                    id="event-duraton"
                    fullWidth
                    value={eventData.eventDuration}
                    disabled
                  >
                    <MenuItem value={"h05"}>0.5 hours</MenuItem>
                    <MenuItem value={"h1"}>1 hour</MenuItem>
                    <MenuItem value={"h2"}>2 hours</MenuItem>
                    <MenuItem value={"h3"}>3 hours</MenuItem>
                    <MenuItem value={"h4"}>4 hours</MenuItem>
                    <MenuItem value={"h5"}>5 hours</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3} className="input-item">
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="status-label">Status</InputLabel>
                  <Select
                    labelId="status-label"
                    id="status"
                    fullWidth
                    value={eventData.status ? eventData.status : "open"}
                    disabled
                    variant="outlined"
                  >
                    <MenuItem
                      value={eventData.status ? eventData.status : "open"}
                    >
                      {eventData.status ? eventData.status : "open"}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3} className="input-item">
                <DatePicker
                  selected={eventData.eventStart}
                  disabled
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  timeCaption="time"
                  dateFormat="MMMM d, yyyy h:mm aa"
                />
              </Grid>

            </Grid>
            <Grid container>
            <Grid item xs={12} md={3} className="input-item">
                <TextField
                  id="Attendees"
                  fullWidth
                  label="Actual Attendees / Expected Attendees "
                  value=  {user.user.role.type==="manager"? eventData.attendees.length+"/"+eventData.expectedAttendees:eventData.attendees.length} 
                  disabled
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container alignItems="flex-end">
            <Typography variant="h6">Additional Information</Typography>
            <Grid container>
              <Grid item xs={12} className="input-item">
                <TextField
                  id="additional-information"
                  multiline
                  fullWidth
                  rows="4"
                  value={eventData.additionalInfo}
                  disabled
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </Grid>
          {eventData.attendees.length > 0 && (
            <Grid container alignItems="flex-end">
              <Typography variant="h6">Attendees</Typography>
              <Grid container className="Attendees__titles">
                <Grid item xs={3} md={3} className="input-item">
                  <Typography>First Name</Typography>
                </Grid>
                <Grid item xs={3} md={3} className="input-item">
                  <Typography>Last Name</Typography>
                </Grid>
                <Grid item xs={3} md={3} className="input-item">
                  <Typography>E-mail</Typography>
                </Grid>
                <Grid item xs={3} md={3} className="input-item">
                  <Typography>Survey</Typography>
                </Grid>
              </Grid>
              {eventData.attendees.map((attendee, index) => {
                return (
                  <Attendee attendee={{ ...attendee }} disabled key={index} />
                );
              })}
            </Grid>
          )}
          <Grid container>
            <Typography variant="h6">Event cost</Typography>
            <Grid container alignItems="flex-end">
              <Grid item xs={12} md={6} className="input-item">
                <TextField
                  id="finance"
                  fullWidth
                  label="Total cost of Event"
                  value={eventData.invoice ? eventData.invoice.lunchCost : 0}
                  disabled
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container className="message">
            <Paper className="message__paper">
              <div className="message__paper_icon">
                <Info />
              </div>
              <Grid container justify="center" alignItems="center">
                <Grid item xs={10} md={6} className="upload-container">
                  {(eventData.invoice &&
                    !eventData.invoice.receipts.length > 0) ||
                  !eventData.invoice ? (
                    <Typography align="center">
                      No receipts have been added for this event.
                    </Typography>
                  ) : null}
                  <Grid container className="FinanceModal__container">
                    {eventData.invoice &&
                      eventData.invoice.receipts &&
                      [...eventData.invoice.receipts].map(file => {
                        return (
                          <Grid
                            item
                            xs={4}
                            key={file.name}
                            className="FinanceModal__container_item"
                          >
                            <Grid container className="FinanceModal__file">
                              <Grid item xs={12}>
                                <div className="FinanceModal__file_container">
                                  <Image />
                                </div>
                              </Grid>
                              <Grid item xs={12}>
                                <Typography variant="body1" noWrap>
                                  {file.name}
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Typography>{file.size}kb</Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        );
                      })}
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid container justify="flex-end">
            <Link to={`/editevent/${id}`} className="btn-link">
              <Button variant="contained" color="primary" className="btn">
                <Edit />
                EDIT EVENT
              </Button>
            </Link>
          </Grid>
          <div id="print-data">
            <table style={{ border: "1px solid #ddd", width: "100%" }}>
              <tbody>
                <tr>
                  <td
                    style={{
                      border: "1px solid #ddd",
                      padding: 8,
                      width: "50%"
                    }}
                  >
                    <b>Practice</b>
                  </td>
                  <td
                    style={{
                      border: "1px solid #ddd",
                      padding: 8,
                      width: "50%"
                    }}
                  >
                    {eventData.practiceName}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: "1px solid #ddd",
                      padding: 8,
                      width: "50%"
                    }}
                  >
                    <b>Event name</b>
                  </td>
                  <td
                    style={{
                      border: "1px solid #ddd",
                      padding: 8,
                      width: "50%"
                    }}
                  >
                    {eventData.eventName}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: "1px solid #ddd",
                      padding: 8,
                      width: "50%"
                    }}
                  >
                    <b>Address 1</b>
                  </td>
                  <td
                    style={{
                      border: "1px solid #ddd",
                      padding: 8,
                      width: "50%"
                    }}
                  >
                    {eventData.address1}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: "1px solid #ddd",
                      padding: 8,
                      width: "50%"
                    }}
                  >
                    <b>Address 2</b>
                  </td>
                  <td
                    style={{
                      border: "1px solid #ddd",
                      padding: 8,
                      width: "50%"
                    }}
                  >
                    {eventData.address2}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: "1px solid #ddd",
                      padding: 8,
                      width: "50%"
                    }}
                  >
                    <b>City</b>
                  </td>
                  <td
                    style={{
                      border: "1px solid #ddd",
                      padding: 8,
                      width: "50%"
                    }}
                  >
                    {eventData.city}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: "1px solid #ddd",
                      padding: 8,
                      width: "50%"
                    }}
                  >
                    <b>State</b>
                  </td>
                  <td
                    style={{
                      border: "1px solid #ddd",
                      padding: 8,
                      width: "50%"
                    }}
                  >
                    {eventData.state}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: "1px solid #ddd",
                      padding: 8,
                      width: "50%"
                    }}
                  >
                    <b>Zip code</b>
                  </td>
                  <td
                    style={{
                      border: "1px solid #ddd",
                      padding: 8,
                      width: "50%"
                    }}
                  >
                    {eventData.zip}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: "1px solid #ddd",
                      padding: 8,
                      width: "50%"
                    }}
                  >
                    <b>E-Mail</b>
                  </td>
                  <td
                    style={{
                      border: "1px solid #ddd",
                      padding: 8,
                      width: "50%"
                    }}
                  >
                    {eventData.email}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: "1px solid #ddd",
                      padding: 8,
                      width: "50%"
                    }}
                  >
                    <b>Contact phone</b>
                  </td>
                  <td
                    style={{
                      border: "1px solid #ddd",
                      padding: 8,
                      width: "50%"
                    }}
                  >
                    {eventData.contactPhone}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: "1px solid #ddd",
                      padding: 8,
                      width: "50%"
                    }}
                  >
                    <b>Practice Type</b>
                  </td>
                  <td
                    style={{
                      border: "1px solid #ddd",
                      padding: 8,
                      width: "50%"
                    }}
                  >
                    {eventData.practiceType}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: "1px solid #ddd",
                      padding: 8,
                      width: "50%"
                    }}
                  >
                    <b>Contact Person</b>
                  </td>
                  <td
                    style={{
                      border: "1px solid #ddd",
                      padding: 8,
                      width: "50%"
                    }}
                  >
                    {eventData.contactPerson}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: "1px solid #ddd",
                      padding: 8,
                      width: "50%"
                    }}
                  >
                    <b>Number of expected Attendees</b>
                  </td>
                  <td
                    style={{
                      border: "1px solid #ddd",
                      padding: 8,
                      width: "50%"
                    }}
                  >
                    {eventData.expectedAttendees}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: "1px solid #ddd",
                      padding: 8,
                      width: "50%"
                    }}
                  >
                    <b>Duration of Event</b>
                  </td>
                  <td
                    style={{
                      border: "1px solid #ddd",
                      padding: 8,
                      width: "50%"
                    }}
                  >
                    {eventData.eventDuration}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: "1px solid #ddd",
                      padding: 8,
                      width: "50%"
                    }}
                  >
                    <b>Date and Time of Event</b>
                  </td>
                  <td
                    style={{
                      border: "1px solid #ddd",
                      padding: 8,
                      width: "50%"
                    }}
                  >
                    {moment(eventData.eventStart).format("MM.DD.YYYY hh:mmA")}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: "1px solid #ddd",
                      padding: 8,
                      width: "50%"
                    }}
                  >
                    <b>Additional Information</b>
                  </td>
                  <td
                    style={{
                      border: "1px solid #ddd",
                      padding: 8,
                      width: "50%"
                    }}
                  >
                    {eventData.additionalInfo}
                  </td>
                </tr>
                {eventData.attendees.length > 0 && (
                  <tr>
                    <td
                      style={{
                        border: "1px solid #ddd",
                        padding: 8,
                        width: "50%"
                      }}
                    >
                      <b>Attendees</b>
                    </td>
                    <td
                      style={{
                        border: "1px solid #ddd",
                        padding: 8,
                        width: "50%"
                      }}
                    >
                      <table style={{ border: "1px solid #ddd" }}>
                        <tbody>
                          <tr
                            style={{ border: "1px solid #ddd", width: "100%" }}
                          >
                            <td
                              style={{
                                border: "1px solid #ddd",
                                width: "25%"
                              }}
                            >
                              <b>First Name</b>
                            </td>
                            <td
                              style={{
                                border: "1px solid #ddd",
                                width: "25%"
                              }}
                            >
                              <b>Last Name</b>
                            </td>
                            <td
                              style={{
                                border: "1px solid #ddd",
                                width: "25%"
                              }}
                            >
                              <b>E-mail</b>
                            </td>
                            <td
                              style={{
                                border: "1px solid #ddd",
                                width: "25%"
                              }}
                            >
                              <b>Survey</b>
                            </td>
                          </tr>
                          {eventData.attendees.map((attendee, index) => {
                            return (
                              <tr key={index} style={{ width: "100%" }}>
                                <td
                                  style={{
                                    border: "1px solid #ddd",
                                    width: "25%"
                                  }}
                                >
                                  {attendee.firstName}
                                </td>
                                <td
                                  style={{
                                    border: "1px solid #ddd",
                                    width: "25%"
                                  }}
                                >
                                  {attendee.lastName}
                                </td>
                                <td
                                  style={{
                                    border: "1px solid #ddd",
                                    width: "25%"
                                  }}
                                >
                                  {attendee.email}
                                </td>
                                <td
                                  style={{
                                    border: "1px solid #ddd",
                                    width: "25%"
                                  }}
                                >
                                  {attendee.surveys.length > 0
                                    ? moment(
                                        attendee.surveys[0].submittedOn
                                      ).format("MM.DD.YYYY")
                                    : 0}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </td>
                  </tr>
                )}
                <tr>
                  <td
                    style={{
                      border: "1px solid #ddd",
                      padding: 8,
                      width: "50%"
                    }}
                  >
                    <b>Total cost of Event</b>
                  </td>
                  <td
                    style={{
                      border: "1px solid #ddd",
                      padding: 8,
                      width: "50%"
                    }}
                  >
                    {eventData.invoice ? eventData.invoice.totalCost : 0}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Grid>
      )}
    </React.Fragment>
  );
};

const mapDispatchToProps = {
  getEventByID: getEventByID,
  getListPractices: getListPractices
};

const mapStateToProps = state => {
  const { user, practices } = state;
  return { user, practices };
};

const connectedViewEvent = connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewEvent);
export { connectedViewEvent as ViewEvent };
