import React from "react";
import { Grid, AppBar, Toolbar, Button } from "@material-ui/core";
import { AccountCircle } from "@material-ui/icons";
import { DropDownButton } from "../DropDownButton";
import { Link } from "react-router-dom";

import { userType } from "../../_constants/user.constants";

const educatorMenu = [
  {
    name: "Events",
    subMenu: [
      {
        name: "View Events",
        to: "/listevents"
      },
      {
        name: "Add Event",
        to: "/addevent"
      },
      {
        name: "Attendees",
        to: "/attendees"
      },
      {
        name: "Event Map",
        to: "/map"
      },
      {
        name: "Event Calendar",
        to: "/calendar"
      }
    ]
  },
  {
    name: "PRACTICES",
    subMenu: [
      {
        name: "View Practices",
        to: "/listpractices"
      },
      {
        name: "Add Practice",
        to: "/addpractice"
      }
    ]
  },
  {
    name: "RESOURCES",
    to: "/resourceslist"
  }
];

const managerMenu = [
  {
    name: "Events",
    subMenu: [
      {
        name: "View Events",
        to: "/listevents"
      },
      {
        name: "Add Event",
        to: "/addevent"
      },
      {
        name: "Attendees",
        to: "/attendees"
      },
      {
        name: "Event Map",
        to: "/map"
      },
      {
        name: "Event Calendar",
        to: "/calendar"
      }
    ]
  },
  {
    name: "PRACTICES",
    subMenu: [
      {
        name: "View Practices",
        to: "/listpractices"
      },
      {
        name: "Add Practice",
        to: "/addpractice"
      }
    ]
  },
  {
    name: "RESOURCES",
    to: "/resourceslist"
  },
  {
    name: "USERS",
    subMenu: [
      
      {
        name: "View Users",
        to: "/userslist"
      },
      {
        name: "Add User",
        to: "/adduser"
      }
    ]
  },
  {
    name: "INVOICES",
    to: "/invoices"
  },
  {
    name: "EMAIL",
    to: "/emails"
  }
];

export const Header = ({
  openLoginModal,
  isAuth,
  username,
  authUserType,
  logout,
  isLogin
}) => {
  const isSurvey = window.location.href.includes('/survey');
  const isThankyou = window.location.href.includes('/Thankyou');

  const changepassword = () => {
    window.open('/#/changepassword/');
  };
 
  return (
    <AppBar position="static" color="default" className="Header">
      <Toolbar>
        <Grid container justify="space-between">
            <Link to="/dashboard">
              <img src={require('../../assets/logo.png')} alt="Logo" width="163" />
            </Link>
          <nav>
            {isAuth && isLogin ? (
              <React.Fragment>
                {authUserType === userType.EDUCATOR &&
                  educatorMenu.map((menuItem, index) =>
                    menuItem.subMenu ? (
                      <DropDownButton
                        name={menuItem.name}
                        options={menuItem.subMenu}
                        key={index}
                      />
                    ) : (
                      <Link to={menuItem.to} key={index}>
                        <Button color="inherit">{menuItem.name}</Button>
                      </Link>
                    )
                  )}
                {authUserType === userType.MANAGER &&
                  managerMenu.map((menuItem, index) =>
                    menuItem.subMenu ? (
                      <DropDownButton
                        name={menuItem.name}
                        options={menuItem.subMenu}
                        key={index}
                      />
                    ) : (
                      <Link to={menuItem.to} key={index}>
                        <Button color="inherit">{menuItem.name}</Button>
                      </Link>
                    )
                  )}
                <DropDownButton
                  name={
                    <div className="user-btn">
                      <AccountCircle />{" "}
                      <span className="user-btn__name">{username}</span>
                    </div>
                  }
                  options={[
                    {
                      name: "Change Password",
                      click: changepassword

                    },
                    {
                      name: "Log out",
                      click: logout
                    }
                  ]}
                />
              </React.Fragment>
            ) : !isSurvey && !isThankyou   ? (
              <Button color="inherit" onClick={openLoginModal}>
                Login
              </Button>
            ) : (<></>)}
          </nav>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};
