import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Grid, Typography } from "@material-ui/core";
import { getPageByID } from "../../_actions/pages.actions";


const Home = ({ pages, getPageByID }) => {
  const [page, setPage] = useState({});

  useEffect(() => {
    const loadPage = async () => {
      const loadedPage = await getPageByID(1);
      setPage(loadedPage);
    };

    loadPage();
  }, [getPageByID]);

  return (
    <React.Fragment>
      <Grid container item xs={12} className="Home">
      <Grid container >
              <Grid  item xs={6} className="input-item">
              <Typography variant="h5">
            {pages.pending ? "Loading..." :page.title}
          </Typography>
          <Typography>{pages.pending ? "Loading..." : page.text}</Typography>
          </Grid>
          <Grid item xs={6} className="input-item">
        <img src={require('./image.png')} alt="Home" width="100%" /> </Grid>
        </Grid >
      </Grid>
    </React.Fragment>
  );
};

const mapDispatchToProps = {
  getPageByID: getPageByID
};

const mapStateToProps = state => {
  const { pages } = state;
  return { pages };
};

const connectedHome = connect(mapStateToProps, mapDispatchToProps)(Home);
export { connectedHome as Home };
