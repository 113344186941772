import { surveysConstants } from "../_constants";
import {
    getListSurveysAPI,
 getSurveyByIDAPI,
 addSurveyAPI
} from "../_api/surveys";
import { parseGraphQLError } from "../_helpers/errors";

export const getListSurveys = token => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch({ type: surveysConstants.GET_SURVEY_LIST_REQUEST });

    getListSurveysAPI(token)
      .then(data => {
        dispatch({
          type: surveysConstants.GET_SURVEY_LIST_SUCCESS,
          payload: data.data.data.surveys
        });

        resolve();
      })
      .catch(error => {
        let errorMessage = parseGraphQLError(error);

        dispatch({
          type: surveysConstants.GET_SURVEY_LIST_ERROR,
          payload: errorMessage
        });

        reject();
      });
  });
};
export const addSurveys = (mutation, token) => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch({ type: surveysConstants.ADD_SURVEY_REQUEST });

    addSurveyAPI(mutation, token)
      .then(() => {
        dispatch({
          type: surveysConstants.ADD_SURVEY_SUCCESS
        });

        resolve();
      })
      .catch(error => {
        let errorMessage = parseGraphQLError(error);

        dispatch({
          type: surveysConstants.ADD_SURVEY_ERROR,
          payload: errorMessage
        });

        reject(errorMessage);
      });
  });
};
export const getSurveysByID = (surveyID, token) => dispatch => {
    return new Promise((resolve, reject) => {
      dispatch({ type: surveysConstants.GET_SURVEY_BY_ID_REQUEST });
  
      getSurveyByIDAPI(surveyID, token)
        .then(data => {
          dispatch({
            type: surveysConstants.GET_SURVEY_BY_ID_SUCCESS
          });
  
          resolve(data);
        })
        .catch(error => {
          let errorMessage = parseGraphQLError(error);
  
          dispatch({
            type: surveysConstants.GET_SURVEY_BY_ID_ERROR,
            payload: errorMessage
          });
  
          reject(errorMessage);
        });
    });
};

  
