const parseGraphQLError = errorObj => {
  let errorMessage = "";

  if (errorObj.request.status !== 200) {
    errorObj.response.data.message &&
      errorObj.response.data.message.forEach(message => {
        message.messages.forEach(error => {
          errorMessage += error.message;
        });
      });

    errorObj.response.data.errors &&
      errorObj.response.data.errors.forEach(error => {
        errorMessage += error.message;
      });
  }

  console.log(errorMessage);

  return errorMessage;
};

export { parseGraphQLError };
