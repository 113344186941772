import { usersConstants } from "../_constants";
import {
  getListUsersAPI,
  getUserByIDAPI,
  addNewUserAPI,
  editUserAPI,
  deleteUserAPI,
  getListUsersPublicAPI,
  getUserByEmail
} from "../_api/users"; 
import { parseGraphQLError } from "../_helpers/errors";

export const getUsersList = token => dispatch => {
  dispatch({ type: usersConstants.GET_USERS_LIST_REQUEST });

  getListUsersAPI(token)
    .then(data => {
      dispatch({
        type: usersConstants.GET_USERS_LIST_SUCCESS,
        payload: data.data.data.users
      });
    })
    .catch(error => {
      let errorMessage = parseGraphQLError(error);

      dispatch({
        type: usersConstants.GET_USERS_LIST_ERROR,
        payload: errorMessage
      });
    });
};

export const getUsersPublicList = () => dispatch => {
  dispatch({ type: usersConstants.GET_USERS_LIST_REQUEST });

  getListUsersPublicAPI()
    .then(data => {
      dispatch({
        type: usersConstants.GET_USERS_LIST_SUCCESS,
        payload: data.data.data.users
      });
  
    })
    .catch(error => {
      let errorMessage = parseGraphQLError(error);

      dispatch({
        type: usersConstants.GET_USERS_LIST_ERROR,
        payload: errorMessage
      });
    });
};

export const getUserByID = (userID, token) => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch({ type: usersConstants.GET_USER_BY_ID_REQUEST });

    getUserByIDAPI(userID, token)
      .then(data => {
        dispatch({
          type: usersConstants.GET_USER_BY_ID_SUCCESS
        });

        resolve(data.data.data.user);
      })
      .catch(error => {
        let errorMessage = parseGraphQLError(error);

        dispatch({
          type: usersConstants.GET_USER_BY_ID_ERROR,
          payload: errorMessage
        });

        reject(errorMessage);
      });
  });
};


export const UserByEmail = (data, token) => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch({ type: usersConstants.GET_USER_BY_Email_REQUEST });

    getUserByEmail(data, token)
      .then(data => {
        dispatch({
          type: usersConstants.GET_USER_BY_Email_SUCCESS
        });

if(data.data.data.users.length>0)
{
         resolve(data.data.data.users[0].email);
}
else
{
  resolve(null);
}
      })
      .catch(error => {
        let errorMessage = parseGraphQLError(error);

        dispatch({
          type: usersConstants.GET_USER_BY_Email_ERROR,
          payload: errorMessage
        });

        reject(errorMessage);
      });
  });
};

export const addNewUser = (mutation, token) => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch({ type: usersConstants.ADD_NEW_USER_REQUEST });

    addNewUserAPI(mutation, token)
      .then(() => {
        dispatch({
          type: usersConstants.ADD_NEW_USER_SUCCESS
        });

        resolve();
      })
      .catch(error => {
        let errorMessage = parseGraphQLError(error);

        dispatch({
          type: usersConstants.ADD_NEW_USER_ERROR,
          payload: errorMessage
        });

        reject(errorMessage);
      });
  });
};

export const editNewUser = (data, token) => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch({ type: usersConstants.EDIT_USER_REQUEST });

    editUserAPI(data, token)
      .then(() => {
        dispatch({
          type: usersConstants.EDIT_USER_SUCCESS
        });

        resolve();
      })
      .catch(error => {
        let errorMessage = parseGraphQLError(error);

        dispatch({
          type: usersConstants.EDIT_USER_ERROR,
          payload: errorMessage
        });

        reject(errorMessage);
      });
  });
};

export const deleteUser = (userID, token) => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch({ type: usersConstants.DELETE_USER_REQUEST });

    deleteUserAPI(userID, token)
      .then(data => {
        dispatch({
          type: usersConstants.DELETE_USER_SUCCESS
        });

        resolve(data);
      })
      .catch(error => {
        let errorMessage = parseGraphQLError(error);

        dispatch({
          type: usersConstants.DELETE_USER_ERROR,
          payload: errorMessage
        });

        reject(errorMessage);
      });
  });
};
