import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import {
  Grid,
  Typography,
  Button,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel
} from "@material-ui/core";
// import { Close } from "@material-ui/icons";
import { ModalAlert } from "../../../components/Modal";

import {
  getListPractices,
  updatePractice,
  archivePractice
} from "../../../_actions/practices.actions";

const EditPractice = ({
  getListPractices,
  updatePractice,
  archivePractice,
  user,
  practices
}) => {
  let { id } = useParams();
  const history = useHistory();

  const [practiceData, setPracticeData] = useState(null);

  useEffect(() => {
    const loadPracticesList = () => {
      getListPractices(user.jwt);
    };

    if (user.jwt) loadPracticesList();
  }, [getListPractices, user.jwt]);

  if (!practiceData && practices.practices.length > 0) {
    const practiceID = parseInt(id);
    const currentPractice = practices.practices.find(practice => {
      if (Number(practice.id) === practiceID) return true;
      return false;
    });

    setPracticeData(currentPractice);
  }
  const [modalAlert, setModalAlert] = useState({
    open: false
  });

  const sendResults = async () => {
    try {
      if (isValidForm()) {
        await updatePractice(practiceData, user.jwt, user.user.id);
        setModalAlert({
          open: true,
          success: true,
          title: "SUCCESS!",
          message: "The Practice has been edited."
        });
      }
      else
      {
        setModalAlert({
          open: true,
          success: false,
          title: "WARNING!",
          message: "Not all the fields are field in"
        });
      }
    } catch (error) {
      setModalAlert({
        open: true,
        success: false,
        title: "ERROR!",
        message: error
      });
    }
  };

  // const setArchivePracticeStatus = () => {
  //   archivePractice(id, true, user.jwt)
  //     .then(data => {
  //       const { practiceName } = data.data.data.updatePractice.practice;
  //       setModalAlert({
  //         open: true,
  //         success: false,
  //         title: "SUCCESS!",
  //         message: `"${practiceName}" in archive`
  //       })
  //       //alert(`"${practiceName}" in archive`);
  //     })
  //     .catch(err =>  setModalAlert({
  //       open: true,
  //       success: false,
  //       title: "ERROR!",
  //       message: err
  //     }));
  // };

  const [showEmptyFields, setShowEmptyFields] = useState(false);

  const isValidForm = () => {
    if (
      practiceData.practiceName &&
      practiceData.practiceType &&
      practiceData.contactPerson &&
      practiceData.contactPhone &&
      practiceData.email &&
      practiceData.address1 &&
      practiceData.city &&
      practiceData.state &&
      practiceData.zip
    ) {
      setShowEmptyFields(false);
      return true;
    } else {
      //alert("Not all the fields are field in");
      setShowEmptyFields(true);
      return false;
    }
  };

  return (
    <React.Fragment>
      {modalAlert.open && (
        <ModalAlert
          close={() => {
            setModalAlert({
              ...modalAlert,
              open: false
            });
            history.push("/listpractices");
          }}
          success={modalAlert.success}
          title={modalAlert.title}
          message={modalAlert.message}
          open={modalAlert.open}
        />
      )}
      {practiceData && (
        <Grid item xs={12} className="AddEvent">
          <Typography variant="h5">{practiceData.practiceName}</Typography>
          <Grid container>
            <Typography variant="h6">Practice Information</Typography>
            <Grid container alignItems="flex-end">
              <Grid item xs={12} md={6} className="input-item">
                <TextField
                  id="practice-name"
                  fullWidth
                  variant="outlined"
                  label="Practice name"
                  value={practiceData.practiceName}
                  error={!practiceData.practiceName && showEmptyFields}
                  onChange={e =>
                    setPracticeData({
                      ...practiceData,
                      practiceName: e.target.value
                    })
                  }
                />
              </Grid>
            </Grid>
            <Grid container alignItems="flex-end">
              <Grid item xs={6} md={3} className="input-item">
                <TextField
                  id="address1"
                  fullWidth
                  variant="outlined"
                  label="Address 1"
                  value={practiceData.address1}
                  error={!practiceData.address1 && showEmptyFields}
                  onChange={e =>
                    setPracticeData({
                      ...practiceData,
                      address1: e.target.value
                    })
                  }
                />
              </Grid>
              <Grid item xs={6} md={3} className="input-item">
                <TextField
                  id="address2"
                  fullWidth
                  variant="outlined"
                  label="Address 2 (optional)"
                  value={practiceData.address2}
                  onChange={e =>
                    setPracticeData({
                      ...practiceData,
                      address2: e.target.value
                    })
                  }
                />
              </Grid>
              <Grid item xs={6} md={2} className="input-item">
                <TextField
                  id="city"
                  fullWidth
                  variant="outlined"
                  label="City"
                  value={practiceData.city}
                  error={!practiceData.city && showEmptyFields}
                  onChange={e =>
                    setPracticeData({
                      ...practiceData,
                      city: e.target.value
                    })
                  }
                />
              </Grid>
              <Grid item xs={6} md={2} className="input-item">
                <TextField
                  id="state"
                  fullWidth
                  variant="outlined"
                  label="State"
                  value={practiceData.state}
                  error={!practiceData.state && showEmptyFields}
                  onChange={e =>
                    setPracticeData({
                      ...practiceData,
                      state: e.target.value
                    })
                  }
                />
              </Grid>
              <Grid item xs={6} md={2} className="input-item">
                <TextField
                  id="zip"
                  fullWidth
                  variant="outlined"
                  label="Zip code"
                  value={practiceData.zip}
                  error={!practiceData.zip && showEmptyFields}
                  onChange={e =>
                    setPracticeData({
                      ...practiceData,
                      zip: e.target.value
                    })
                  }
                />
              </Grid>
            </Grid>
            <Grid container alignItems="flex-end">
              <Grid item xs={6} md={4} className="input-item">
                <TextField
                  id="email"
                  fullWidth
                  variant="outlined"
                  label="E-Mail"
                  value={practiceData.email}
                  error={!practiceData.email && showEmptyFields}
                  onChange={e =>
                    setPracticeData({
                      ...practiceData,
                      email: e.target.value
                    })
                  }
                />
              </Grid>
              <Grid item xs={6} md={4} className="input-item">
                <TextField
                  fullWidth
                  variant="outlined"
                  id="phone-number"
                  label="Contact Phone"
                  value={practiceData.contactPhone}
                  error={!practiceData.contactPhone && showEmptyFields}
                  onChange={e =>
                    setPracticeData({
                      ...practiceData,
                      contactPhone: e.target.value
                    })
                  }
                  placeholder="+1 (___)__-__-___-__"
                />
              </Grid>
              <Grid item xs={6} md={4} className="input-item">
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="practice-type-label">
                    Practice Type
                  </InputLabel>
                  <Select
                    labelId="practice-type-label"
                    id="practice-type"
                    fullWidth
                    value={practiceData.practiceType}
                    error={!practiceData.practiceType && showEmptyFields}
                    onChange={e =>
                      setPracticeData({
                        ...practiceData,
                        practiceType: e.target.value
                      })
                    }
                  >
                     
                  <MenuItem value={"Endodontist"}>Endodontist</MenuItem>
                  <MenuItem value={"GeneralDentistry"}>General Dentistry</MenuItem>
                  <MenuItem value={"OralSurgeonPathologist"}>Oral Surgeon/Pathologist</MenuItem>
                  <MenuItem value={"Orthodontist"}>Orthodontist</MenuItem>
                  <MenuItem value={"PediatricDentistry"}>Prosthodontist</MenuItem>
                  <MenuItem value={"Prosthodontist"}>Prosthodontist</MenuItem>
                  <MenuItem value={"DDSDMDStudent"}>DDS/DMD Student</MenuItem>
                  <MenuItem value={"RDHStudent"}>RDH Student</MenuItem>
                  <MenuItem value={"Other"}>Other</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Typography variant="h6">Practice contact Person</Typography>
            <Grid container alignItems="flex-end">
              <Grid item xs={12} md={6} className="input-item">
                <TextField
                  id="full-name"
                  fullWidth
                  variant="outlined"
                  label="Full Name"
                  value={practiceData.contactPerson}
                  error={!practiceData.contactPerson && showEmptyFields}
                  onChange={e =>
                    setPracticeData({
                      ...practiceData,
                      contactPerson: e.target.value
                    })
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container justify="space-between">
            {/* <Button
              variant="contained"
              color="secondary"
              className="btn"
              onClick={setArchivePracticeStatus}
            >
              <Close />
              DELETE PRACTICE
            </Button> */}
            <Button
              variant="contained"
              color="primary"
              className="btn"
              onClick={sendResults}
            >
              SAVE
            </Button>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
};

const mapDispatchToProps = {
  getListPractices: getListPractices,
  updatePractice: updatePractice,
  archivePractice: archivePractice
};

const mapStateToProps = state => {
  const { practices, user } = state;
  return { practices, user };
};

const connectedEditPractice = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditPractice);
export { connectedEditPractice as EditPractice };
