import { eventsConstants } from "../_constants";
import {
  getListEvents,
  getEventByIDAPI,
  addEventAPI,
  editEventAPI,
  sendSurveyEmailAPI,
  DeleteEventAPI
 

} from "../_api/events";
import { parseGraphQLError } from "../_helpers/errors";
import { EmailsList} from "../_api/files.js";

export const getEventsList = token => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch({ type: eventsConstants.GET_EVENTS_LIST_REQUEST });

    getListEvents(token)
      .then(data => {
        
        dispatch({
          type: eventsConstants.GET_EVENTS_LIST_SUCCESS,
          payload: data.data.data.events
        });

        resolve(data.data.data.events);
      })
      .catch(error => {
        let errorMessage = parseGraphQLError(error);

        dispatch({
          type: eventsConstants.GET_EVENTS_LIST_ERROR,
          payload: errorMessage
        });

        reject(errorMessage);
      });
  });
};

export const getEventByID = (eventID, token) => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch({ type: eventsConstants.GET_EVENT_BY_ID_REQUEST });

    getEventByIDAPI(eventID, token)
      .then(data => {
        dispatch({
          type: eventsConstants.GET_EVENT_BY_ID_SUCCESS
        });

        resolve(data);
      })
      .catch(error => {
        let errorMessage = parseGraphQLError(error);

        dispatch({
          type: eventsConstants.GET_EVENT_BY_ID_ERROR,
          payload: errorMessage
        });

        reject(errorMessage);
      });
  });
};

export const addEvent = (mutation, token, id) => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch({ type: eventsConstants.ADD_NEW_EVENT_REQUEST });

    addEventAPI(mutation, token, id)
      .then(data => {
        dispatch({
          type: eventsConstants.ADD_NEW_EVENT_SUCCESS,
          payload: data.data.data.events
        });

        resolve();
      })
      .catch(error => {
        let errorMessage = parseGraphQLError(error);

        dispatch({
          type: eventsConstants.ADD_NEW_EVENT_ERROR,
          payload: errorMessage
        });

        reject(errorMessage);
      });
  });
};

export const editEvent = (mutation, token, id) => dispatch => {
 
  return new Promise((resolve, reject) => {
    dispatch({ type: eventsConstants.EDIT_EVENT_REQUEST });

    editEventAPI(mutation, token, id)
      .then(data => {
        dispatch({
          type: eventsConstants.EDIT_EVENT_SUCCESS,
          payload: data.data.data.events
        });

        resolve();
      })
      .catch(error => {
        let errorMessage = parseGraphQLError(error);

        dispatch({
          type: eventsConstants.EDIT_EVENT_ERROR,
          payload: errorMessage
        });

        reject(errorMessage);
      });
  });
  
};
export const sendSurveyEmail = (eventID, token) => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch({ type: eventsConstants.SURVEY_EVENT_REQUEST });

    sendSurveyEmailAPI(eventID, token)
      .then(data => {
        dispatch({
          type: eventsConstants.SURVEY_EVENT_SUCCESS
        });

        resolve(data);
      })
      .catch(error => {
        let errorMessage = parseGraphQLError(error);

        dispatch({
          type: eventsConstants.SURVEY_EVENT_ERROR,
          payload: errorMessage
        });

        reject(errorMessage);
      });
  });
};
export const deleteEventByID = (eventID, token) => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch({ type: eventsConstants.DELETE_EVENT_REQUEST });

    DeleteEventAPI(eventID, token)
      .then(data => {
        dispatch({
          type: eventsConstants.DELETE_EVENT_SUCCESS
        });

        resolve(data);
      })
      .catch(error => {
        let errorMessage = parseGraphQLError(error);

        dispatch({
          type: eventsConstants.DELETE_EVENT_ERROR,
          payload: errorMessage
        });

        reject(errorMessage);
      });
  });
};
export const GetEmailList = () => dispatch => {
  return new Promise((resolve, reject) => {
 
    dispatch({ type: eventsConstants.EMAIL_LIST_REQUEST });

    EmailsList()
      .then(data => {
        dispatch({
          type: eventsConstants.EMAIL_LIST_SUCCESS,
          payload: data.data.data
        });
 
     
        resolve(data.data.data);
      
      })
      .catch(error => {
        let errorMessage = parseGraphQLError(error);
       
        dispatch({
          type: eventsConstants.EMAIL_LIST_ERROR,
          payload: errorMessage
        });

        reject(errorMessage);
      });
  });
};