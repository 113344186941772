export const usersConstants = {
  GET_USERS_LIST_REQUEST: "GET_USERS_LIST_REQUEST",
  GET_USERS_LIST_SUCCESS: "GET_USERS_LIST_SUCCESS",
  GET_USERS_LIST_ERROR: "GET_USERS_LIST_ERROR",

  GET_USER_BY_ID_REQUEST: "GET_USER_BY_ID_REQUEST",
  GET_USER_BY_ID_SUCCESS: "GET_USER_BY_ID_SUCCESS",
  GET_USER_BY_ID_ERROR: "GET_USER_BY_ID_ERROR",

  ADD_NEW_USER_REQUEST: "ADD_NEW_USER_REQUEST",
  ADD_NEW_USER_SUCCESS: "ADD_NEW_USER_SUCCESS",
  ADD_NEW_USER_ERROR: "ADD_NEW_USER_ERROR",

  EDIT_USER_REQUEST: "EDIT_USER_REQUEST",
  EDIT_USER_SUCCESS: "EDIT_USER_SUCCESS",
  EDIT_USER_ERROR: "EDIT_USER_ERROR",
  
DELETE_USER_REQUEST: "DELETE_USER_REQUEST",
DELETE_USER_SUCCESS: "DELETE_USER_SUCCESS",
DELETE_USER_ERROR: "DELETE_USER_ERROR",
GET_USER_BY_Email_REQUEST: "GET_USER_BY_Email_REQUEST",
GET_USER_BY_Email_SUCCESS: "GET_USER_BY_Email_SUCCESS",
GET_USER_BY_Email_ERROR: "GET_USER_BY_Email_ERROR"


};
