import axios from "axios";
import { server } from "../_constants";

export const  getListPracticesAPI  =  token => {
  // return axios.post(
  //   `${server}/graphql`,
  //   {
  //     query: `
  //       query {
  //         practices  {
  //           id
  //           practiceName
  //           author {
  //             id
  //             username
  //           }
  //           practiceType
  //           contactPerson
  //           contactPhone
  //           email
  //           address1
  //           address2
  //           SalesforceID
  //           city
  //           state
  //           zip
  //           latitude
  //           longitude
  //           archived
  //           events {
  //             id
  //             eventName
  //             eventStart
  //             attendees {
  //               id
  //             }
  //             contactPerson
             
  //           }
  //         }
  //       }
  //   `
  //   },
  //   {
  //     headers: {
  //       Authorization: "bearer " + token,
  //       "Content-Type": "application/json"
  //     }
  //   }
  // );

  return  axios.get(
    `${server}/practices`,
 
    {
      headers: {
        Authorization: "bearer " + token,
        "Content-Type": "application/json"
      }
    }
  );
};

export const addPracticeAPI = (data, token, id) => {
  return axios.post(
    `${server}/graphql`,
    {
      query: `
      mutation (
        $practiceName: String!, 
        $practiceType: ENUM_PRACTICE_PRACTICETYPE,
        $contactPerson: String, 
        $contactPhone: String, 
        $email: String, 
        $address1: String!, 
        $address2: String, 
        $city: String, 
        $state: String, 
        $zip: String) {
      createPractice(input: {
        data: {
          practiceName: $practiceName,
          practiceType: $practiceType,
          contactPerson: $contactPerson, 
          contactPhone: $contactPhone, 
          email: $email, 
          address1: $address1, 
          address2: $address2, 
          city: $city, 
          state: $state, 
          zip: $zip
        }
      }) {
        practice {
          id
          practiceName
          author {
            id
            username
          }
        }
      }
    }
    `,
      variables: {
        id: String(data.id),
        practiceName: data.practiceName,
        author: String(id),
        practiceType: data.practiceType,
        contactPerson: data.contactPerson,
        contactPhone: data.contactPhone,
        email: data.email,
        address1: data.address1,
        address2: data.address2,
        city: data.city,
        state: data.state,
        zip: data.zip
      }
    },
    {
      headers: {
        Authorization: "bearer " + token,
        "Content-Type": "application/json"
      }
    }
  );
};

export const updatePracticeAPI = (data, token, id) => {
  return axios.post(
    `${server}/graphql`,
    {
      query: `
      mutation (
        $id: ID!,
        $practiceName: String!, 
        $author: ID!,
        $practiceType: ENUM_PRACTICE_PRACTICETYPE,
        $contactPerson: String, 
        $contactPhone: String, 
        $email: String, 
        $address1: String, 
        $address2: String, 
        $city: String, 
        $state: String, 
        $zip: String) {
      updatePractice(input: {
        where: {
            id: $id
        }
        data: {
          practiceName: $practiceName,
          author: $author,
          practiceType: $practiceType,
          contactPerson: $contactPerson, 
          contactPhone: $contactPhone, 
          email: $email, 
          address1: $address1, 
          address2: $address2, 
          city: $city, 
          state: $state, 
          zip: $zip
        }
      }) {
        practice {
          practiceName
          author {
            id
            username
          }
        }
      }
    }
    `,
      variables: {
        id: String(data.id),
        practiceName: data.practiceName,
        author: String(id),
        practiceType: data.practiceType,
        contactPerson: data.contactPerson,
        contactPhone: data.contactPhone,
        email: data.email,
        address1: data.address1,
        address2: data.address2,
        city: data.city,
        state: data.state,
        zip: data.zip
      }
    },
    {
      headers: {
        Authorization: "bearer " + token,
        "Content-Type": "application/json"
      }
    }
  );
};

export const archivePracticeAPI = (practiceID, archiveStatus, token) => {
  return axios.post(
    `${server}/graphql`,
    {
      query: `
      mutation (
        $id: ID!,
        $archived: Boolean!) {
          updatePractice(input: {
        where: {
          id: $id
        }
        data: {
          archived: $archived
        }
      }) {
        practice {
          practiceName
          archived
          }
        }
      }
    `,
      variables: {
        id: String(practiceID),
        archived: archiveStatus
      }
    },
    {
      headers: {
        Authorization: "bearer " + token,
        "Content-Type": "application/json"
      }
    }
  );
};

export const updateGeoLocationAPI = (data, token) => {
console.log(data);
  return axios.post(
    `${server}/graphql`,
    {
      query: `
      mutation (
        $id: ID! ,
         $practiceName: String, 
        $practiceType: ENUM_PRACTICE_PRACTICETYPE,
        $contactPhone: String, 
        $address1: String, 
        $address2: String, 
        $city: String, 
        $state: String, 
        $zip: String,
        $salesforceID: String,
    ) {
      setGeoCodes (
        id: $id,
        practiceName: $practiceName,
          practiceType: $practiceType,
          contactPhone: $contactPhone, 
          address1: $address1, 
          address2: $address2, 
          city: $city, 
          state: $state,
          zip: $zip,
          salesforceID:  $salesforceID,
        ) {
          id,
           practiceName,
          practiceType,
          contactPhone,
          address1,
          address2 ,
          city,
          state,
          zip,
          latitude,
          longitude,
          SalesforceID
      }
    }
    `,
      variables: {
        id: data.id,
        practiceName: data.practiceName,     
        practiceType: data.practiceType,      
        contactPhone: data.contactPhone,      
        address1: data.address1,
        address2: data.address2,
        city: data.city,
        state: data.state,
        zip: data.zip,
        salesforceID: data.SalesforceID!=null?data.SalesforceID:""
      }
    },
    {
      headers: {
        Authorization: "bearer " + token,
        "Content-Type": "application/json"
      }
    }
   
  );
 
};
