import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { userLogin, userAuth, userLogout } from "./_actions/user.actions";
import { Grid } from "@material-ui/core";
import {
  Switch,
  Route,
  Redirect,
  useHistory,
  useLocation
} from "react-router-dom";
import { links } from "./_helpers/pages";

import { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import { Login } from "./containers/Login";

function App({ userAuth, user, userLogin, userLogout }) {
  const [showLoginModal, setShowLoginModal] = useState(false);

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
 
    const autoAuth = () => {
      userAuth();



      if( user.isAuth===true &&  location.pathname === "/"  )
       {
       
       
         if((user.user.IsLogin ===false||user.user.IsLogin===null))
         {
          
         location.pathname = "/ChangePassword";
         }
         else
         {
         location.pathname = "/Dashboard";
         }
      }
    };

    user.isAuth && setShowLoginModal(false);

    autoAuth();
    

    if (user.error) alert(user.error);
  }, [userAuth, user.isAuth, user.error, history, user.user.IsLogin, location]);

  return (
    <Grid container justify="center">
      <Login
        open={showLoginModal}
        close={() => setShowLoginModal(false)}
        userLogin={userLogin}
      />

      <Grid item lg={10} md={10}>
        <Header
          openLoginModal={() => setShowLoginModal(true)}
          isAuth={user.isAuth}
          authUserType={user.user.role.type}
          username={user.user.username}
          logout={userLogout}
           isLogin={user.user.IsLogin}
        />
        <Grid container className="main-container">
          <Switch>
            {links.map(({ component: Component, ...link }) => {
              return (
                <Route
                  key={link.to}
                  exact
                  path={link.to}
                  render={props => {
                    if (link.private) {
                      if (user.isAuth !== null) {
                        if (user.isAuth) {
                          const typeOfUser = user.user.role.type;
                          const arrayRolesForPage = link.roles;
                          const ifCanPageRender = arrayRolesForPage.includes(
                            typeOfUser
                          );

                          if (ifCanPageRender) {
                            return <Component {...props} />;
                          }
                     
                          return <Redirect to="/dashboard" />;
                        }

                        location.pathname !== "/" && setShowLoginModal(true);
                      
                        return <Redirect to="/" />;
                      }
                    }
                    return <Component {...props} />;
                  }}
                />
              );
            })}
          </Switch>
        </Grid>
        <Footer />
      </Grid>
    </Grid>
  );
}

const mapDispatchToProps = {
  userLogin: userLogin,
  userAuth: userAuth,
  userLogout: userLogout
};

const mapStateToProps = state => {
  const { user } = state;
  return { user };
};

const connectedApp = connect(mapStateToProps, mapDispatchToProps)(App);
export { connectedApp as App };
