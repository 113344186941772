import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Grid } from "@material-ui/core";
import MaterialTable from "material-table";
import { Edit, Visibility } from "@material-ui/icons";
import { Link } from "react-router-dom";

import { getUsersList } from "../../../../_actions/users.actions";

const UsersList = ({ user, users, getUsersList }) => {
  useEffect(() => {
    const loadUsersList = () => {
      getUsersList(user.jwt);
    };

    if (user.jwt) loadUsersList();
  }, [getUsersList, user.jwt]);

  return (
    <React.Fragment>
      <Grid item xs={12}>
        {users.users && users.users.length > 0 && (
        
          <MaterialTable
            title="Users"
            columns={[
              {
                title: "First Name",
                field: "firstName",
                sorting: true
              },
              {
                title: "Last name",
                field: "lastName",
                sorting: true
              },
              {
                title: "Email",
                field: "email",
                
                sorting: true
              },
              {
                title: "Phone",
                field: "phone",
                sorting: true
              },
              {
                title: "Address 1/2",
                field: "address",
                sorting: true
              },
              {
                title: "City",
                field: "city",
                sorting: true
              },
              {
                title: "State",
                field: "state",
                sorting: true
              },
              {
                title: "Territory",
                field: "Territory",
                sorting: true
              },
              {
                title: "Zip Code",
                field: "zip",
                sorting: true
              },
              {
                title: "Discount Code",
                field: "discount",
                sorting: true
              },
              {
                title: "Action",
                field: "action",
                filtering: false,
                render: tableData => {
               
                  return (
                    <React.Fragment>
                     
                      <Link to={`/edituser/${ tableData.id}`}>
                        <Edit />
                      </Link>
                      <Link to={`/viewuser/${tableData.id}`}>
                        <Visibility />
                      </Link>
                    </React.Fragment>
                  );
                },
                sorting: false
              }
            ]}
            data={users.users.map(user => {
              return {
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
                phone: user.contactPhone,
                address: user.address1 + " / "+ user.address2,
                city: user.city,
                Territory:user.Territory,
                state: user.state,
                zip: user.zip,
                discount: user.discountCode,
                action: "",
                id:user.id
              };
            })}
            options={{ 
              sorting: true, 
              draggable: false, 
              filtering: false,
              toolbar: true
            }}
          />
        )}
      </Grid>
    </React.Fragment>
  );
};

const mapDispatchToProps = {
  getUsersList: getUsersList
};

const mapStateToProps = state => {
  const { users, user } = state;
  return { users, user };
};

const connectedUsersList = connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersList);
export { connectedUsersList as UsersList };
