import { attendeesConstants } from "../_constants";

const initialState = {
  attendees: [],
  pending: false,
  error: ""
};

export const attendees = (state = initialState, action) => {
  switch (action.type) {
    case attendeesConstants.GET_ATTENDEES_LIST_REQUEST:
      return {
        ...state,
        pending: true,
        error: ""
      };

    case attendeesConstants.GET_ATTENDEES_LIST_SUCCESS:
      return {
        ...state,
        attendees: action.payload,
        pending: false,
        error: ""
      };

    case attendeesConstants.GET_ATTENDEES_LIST_ERROR:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    case attendeesConstants.ADD_ATTENDEE_REQUEST:
      return {
        ...state,
        pending: true,
        error: ""
      };

    case attendeesConstants.ADD_ATTENDEE_SUCCESS:
      return {
        ...state,
        pending: false,
        error: ""
      };

    case attendeesConstants.ADD_ATTENDEE_ERROR:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    case attendeesConstants.EDIT_ATTENDEE_REQUEST:
      return {
        ...state,
        pending: true,
        error: ""
      };

    case attendeesConstants.EDIT_ATTENDEE_SUCCESS:
      return {
        ...state,
        pending: false,
        error: ""
      };

    case attendeesConstants.EDIT_ATTENDEE_ERROR:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    case attendeesConstants.UNASSIGN_ATTENDEE_REQUEST:
      return {
        ...state,
        pending: true,
        error: ""
      };

    case attendeesConstants.UNASSIGN_ATTENDEE_SUCCESS:
      return {
        ...state,
        pending: false,
        error: ""
      };

    case attendeesConstants.UNASSIGN_ATTENDEE_ERROR:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

      case attendeesConstants.SENDEMAIL_ATTENDEE_REQUEST:
        return {
          ...state,
          pending: true,
          error: ""
        };
  
      case attendeesConstants.SENDEMAIL_ATTENDEE_SUCCESS:
        return {
          ...state,
          pending: false,
          error: ""
        };
  
      case attendeesConstants.SENDEMAIL_ATTENDEE_ERROR:
        return {
          ...state,
          pending: false,
          error: action.payload
        };
    default:
      return {
        ...state
      };
  }
};
