import { EmailPage } from "../../containers/Manager/EmailPage/EmailList";
import { ViewEmail } from "../../containers/Manager/EmailPage/ViewEmail";
import { InvoicesList } from "../../containers/Manager/Invoices/InvoicesList";
import { UsersList } from "../../containers/Manager/Users/UsersList";
import { AddUser } from "../../containers/Manager/Users/AddUser";
import { EditUser } from "../../containers/Manager/Users/EditUser";
import { ViewUser } from "../../containers/Manager/Users/ViewUser";

import { userType } from "../../_constants";

const pagesManager = [
  {
    name: "Email",
    to: "/emails",
    private: true,
    roles: [userType.MANAGER],
    component: EmailPage
  },
  {
    name: 'ViewEmail',
    to: "/viewemail/:id",
    roles: [userType.MANAGER],
    component: ViewEmail
  },
  {
    name: "InvoicesList",
    to: "/invoices",
    private: true,
    roles: [userType.MANAGER],
    component: InvoicesList
  },
  {
    name: "UsersList",
    to: "/userslist",
    private: true,
    roles: [userType.MANAGER],
    component: UsersList
  },
  {
    name: "AddUser",
    to: "/adduser",
    private: true,
    roles: [userType.MANAGER],
    component: AddUser
  },
  {
    name: "EditUser",
    to: "/edituser/:id",
    private: true,
    roles: [userType.MANAGER],
    component: EditUser
  },
  {
    name: "ViewUser",
    to: "/viewuser/:id",
    private: true,
    roles: [userType.MANAGER],
    component: ViewUser
  }
];

export { pagesManager };
