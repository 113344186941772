export const surveysConstants = {
    GET_SURVEY_LIST_REQUEST: "GET_SURVEY_LIST_REQUEST",
    GET_SURVEY_LIST_SUCCESS: "GET_SURVEY_LIST_SUCCESS",
    GET_SURVEY_LIST_ERROR: "GET_SURVEY_LIST_ERROR",
    
    ADD_SURVEY_REQUEST: "ADD_SURVEY_REQUEST",
    ADD_SURVEY_SUCCESS: "ADD_SURVEY_SUCCESS",
    ADD_SURVEY_ERROR: "ADD_SURVEY_ERROR",
  
    GET_SURVEY_BY_ID_REQUEST: "GET_SURVEY_BY_ID_REQUEST",
    GET_SURVEY_BY_ID_SUCCESS: "GET_SURVEY_BY_ID_SUCCESS",
    GET_SURVEY_BY_ID_ERROR: "GET_SURVEY_BY_ID_ERROR"
  
}