import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {useHistory } from "react-router-dom";

import {
  Grid,
  Typography,
  Button,
  TextField,
  RadioGroup,
  Radio,
  FormControl,
  FormControlLabel,
  Select,
  MenuItem
} from "@material-ui/core";
import { getUsersPublicList } from "../../_actions/users.actions";
 import { addSurveys} from "../../_actions/survey.actions";
 import { ModalAlert } from "../../components/Modal";
const Survey = ({users,user,getUsersPublicList,addSurveys}) => {
  useEffect(() => {
    const loadUsersList =async () => {
     await getUsersPublicList();
  
    };

     loadUsersList();
  }, [getUsersPublicList]);
  const [modalAlert, setModalAlert] = useState({
    open: false
  });
 const educators = users.users.filter(user => user.role.type === 'educator')
 const attendees=window.location.href.split('?')[1].split('&')[0].replace('attendee=','');
 const event=window.location.href.split('?')[1].split('&')[1].replace('event=','');


 const history = useHistory();
 
  const [surveyData, SetSurveyData] = useState({
    stepNumber: 1,
    officeName: '',
    jobTitle: '',
    otherJobTitle: '',
    answer3: '',
    answer4: '',
    answer5: '',
    answer6: '',
    answer7: '',
    answer8: '',
    answer9: '',
    answer10: '',
    answer11: '',
    answer12: '',
    answer13: '',
    answer14: '',
   
    attendee:attendees,
    event:event,
    buttontext:"Next"
  });
  const [showEmptyFields, setShowEmptyFields] = useState(false);

  const isValidForm = () => {
   
    if (
      surveyData.officeName &&
      surveyData.jobTitle &&
   
      surveyData.answer3 &&
      surveyData.answer4 &&
      surveyData.answer5 &&
      surveyData.answer6 &&
      surveyData.answer7 &&
      surveyData.answer9 &&
      surveyData.answer10 &&
      surveyData.answer11 &&
      surveyData.answer12 &&
      surveyData.answer13 &&
      surveyData.answer14 
    ) {
      setShowEmptyFields(false);
      return true;
    } else {
    
      setShowEmptyFields(true);
      return false;
    }
  };
  const submitSurvey= async () => {
    try {
    if (isValidForm()) {
    await addSurveys(surveyData);
  
    setModalAlert({
      open: true,
      success: true,
      title: "SUCCESS!",
      message: "Survey has successfully submitted."
    });
  }
 
    else
    {
      setModalAlert({
        open: true,
        success: true,
        title: "WARNING!",
        message: "Not all required  fields are field in"
      });
 
  }
}
catch (error) {
  setModalAlert({
    open: true,
    success: false,
    title: "ERROR!",
    message: error
  });
}
   };

 const checkvalidity = (stepNumber) => {
    

if(stepNumber===1)
{
  if(surveyData.officeName==="" )
  {
    setModalAlert({
      open: true,
      success: true,
      title: "WARNING!",
      message: "This Field  is required"
    });
  }
  else
  {
    SetSurveyData({
      ...surveyData,
      stepNumber: surveyData.stepNumber +1
    })
  }
 
    
}



if(stepNumber===2)
{
  if(surveyData.jobTitle==="" )
  {
    setModalAlert({
      open: true,
      success: true,
      title: "WARNING!",
      message: "This Field  is required"
    });
  }
  else
  {
    SetSurveyData({
      ...surveyData,
      stepNumber: surveyData.stepNumber +1
    })
  }
 
    
}



if(stepNumber===3)
{
  if(surveyData.answer3==="" )
  {
    setModalAlert({
      open: true,
      success: true,
      title: "WARNING!",
      message: "This Field  is required"
    });
  }
  else
  {
    SetSurveyData({
      ...surveyData,
      stepNumber: surveyData.stepNumber +1
    })
  }
 
    
}





if(stepNumber===4)
{
  if(surveyData.answer4==="" )
  {
    setModalAlert({
      open: true,
      success: true,
      title: "WARNING!",
      message: "This Field  is required"
    });
  }
  else
  {
    SetSurveyData({
      ...surveyData,
      stepNumber: surveyData.stepNumber +1
    })
  }
 
    
}




if(stepNumber===5)
{
  if(surveyData.answer5==="" )
  {
    setModalAlert({
      open: true,
      success: true,
      title: "WARNING!",
      message: "This Field  is required"
    });
  }
  else
  {
    SetSurveyData({
      ...surveyData,
      stepNumber: surveyData.stepNumber +1
    })
  }
 
    
}




if(stepNumber===6)
{
  if(surveyData.answer6==="" )
  {
    setModalAlert({
      open: true,
      success: true,
      title: "WARNING!",
      message: "This Field  is required"
    });
  }
  else
  {
    SetSurveyData({
      ...surveyData,
      stepNumber: surveyData.stepNumber +1
    })
  }
 
    
}



if(stepNumber===7)
{
  if(surveyData.answer7==="" )
  {
    setModalAlert({
      open: true,
      success: true,
      title: "WARNING!",
      message: "This Field  is required"
    });
  }
  else
  {
    SetSurveyData({
      ...surveyData,
      stepNumber: surveyData.stepNumber +1
    })
  }
 
    
}



if(stepNumber===8)
{
  if(surveyData.answer8==="" )
  {
    setModalAlert({
      open: true,
      success: true,
      title: "WARNING!",
      message: "This Field  is required"
    });
  }
  else
  {
    SetSurveyData({
      ...surveyData,
      stepNumber: surveyData.stepNumber +1
    })
  }
 
    
}
 

if(stepNumber===9)
{
  if(surveyData.answer9==="" )
  {
    setModalAlert({
      open: true,
      success: true,
      title: "WARNING!",
      message: "This Field  is required"
    });
  }
  else
  {
    SetSurveyData({
      ...surveyData,
      stepNumber: surveyData.stepNumber +1
    })
  }
 
    
}
 

if(stepNumber===10)
{
  if(surveyData.answer10==="" )
  {
    setModalAlert({
      open: true,
      success: true,
      title: "WARNING!",
      message: "This Field  is required"
    });
  }
  else
  {
    SetSurveyData({
      ...surveyData,
      stepNumber: surveyData.stepNumber +1
    })
  }
 
    
}
 

if(stepNumber===11)
{
  if(surveyData.answer11==="" )
  {
    setModalAlert({
      open: true,
      success: true,
      title: "WARNING!",
      message: "This Field  is required"
    });
  }
  else
  {
    SetSurveyData({
      ...surveyData,
      stepNumber: surveyData.stepNumber +1
    })
  }
 
    
}
 

if(stepNumber===12)
{
  if(surveyData.answer12==="" )
  {
    setModalAlert({
      open: true,
      success: true,
      title: "WARNING!",
      message: "This Field  is required"
    });
  }
  else
  {
    SetSurveyData({
      ...surveyData,
      stepNumber: surveyData.stepNumber +1
    })
  }
 
    
}
 

if(stepNumber===13)
{
  if(surveyData.answer13==="" )
  {
    setModalAlert({
      open: true,
      success: true,
      title: "WARNING!",
      message: "This Field  is required"
    });
  }
  else
  {
    SetSurveyData({
      ...surveyData,
      stepNumber: surveyData.stepNumber +1
    })
  }
 
    
}

if(stepNumber===14)
{
  if(surveyData.answer14==="" )
  {
    setModalAlert({
      open: true,
      success: true,
      title: "WARNING!",
      message: "This Field  is required"
    });
  }
  else
  {
    SetSurveyData({
      ...surveyData,
      stepNumber: surveyData.stepNumber +1
    })
  }
 
    
}

// if(stepNumber===15)
// {
//   if(surveyData.answer15==="" )
//   {
//     setModalAlert({
//       open: true,
//       success: true,
//       title: "WARNING!",
//       message: "This Field  is required"
//     });
//   }
//   else
//   {
//     SetSurveyData({
//       ...surveyData,
//       stepNumber: surveyData.stepNumber +1
//     })
//   }
 
    
//}

}

 
  return (
    <React.Fragment>
          {modalAlert.open && (
        <ModalAlert
          close={() => {
            setModalAlert({
              ...modalAlert,
              open: false
            });
            if(modalAlert.title==="SUCCESS!")
            {
              history.push("/Thankyou");
             
            }
            else

            {
             
              
            
            
            }
          }}
          success={modalAlert.success}
          title={modalAlert.title}
          message={modalAlert.message}
          open={modalAlert.open}
        />
      )}
      <Grid item xs={12} className="AddEvent">
        {surveyData.stepNumber === 1 && (
          <Grid container>
            <Typography variant="h6">* Please provide your office name</Typography>
            <Grid container alignItems="flex-end">
              <Grid item xs={9} md={9} className="input-item">
                <TextField
                  required
                  id="officeName"
                  fullWidth
                  variant="outlined"
                  value={surveyData.officeName}
                  error={!surveyData.officeName && showEmptyFields}
                  onChange={e =>
                    SetSurveyData({
                      ...surveyData,
                      officeName: e.target.value
                    })
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        )}

        {surveyData.stepNumber === 2 && (
          <Grid container>
            <Typography variant="h6">* Please select your job title</Typography>
            <Grid container alignItems="flex-end">
              <Grid item xs={9} md={9} className="input-item">
                <FormControl required fullWidth variant="outlined">
                  <RadioGroup aria-label="job title" name="jobtitle" 
                    value={surveyData.jobTitle}
                    error={!surveyData.jobTitle && showEmptyFields}
                    onChange={e => {
                      SetSurveyData({
                        ...surveyData,
                        jobTitle: e.target.value
                      })
                    }
                  }>
                    <FormControlLabel value="dds/dma" control={<Radio color="primary" />} label="DDS/DMA" />
                    <FormControlLabel value="rdh" control={<Radio color="primary" />} label="RDH" />
                    <FormControlLabel value="dental asistant" control={<Radio color="primary" />} label="Dental Assistant" />
                    <FormControlLabel value="office manager" control={<Radio color="primary" />} label="Office Manager" />
                    <FormControlLabel value="office staff" control={<Radio color="primary" />} label="Office Staff" />
                    <FormControlLabel value="faculty professor" control={<Radio color="primary" />} label="Faculty Professor" />
                    <FormControlLabel value="1 year rdh student" control={<Radio color="primary" />} label="1 Year RDH Student" />
                    <FormControlLabel value="2 year rdh student" control={<Radio color="primary" />} label="2 Year RDH Student" />
                    <FormControlLabel value="1 year dds student" control={<Radio color="primary" />} label="1 Year DDS Student" />
                    <FormControlLabel value="2 year rdh student" control={<Radio color="primary" />} label="2 Year DDS Student" />
                    <FormControlLabel value="3 year rdh student" control={<Radio color="primary" />} label="3 Year DDS Student" />
                    <FormControlLabel value="4 year rdh student" control={<Radio color="primary" />} label="4 Year DDS Student" />
                    <FormControlLabel value="lab tech" control={<Radio color="primary" />} label="Lab Tech" />
                    <FormControlLabel value="other" control={<Radio color="primary" />} label="Other (please specify)" />
                    {surveyData.jobTitle === 'other' && (
                      <TextField
                      id="otherjobtitle"
                      fullWidth
                      variant="outlined"
                      value={surveyData.otherJobTitle}
                      error={!surveyData.otherJobTitle && showEmptyFields}
                      onChange={e =>
                        SetSurveyData({
                          ...surveyData,
                          otherJobTitle: e.target.value
                        })
                      }
                    />
                    )}
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        )}

        {surveyData.stepNumber === 3 && (
          <Grid container>
            <Typography variant="h6">* Do currently recommend any oral care probiotics to your patients?</Typography>
            <Grid container alignItems="flex-end">
              <Grid item xs={9} md={9} className="input-item">
                <FormControl required fullWidth variant="outlined">
                  <RadioGroup aria-label="probiotics" name="probiotics "
                    value={surveyData.answer3}
                    error={!surveyData.answer3 && showEmptyFields}
                    onChange={e => {
                      SetSurveyData({
                        ...surveyData,
                        answer3: e.target.value
                      })
                    }
                  }>
                    <FormControlLabel value="yes" control={<Radio color="primary" />} label="Yes" />
                    <FormControlLabel value="no" control={<Radio color="primary" />} label="No" />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        )}

        {surveyData.stepNumber === 4 && (
          <Grid container>
            <Typography variant="h6">* Prior to the lunch and learn have you ever used ProBiora Health products?</Typography>
            <Grid container alignItems="flex-end">
              <Grid item xs={9} md={9} className="input-item">
                <FormControl required fullWidth variant="outlined">
                  <RadioGroup aria-label="proBiora" name="proBiora " 
                    value={surveyData.answer4}
                    error={!surveyData.answer4 && showEmptyFields}
                    onChange={e => {
                      SetSurveyData({
                        ...surveyData,
                        answer4: e.target.value
                      })
                    }
                  }>
                    <FormControlLabel value="yes" control={<Radio color="primary" />} label="Yes" />
                    <FormControlLabel value="no" control={<Radio color="primary" />} label="No" />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        )}

        {surveyData.stepNumber === 5 && (
          <Grid container>
            <Typography variant="h6">* Prior to the ProBiora Health Presentation, to what % of your patients did you recommend ProBioraPro?</Typography>
            <Grid container alignItems="flex-end">
              <Grid item xs={9} md={9} className="input-item">
                <FormControl required fullWidth variant="outlined">
                  <RadioGroup aria-label="proBiora" name="proBiora "
                    value={surveyData.answer5}
                    error={!surveyData.answer5 && showEmptyFields}
                    onChange={e => {
                      SetSurveyData({
                        ...surveyData,
                        answer5: e.target.value
                      })
                    }
                  }>
                    <FormControlLabel value="0-20" control={<Radio color="primary" />} label="0-20%" />
                    <FormControlLabel value="21-40" control={<Radio color="primary" />} label="21-40%" />
                    <FormControlLabel value="41-60" control={<Radio color="primary" />} label="41-60%" />
                    <FormControlLabel value="61-80" control={<Radio color="primary" />} label="61-80%" />
                    <FormControlLabel value="80+" control={<Radio color="primary" />} label="80+%" />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        )}

        {surveyData.stepNumber === 6 && (
          <Grid container>
            <Typography variant="h6">* The Presentation was professional, informative and I would attend again to see new products</Typography>
            <Grid container alignItems="flex-end">
              <Grid item xs={9} md={9} className="input-item">
                <FormControl required fullWidth variant="outlined">
                  <RadioGroup aria-label="proBiora" name="proBiora "
                   value={surveyData.answer6}
                   error={!surveyData.answer6 && showEmptyFields}
                   onChange={e => {
                     SetSurveyData({
                       ...surveyData,
                       answer6: e.target.value
                     })
                   }
                 }>
                    <FormControlLabel value="strongly agree" control={<Radio color="primary" />} label="Strongly Agree" />
                    <FormControlLabel value="somewhat agree" control={<Radio color="primary" />} label="Somewhat Agree" />
                    <FormControlLabel value="somewhat disagree" control={<Radio color="primary" />} label="Somewhat Disagree" />
                    <FormControlLabel value="strongly disagree" control={<Radio color="primary" />} label="Strongly Disagree" />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        )}

        {surveyData.stepNumber === 7 && (
          <Grid container>
            <Typography variant="h6">* I was pleased about the probotic research and products. I will share with my patients</Typography>
            <Grid container alignItems="flex-end">
              <Grid item xs={9} md={9} className="input-item">
                <FormControl required fullWidth variant="outlined">
                  <RadioGroup aria-label="proBiora" name="proBiora "
                    value={surveyData.answer7}
                    onChange={e => {
                      SetSurveyData({
                        ...surveyData,
                        answer7: e.target.value
                      })
                    }
                  }>
                    <FormControlLabel value="strongly agree" control={<Radio color="primary" />} label="Strongly Agree" />
                    <FormControlLabel value="somewhat agree" control={<Radio color="primary" />} label="Somewhat Agree" />
                    <FormControlLabel value="somewhat disagree" control={<Radio color="primary" />} label="Somewhat Disagree" />
                    <FormControlLabel value="strongly disagree" control={<Radio color="primary" />} label="Strongly Disagree" />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        )}

        {surveyData.stepNumber === 8 && (
          <Grid container>
            <Typography variant="h6">* The science and product information will give me the confidence to recommend ProBioraPro ( professional
          grade product, exclusively sold by dental and medical offices)</Typography>
            <Grid container alignItems="flex-end">
              <Grid item xs={9} md={9} className="input-item">
                <FormControl required fullWidth variant="outlined" >
                  <RadioGroup aria-label="proBiora" name="proBiora " 
                   value={surveyData.answer8}
                   onChange={e => {
                     SetSurveyData({
                       ...surveyData,
                       answer8: e.target.value
                     })
                   }
                 }>
                    <FormControlLabel value="strongly agree" control={<Radio color="primary" />} label="Strongly Agree" />
                    <FormControlLabel value="somewhat agree" control={<Radio color="primary" />} label="Somewhat Agree" />
                    <FormControlLabel value="somewhat disagree" control={<Radio color="primary" />} label="Somewhat Disagree" />
                    <FormControlLabel value="strongly disagree" control={<Radio color="primary" />} label="Strongly Disagree" />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        )}

        {surveyData.stepNumber === 9 && (
          <Grid container>
            <Typography variant="h6">* My Educator was well versed in the science and product knowledge?</Typography>
            <Grid container alignItems="flex-end">
              <Grid item xs={9} md={9} className="input-item">
                <FormControl required fullWidth variant="outlined">
                  <RadioGroup aria-label="proBiora" name="proBiora "
                    value={surveyData.answer9}
                    onChange={e => {
                      SetSurveyData({
                        ...surveyData,
                        answer9: e.target.value
                      })
                    }
                  }>
                    <FormControlLabel value="strongly agree" control={<Radio color="primary" />} label="Strongly Agree" />
                    <FormControlLabel value="somewhat agree" control={<Radio color="primary" />} label="Somewhat Agree" />
                    <FormControlLabel value="somewhat disagree" control={<Radio color="primary" />} label="Somewhat Disagree" />
                    <FormControlLabel value="strongly disagree" control={<Radio color="primary" />} label="Strongly Disagree" />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        )}

        {surveyData.stepNumber === 10 && (
          <Grid container>
            <Typography variant="h6">* My Educator was friendly, courteous and professional?</Typography>
            <Grid container alignItems="flex-end">
              <Grid item xs={9} md={9} className="input-item">
                <FormControl required fullWidth variant="outlined">
                  <RadioGroup aria-label="proBiora" name="proBiora " 
                    value={surveyData.answer10}
                    onChange={e => {
                      SetSurveyData({
                        ...surveyData,
                        answer10: e.target.value
                      })
                    }
                  }>
                    <FormControlLabel value="strongly agree" control={<Radio color="primary" />} label="Strongly Agree" />
                    <FormControlLabel value="somewhat agree" control={<Radio color="primary" />} label="Somewhat Agree" />
                    <FormControlLabel value="somewhat disagree" control={<Radio color="primary" />} label="Somewhat Disagree" />
                    <FormControlLabel value="strongly disagree" control={<Radio color="primary" />} label="Strongly Disagree" />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        )}

        {surveyData.stepNumber === 11 && (
          <Grid container>
            <Typography variant="h6">* My Educator managed time effectively?</Typography>
            <Grid container alignItems="flex-end">
              <Grid item xs={9} md={9} className="input-item">
                <FormControl required fullWidth variant="outlined">
                  <RadioGroup aria-label="proBiora" name="proBiora "
                    value={surveyData.answer11}
                    onChange={e => {
                      SetSurveyData({
                        ...surveyData,
                        answer11: e.target.value
                      })
                    }
                  }>
                    <FormControlLabel value="strongly agree" control={<Radio color="primary" />} label="Strongly Agree" />
                    <FormControlLabel value="somewhat agree" control={<Radio color="primary" />} label="Somewhat Agree" />
                    <FormControlLabel value="somewhat disagree" control={<Radio color="primary" />} label="Somewhat Disagree" />
                    <FormControlLabel value="strongly disagree" control={<Radio color="primary" />} label="Strongly Disagree" />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        )}

        {surveyData.stepNumber === 12 && (
          <Grid container>
            <Typography variant="h6">* How likely are you to take advantage of the professional ProBioraPro 50% off offer?</Typography>
            <Grid container alignItems="flex-end">
              <Grid item xs={9} md={9} className="input-item">
                <FormControl required fullWidth variant="outlined">
                  <RadioGroup aria-label="proBiora" name="proBiora "
                    value={surveyData.answer12}
                    onChange={e => {
                      SetSurveyData({
                        ...surveyData,
                        answer12: e.target.value
                      })
                    }
                  }>
                    <FormControlLabel value="will definitely purchase" control={<Radio color="primary" />} label="Will definitely purchase" />
                    <FormControlLabel value="maybe will purchase" control={<Radio color="primary" />} label="Maybe will purchase" />
                    <FormControlLabel value="will not purchase" control={<Radio color="primary" />} label="Will not purchase" />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        )}

        {/* {surveyData.stepNumber === 13 && (
          <Grid container>
            <Typography variant="h6">* Now that you have attended a ProBiora Health Presentation, to what % of your patients will you recommend
            ProBioraPro to your patients?</Typography>
            <Grid container alignItems="flex-end">
              <Grid item xs={9} md={9} className="input-item">
                <FormControl required fullWidth variant="outlined">
                  <RadioGroup aria-label="proBiora" name="proBiora "
                    value={surveyData.answer13}
                    onChange={e => {
                      SetSurveyData({
                        ...surveyData,
                        answer13: e.target.value
                      })
                    }
                  }>
                    <FormControlLabel value="0-20" control={<Radio color="primary" />} label="0-20%" />
                    <FormControlLabel value="21-40" control={<Radio color="primary" />} label="21-40%" />
                    <FormControlLabel value="41-60" control={<Radio color="primary" />} label="41-60%" />
                    <FormControlLabel value="61-80" control={<Radio color="primary" />} label="61-80%" />
                    <FormControlLabel value="80+" control={<Radio color="primary" />} label="80+%" />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        )} */}

        {surveyData.stepNumber === 13 && (
          <Grid container>
            <Typography variant="h6">* Now that you have attended a ProBiora Health Presentation, to what % of your patients will you recommend
            ProBioraPro to your patients?</Typography>
            <Grid container alignItems="flex-end">
              <Grid item xs={9} md={9} className="input-item">
                <FormControl required fullWidth variant="outlined">
                  <RadioGroup aria-label="proBiora" name="proBiora "
                    value={surveyData.answer13}
                    onChange={e => {
                      SetSurveyData({
                        ...surveyData,
                        answer13: e.target.value
                       
                      })
                    }
                  }>
                    <FormControlLabel value="0-20" control={<Radio color="primary" />} label="0-20%" />
                    <FormControlLabel value="21-40" control={<Radio color="primary" />} label="21-40%" />
                    <FormControlLabel value="41-60" control={<Radio color="primary" />} label="41-60%" />
                    <FormControlLabel value="61-80" control={<Radio color="primary" />} label="61-80%" />
                    <FormControlLabel value="80+" control={<Radio color="primary" />} label="80+%" />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        )}

        {surveyData.stepNumber === 14 && (
          <Grid container>
            <Typography variant="h6">* Please select your educator</Typography>
            <Grid container alignItems="flex-end">
              <Grid item xs={9} md={9} className="input-item">
              <FormControl required fullWidth variant="outlined">
                  <Select
                    labelId="educators"
                    id="educators"
                    fullWidth
                    value={surveyData.answer14}
                    onChange={e =>
                   
                      SetSurveyData({
                        ...surveyData,
                        answer15: e.target.value,
                        answer14: e.target.value,
                        buttontext:"Submit"
                      })
                    }
                  >
                    {educators.map(user => (
                      <MenuItem value={user.id} key={user.id}>
                        {user.firstName +"  " + user.lastName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

              </Grid>
            </Grid>
          </Grid>

        )}

        <Grid container>
          <Button
            variant="contained"
            color="primary"
            className="btn"
            onClick={() => {
              if(surveyData.stepNumber>1) {
                SetSurveyData({
                  ...surveyData,
                  stepNumber: surveyData.stepNumber -1
                })
             
              }
            }}
          >
            Prev
          </Button>
          <Button
            variant="contained"
            color="primary"
            className="btn"
          
            style={{ marginLeft: 20 }}
            onClick={() => {
             
              if(surveyData.stepNumber<14) {
              
                checkvalidity(surveyData.stepNumber);
              }
              else
              {
                
                submitSurvey();
              }
            }}
          > 
            {surveyData.buttontext}
           
          </Button>
        </Grid>

      </Grid>
    </React.Fragment >
  )
};

const mapDispatchToProps = {
  getUsersPublicList: getUsersPublicList,
  addSurveys:addSurveys
};

const mapStateToProps = state => {
  const { users, user } = state;
  return { users, user };
};

const connectedSurvey = connect(
  mapStateToProps,
  mapDispatchToProps
)(Survey);

export { connectedSurvey as Survey };