import { userConstants } from "../_constants";
import { userLoginAPI,userSendemailAPI } from "../_api/user";
import { resetPasswordAPI } from "../_api/password";
import { parseGraphQLError } from "../_helpers/errors";

export const userAuth = () => dispatch => {
  const authData = localStorage.getItem("User");
  const userData = JSON.parse(authData);

  if (authData) {
    dispatch({
      type: userConstants.USER_LOGIN_SUCCESS,
      payload: userData
    });
  } else {
    dispatch({ type: userConstants.USER_LOGOUT });
  }
};

export const userLogin = (username, password) => dispatch => {
  dispatch({ type: userConstants.USER_LOGIN_REQUEST });

  userLoginAPI(username, password)
    .then(data => {
      localStorage.setItem("User", JSON.stringify(data.data));

      dispatch({
        type: userConstants.USER_LOGIN_SUCCESS,
        payload: data.data
      });
    })
    .catch(error => {
      let errorMessage = parseGraphQLError(error);

      dispatch({
        type: userConstants.USER_LOGIN_ERROR,
        payload: errorMessage
      });
    });
};

export const userSendemail = (userData) => dispatch => {
  
  dispatch({ type: userConstants.USER_EMAIL_REQUEST });

  userSendemailAPI(userData)
    .then(data => {
     
      dispatch({
        type: userConstants.USER_EMAIL_SUCCESS,
        payload: data.data
      });
    })
    .catch(error => {
      let errorMessage = parseGraphQLError(error);

      dispatch({
        type: userConstants.USER_EMAIL_ERROR,
        payload: errorMessage
      });
    });
};

export const resetPassword = (
  code,
  password,
  passwordConfirmation
) => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch({ type: userConstants.RESET_PASSWORD_REQUEST });

    resetPasswordAPI(code, password, passwordConfirmation)
      .then(data => {
        localStorage.setItem("User", JSON.stringify(data.data));

        dispatch({
          type: userConstants.RESET_PASSWORD_SUCCESS,
          payload: data.data
        });

        resolve();
      })
      .catch(error => {
        let errorMessage = parseGraphQLError(error);

        dispatch({
          type: userConstants.RESET_PASSWORD_ERROR,
          payload: errorMessage
        });

        reject(errorMessage);
      });
  });
};

export const userLogout = () => dispatch => {
  localStorage.removeItem("User");
  dispatch({ type: userConstants.USER_LOGOUT });
};
