import React from "react";
import { connect } from "react-redux";

import {
  Grid
 ,
 Typography
} from "@material-ui/core";

const ThankYou = () => {


  return (
    <React.Fragment>
      
      <Grid item xs={12} className="AddEvent">

      <Grid container >
          <Grid  item xs={3} className="input-item">
              
          </Grid>
              <Grid  item xs={6} className="input-item">
              <Typography variant="h4">
              Thank you for completing the survey
          </Typography>
         </Grid>
         <Grid  item xs={3} className="input-item">
           
         </Grid>
      </Grid>
      </Grid>
    </React.Fragment >
  )
};



const mapStateToProps = state => {
  const { users, user } = state;
  return { users, user };
};

const connectedThankYou = connect(
  mapStateToProps
)(ThankYou);

export { connectedThankYou as ThankYou };