import { eventsConstants } from "../_constants";

const initialState = {
  events: [],
  pending: false,
  error: ""
};

export const events = (state = initialState, action) => {
  switch (action.type) {
    case eventsConstants.GET_EVENTS_LIST_REQUEST:
      return {
        ...state,
        pending: true,
        error: ""
      };

    case eventsConstants.GET_EVENTS_LIST_SUCCESS:
      return {
        ...state,
        events: action.payload,
        pending: false,
        error: ""
      };

    case eventsConstants.GET_EVENTS_LIST_ERROR:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    case eventsConstants.GET_EVENT_BY_ID_REQUEST:
      return {
        ...state,
        pending: true,
        error: ""
      };

    case eventsConstants.GET_EVENT_BY_ID_SUCCESS:
      return {
        ...state,
        pending: false,
        error: ""
      };

    case eventsConstants.GET_EVENT_BY_ID_ERROR:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    case eventsConstants.ADD_NEW_EVENT_REQUEST:
      return {
        ...state,
        pending: true,
        error: ""
      };

    case eventsConstants.ADD_NEW_EVENT_SUCCESS:
      return {
        ...state,
        pending: false,
        error: ""
      };

    case eventsConstants.ADD_NEW_EVENT_ERROR:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    case eventsConstants.EDIT_EVENT_REQUEST:
      return {
        ...state,
        pending: true,
        error: ""
      };

    case eventsConstants.EDIT_EVENT_SUCCESS:
      return {
        ...state,
        pending: false,
        error: ""
      };

    case eventsConstants.EDIT_EVENT_ERROR:
      return {
        ...state,
        pending: false,
        error: action.payload
      };
      case eventsConstants.SURVEY_EVENT_REQUEST:
        return {
          ...state,
          pending: true,
          error: ""
        };
  
      case eventsConstants.SURVEY_EVENT_SUCCESS:
        return {
          ...state,
          pending: false,
          error: ""
        };
  
      case eventsConstants.SURVEY_EVENT_ERROR:
        return {
          ...state,
          pending: false,
          error: action.payload
        };
        case eventsConstants.DELETE_EVENT_REQUEST:
        return {
          ...state,
          pending: true,
          error: ""
        };
  
      case eventsConstants.DELETE_EVENT_SUCCESS:
        return {
          ...state,
          pending: false,
          error: ""
        };
  
      case eventsConstants.DELETE_EVENT_ERROR:
        return {
          ...state,
          pending: false,
          error: action.payload
        };
        
    default:
      return {
        ...state
      };
  }
};
