import { usersConstants } from "../_constants";

const initialState = {
  users: [],
  pending: false,
  error: ""
};

export const users = (state = initialState, action) => {
  switch (action.type) {
    case usersConstants.GET_USERS_LIST_REQUEST:
      return {
        ...state,
        pending: true,
        error: ""
      };
    case usersConstants.GET_USERS_LIST_SUCCESS:
      return {
        ...state,
        pending: false,
        users: action.payload,
        error: ""
      };
    case usersConstants.GET_USERS_LIST_ERROR:
      return {
        ...state,
        pending: false,
        error: action.payload
      };
      case usersConstants.DELETE_USER_REQUEST:
        return {
          ...state,
          pending: true,
          error: ""
        };
  
      case usersConstants.DELETE_USER_SUCCESS:
        return {
          ...state,
          pending: false,
          error: ""
        };
  
      case usersConstants.DELETE_USER_ERROR:
        return {
          ...state,
          pending: false,
          error: action.payload
        };

    case usersConstants.ADD_NEW_USER_REQUEST:
      return {
        ...state,
        pending: true,
        error: ""
      };
    case usersConstants.ADD_NEW_USER_SUCCESS:
      return {
        ...state,
        pending: false,
        error: ""
      };
    case usersConstants.ADD_NEW_USER_ERROR:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    case usersConstants.EDIT_USER_REQUEST:
      return {
        ...state,
        pending: true,
        error: ""
      };
    case usersConstants.EDIT_USER_SUCCESS:
      return {
        ...state,
        pending: false,
        error: ""
      };
    case usersConstants.EDIT_USER_ERROR:
      return {
        ...state,
        pending: false,
        error: action.payload
      };
      case usersConstants.GET_USER_BY_Email_REQUEST:
        return {
          ...state,
          pending: true,
          error: ""
        };
      case usersConstants.GET_USER_BY_Email_SUCCESS:
        return {
          ...state,
          pending: false,
          users: action.payload,
          error: ""
        };
      case usersConstants.GET_USER_BY_Email_ERROR:
        return {
          ...state,
          pending: false,
          error: action.payload
        };
        case usersConstants.GET_USER_BY_ID_REQUEST:
          return {
            ...state,
            pending: true,
            error: ""
          };
        case usersConstants.GET_USER_BY_ID_SUCCESS:
          return {
            ...state,
            pending: false,
            users: action.payload,
            error: ""
          };
        case usersConstants.GET_USER_BY_ID_ERROR:
          return {
            ...state,
            pending: false,
            error: action.payload
          };
    default:
      return {
        ...state
      };
  }
};
