import React, { useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { resetPassword } from "../../../_actions/user.actions";
import { Grid, Typography, TextField, Button } from "@material-ui/core";

const ResetPassword = ({ resetPassword }) => {
  const history = useHistory();

  const [passwordData, setPasswordData] = useState({
    password: "",
    passwordConfirmation: "",
    code: ""
  });
  const [showError, setShowError] = useState(false);

  const resetPasswordHandler = () => {
    const { code, password, passwordConfirmation } = passwordData;

    if (code && password && passwordConfirmation) {
      resetPassword(code, password, passwordConfirmation)
        .then(() => {
          alert("Success!");
          history.push("/dashboard");
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      setShowError(true);
    }
  };

  return (
    
    <Grid container direction="column">
      <Typography variant="h5" className="input-field">
        Reset Password
      </Typography>
      <Grid item xs={3}>
        <Grid container>
          <Grid item xs={12} className="input-field">
            <TextField
              id={`reset-password-code`}
              fullWidth
              variant="outlined"
              value={passwordData.code}
              label="Enter code from email"
              error={showError && passwordData.code.length === 0}
              onChange={e => {
                setPasswordData({
                  ...passwordData,
                  code: e.target.value
                });
              }}
            />
          </Grid>
          <Grid item xs={12} className="input-field">
            <TextField
              id={`reset-password`}
              fullWidth
              variant="outlined"
              value={passwordData.password}
              label="Enter password"
              error={showError && passwordData.password.length === 0}
              onChange={e => {
                setPasswordData({
                  ...passwordData,
                  password: e.target.value
                });
              }}
            />
          </Grid>
          <Grid item xs={12} className="input-field">
            <TextField
              id={`reset-password-confirm`}
              fullWidth
              variant="outlined"
              value={passwordData.passwordConfirmation}
              label="Confirm password"
              error={
                showError && passwordData.passwordConfirmation.length === 0
              }
              onChange={e => {
                setPasswordData({
                  ...passwordData,
                  passwordConfirmation: e.target.value
                });
              }}
            />
          </Grid>
          <Grid item xs={12} className="input-field">
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => {
                resetPasswordHandler();
              }}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapDispatchToProps = {
  resetPassword: resetPassword
};

const connectedResetPassword = connect(null, mapDispatchToProps)(ResetPassword);
export { connectedResetPassword as ResetPassword };
