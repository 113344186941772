import React, { useState } from "react";
import { Modal, Grid, TextField, Button, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { createMuiTheme } from "@material-ui/core";

export const Login = ({ open, close, userLogin }) => {
  const [credentials, setCredentials] = useState({
    username: "",
    password: ""
  });

  const login = () => {
    if (credentials.username && credentials.password) {
      userLogin(credentials.username, credentials.password);
    }
  };

  const formLabelsTheme = createMuiTheme({
    overrides: {
      MuiFormLabel: {
        asterisk: {
          color: "#db3131",
          "&$error": {
            color: "#db3131"
          }
        }
      }
    }
  });

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      disableAutoFocus={true}
      open={open}
      onClose={close}
    >
      <Grid container direction="column" className="Login">
        <Typography align="center" variant="h4" className="input-field">
          Ed-Net Login
        </Typography>
        <MuiThemeProvider theme={formLabelsTheme}>
          <Grid item xs={12}>
            <Grid container direction="column">
              <Grid item xs={12} className="input-field">
                <TextField
                  id="login-username"
                  required
                  label="Username"
                  type="text"
                  variant="outlined"
                  fullWidth
                  onChange={e =>
                    setCredentials({ ...credentials, username: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12} className="input-field">
                <TextField
                  id="login-password"
                  required
                  label="Password"
                  type="password"
                  variant="outlined"
                  fullWidth
                  onChange={e =>
                    setCredentials({ ...credentials, password: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12} className="input-field">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={login}
                  fullWidth
                >
                  Login
                </Button>
              </Grid>
              <Grid item xs={12} className="input-field">
                <Link
                  to="/forgotpassword"
                  className="forgotpassword btn-link"
                  onClick={close}
                >
                  <Button variant="contained" color="primary" fullWidth>
                    Forgot Password?
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </MuiThemeProvider>
       
      </Grid>
    </Modal>
  );
};
