import axios from "axios";
import { server } from "../_constants";

export const getInvoicesEventsAPI = token => {
  return axios.post(
    `${server}/graphql`,
    {
      query: `        
        query {
              invoices (sort: "created_at:desc",
              where: {
                event_gt:0
              
              }
            ) {
            id
            dateSubmitted
            lunchCost
            educatorFee
            totalCost
            receipts {
                id
                name
                url
                provider
                mime
                provider_metadata
            }
            approvedOn
            approvedBy {
                id
                username
                firstName
                lastName
            }
            processedOn
            processedBy {
                id
                username
                firstName
                lastName
            }
            event {
                id
                eventName
                eventStart
                practice {
                  id
                  practiceName
                }
                attendees {
                  id
                }
            }
            author {
                id
                username
                }
            }
        }
  
         `
    },
    {
      headers: {
        Authorization: "bearer " + token,
        "Content-Type": "application/json"
      }
    }
  );
};

export const createInvoiceAPI = (data, token) => {
  return axios.post(
    `${server}/graphql`,
    {
      query: `
      mutation (
        $lunchCost: Float,
        $educatorFee: Float,
        $totalCost: Float,
        $receipts: [ID]
        $event: ID!) {
        createInvoice(input: {
          data: {
            lunchCost: $lunchCost,
            educatorFee: $educatorFee,
            totalCost: $totalCost, 
            receipts: $receipts, 
            event: $event
          }
        }) {
          invoice {
              id
              dateSubmitted
              lunchCost
              educatorFee
              totalCost
              receipts {
                  id
                  name
                  url
                  provider
                  mime
                  provider_metadata
              }
              approvedOn
              approvedBy {
                id
                username
                firstName
                lastName
              }
              processedOn
              processedBy {
                id
                username
                firstName
                lastName
              }
              event {
                id
                eventName
              }
              author {
                  id
                  firstName
                  lastName
              }
          }
        }
      }
    `,
      variables: {
        lunchCost: data.lunchCost,
        educatorFee: data.educatorFee,
        totalCost: data.totalCost,
        receipts: data.receipts,
        event: data.event
      }
    },
    {
      headers: {
        Authorization: "bearer " + token,
        "Content-Type": "application/json"
      }
    }
  );
};

export const editInvoiceAPI = (data, token) => {
  return axios.post(
    `${server}/graphql`,
    {
      query: `
      mutation (
        $id: ID!,
        $lunchCost: Float,
        $educatorFee: Float,
        $totalCost: Float,
        $receipts: [ID]
        ) {
        updateInvoice(input: {
          where: {
              id: $id
          }
          data: {
            lunchCost: $lunchCost,
            educatorFee: $educatorFee,
            totalCost: $totalCost, 
            receipts: $receipts, 
          }
        }) {
          invoice {
              id
              dateSubmitted
              lunchCost
              educatorFee
              totalCost
              receipts {
                  id
                  name
                  url
                  provider
                  mime
                  provider_metadata
              }
              approvedOn
              approvedBy {
                id
                username
                firstName
                lastName
              }
              processedOn
              processedBy {
                id
                username
                firstName
                lastName
              }
              event {
                id
                eventName
              }
              author {
                  id
                  firstName
                  lastName
              }
          }
        }
      }
    `,
      variables: {
        id: data.id,
        lunchCost: data.lunchCost,
        educatorFee: data.educatorFee,
        totalCost: data.totalCost,
        receipts: data.receipts
      }
    },
    {
      headers: {
        Authorization: "bearer " + token,
        "Content-Type": "application/json"
      }
    }
  );
};
  export const approveInvoiceAPI = (data, token) => {
    return axios.post(
      `${server}/graphql`,
      {
        query: `
        mutation (
          $id: ID!
          ) {
          approveInvoice(
            id: $id
            ) {
                id
                approvedOn
                approvedBy {
                    id
                    email
                }
            }
        }
      `,
        variables: {
          id: data.id         
         
        }
      },
      {
        headers: {
          Authorization: "bearer " + token,
          "Content-Type": "application/json"
        }
      }
    );
};
export const processInvoiceAPI = (data, token) => {
  return axios.post(
    `${server}/graphql`,
    {
      query: `
      mutation (
        $id: ID!
        ) {
       processInvoice(
          id: $id
          ) {
              id
              processedOn
              processedBy {
                  id
                  email
              }
          }
      }
    `,
      variables: {
        id: data.id         
       
      }
    },
    {
      headers: {
        Authorization: "bearer " + token,
        "Content-Type": "application/json"
      }
    }
  );
};
