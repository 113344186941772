import { attendeesConstants } from "../_constants";
import {
  getListAttendeesAPI,
  addAttendeeAPI,
  editAttendeeAPI,
  unAssignAttendeeAPI,
  sendemailAttendeeAPI
} from "../_api/attendees";
import { parseGraphQLError } from "../_helpers/errors";

export const getListAttendees = token => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch({ type: attendeesConstants.GET_ATTENDEES_LIST_REQUEST });

    getListAttendeesAPI(token)
      .then(data => {
        dispatch({
          type: attendeesConstants.GET_ATTENDEES_LIST_SUCCESS,
          payload: data.data.data.attendees
        });

        resolve();
      })
      .catch(error => {
        let errorMessage = parseGraphQLError(error);

        dispatch({
          type: attendeesConstants.GET_ATTENDEES_LIST_ERROR,
          payload: errorMessage
        });

        reject();
      });
  });
};

export const addAttendee = (mutation, token,userId) => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch({ type: attendeesConstants.ADD_ATTENDEE_REQUEST });
  
    addAttendeeAPI(mutation, token,userId)
      .then(() => {
        dispatch({
          type: attendeesConstants.ADD_ATTENDEE_SUCCESS
        });

        resolve();
      })
      .catch(error => {
        let errorMessage = parseGraphQLError(error);

        dispatch({
          type: attendeesConstants.ADD_ATTENDEE_ERROR,
          payload: errorMessage
        });

        reject(errorMessage);
      });
  });
};

export const editAttendee = (mutation, token) => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch({ type: attendeesConstants.EDIT_ATTENDEE_REQUEST });

    editAttendeeAPI(mutation, token)
      .then(() => {
        dispatch({
          type: attendeesConstants.EDIT_ATTENDEE_SUCCESS
        });

        resolve();
      })
      .catch(error => {
        let errorMessage = parseGraphQLError(error);

        dispatch({
          type: attendeesConstants.EDIT_ATTENDEE_ERROR,
          payload: errorMessage
        });

        reject();
      });
  });
};

export const unAssignAttendee = (attendID, eventID, token) => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch({ type: attendeesConstants.UNASSIGN_ATTENDEE_REQUEST });

    unAssignAttendeeAPI(attendID, eventID, token)
      .then(() => {
        dispatch({
          type: attendeesConstants.UNASSIGN_ATTENDEE_SUCCESS
        });

        resolve(true);
      })
      .catch(error => {
        let errorMessage = parseGraphQLError(error);

        dispatch({
          type: attendeesConstants.UNASSIGN_ATTENDEE_ERROR,
          payload: errorMessage
        });

        reject(false);
      });
  });
};
export const sendEmailAttendee = (attendID, eventID,email, token) => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch({ type: attendeesConstants.SENDEMAIL_ATTENDEE_REQUEST });

    sendemailAttendeeAPI(attendID, eventID,email, token)
      .then(() => {
        dispatch({
          type: attendeesConstants.SENDEMAIL_ATTENDEE_SUCCESS
        });

        resolve(true);
      })
      .catch(error => {
        let errorMessage = parseGraphQLError(error);

        dispatch({
          type: attendeesConstants.SENDEMAIL_ATTENDEE_ERROR,
          payload: errorMessage
        });

        reject(false);
      });
  });
};
