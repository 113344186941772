import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Grid, Typography, Paper, Button } from "@material-ui/core";

import {
  Description,
  Image,
  PlayArrow,
  ChevronRight
} from "@material-ui/icons";
import moment from "moment";

import { getListResourcesByCategory } from "../../../_actions/resources.actions";
import { server } from "../../../_constants";

const resourceCategories = {
  category1: "category1",
  category2: "category2"
};

const iconOfFilyType = {
  image: <Image />,
  doc: <Description />,
  video: <PlayArrow />
};

const ResourcesList = ({ getListResourcesByCategory, user, resources }) => {
  const [selectedCategory, setSelectedCategory] = useState(
    resourceCategories.category1
  );

  useEffect(() => {
    const loadEventsList = () => {
      getListResourcesByCategory(user.jwt, resourceCategories.category1);
    };

    if (user.jwt) loadEventsList();
  }, [getListResourcesByCategory, user.jwt]);
 
  const selectCategory = categoryName => {
    getListResourcesByCategory(user.jwt, categoryName);
    setSelectedCategory(categoryName);
  };

  return (
    <React.Fragment>
      <Grid item xs={12} className="Resource">
        <Grid container>
          <Grid item xs={12} md={6}>
            <Typography variant="h5" style={{ marginBottom: 20 }}>
              Resources
            </Typography>
          </Grid>
        </Grid>
        <Grid container className="Resource__categories-list">
          {Object.keys(resourceCategories).map(category => {
            return (
              <Typography
                className={`Resource__categories-list_item${
                  selectedCategory === category ? " active" : ""
                }`}
                onClick={() => {
                  selectCategory(category);
                }}
                key={category}
              >
                {category}
              </Typography>
            );
          })}
        </Grid>
        {!resources.pending &&
          !resources.error &&
          resources.resources.length > 0 && (
            <Grid container>
              {resources.resources.map(resource => {
                return resource.file.map(file => {
                  return (
                    <Grid
                      item
                      xs={12}
                      md={4}
                      className="Resource-item"
                      key={resource.title}
                    >
                      <Paper className="Resource-item-wrapper">
                        <Grid
                          container
                          alignItems="center"
                          className="Resource-item__element"
                        >
                          <Grid item xs={10}>
                            <Typography variant="h6" noWrap>
                              {resource.title}
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Typography>
                              {moment(resource.created_at).format("MM/DD")}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          direction="column"
                          className="Resource-item__element"
                        >
                          <Grid item xs={12} key={file.name}>
                            <Grid container>
                              <Grid item xs={1}>
                                {iconOfFilyType[`${file.mime.split("/")[0]}`]}
                              </Grid>
                              <Grid item xs={11}>
                                <a
                                  href={`${server}${file.url}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <Typography noWrap>{file.name}</Typography>
                                </a>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        {/* <Typography className="Resource-item__element">
                        Headline Lorem ipsum dolor sit amet, consectetur
                        adipiscing elit. Lorem ipsum
                      </Typography> */}
                        <a
                          href={`${server}${file.url}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="btn-link"
                        >
                          <Button variant="contained" color="default" fullWidth>
                            Preview <ChevronRight />
                          </Button>
                        </a>
                      </Paper>
                    </Grid>
                  );
                });
              })}
            </Grid>
          )}
      </Grid>
    </React.Fragment>
  );
};

const mapDispatchToProps = {
  getListResourcesByCategory: getListResourcesByCategory
};

const mapStateToProps = state => {
  const { resources, user } = state;
  return { resources, user };
};

const connectedResourcesList = connect(
  mapStateToProps,
  mapDispatchToProps
)(ResourcesList);
export { connectedResourcesList as ResourcesList };
