import axios from "axios";
import { server } from "../_constants";

export const getPageByIDAPI = pageID => {
  return axios.post(
    `${server}/graphql`,
    {
      query: `
        query (
            $id: ID!    
        ) {
        page (
            id: $id
            ) {
            id
            title
            text
        }
        }
    `,
      variables: {
        id: pageID
      }
    },
    {
      headers: {
        "Content-Type": "application/json"
      }
    }
  );
};
