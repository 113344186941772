import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Grid,
  Typography,
  Button,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Paper
} from "@material-ui/core";

import { AddPractice } from "../AddPractice";
import { getListPractices } from "../../../_actions/practices.actions";

const Lookup = ({ getListPractices, user, practices }) => {
  const [selectedPractice, setSelectedPractice] = useState({ id: "" });

  useEffect(() => {
    const loadPracticesList = () => {
      getListPractices(user.jwt);
    };

    if (user.jwt) loadPracticesList();
  }, [getListPractices, user.jwt]);

  const selectPractice = practiceID => {
    const newSelectedPractice = practices.practices.find(practices => {
      if (practiceID === practices.id) return true;
      return false;
    });

    setSelectedPractice(newSelectedPractice);
  };

  return (
    <React.Fragment>
      {practices.practices && (
        <Grid item xs={12} className="Practice">
          <Typography variant="h5">Add new Practice</Typography>
          <Grid container>
            <Typography variant="h6">Practice Look-up</Typography>
            <Grid container alignItems="flex-end">
              <Grid item xs={12} md={6} className="input-item">
                <FormControl fullWidth>
                  <InputLabel id="select-practice-label">
                    Practice Look-up
                  </InputLabel>
                  <Select
                    labelId="select-practice-label"
                    id="select-practice"
                    fullWidth
                    value={selectedPractice.id}
                    onChange={e => selectPractice(e.target.value)}
                  >
                    {!practices.pending &&
                      !practices.error &&
                      practices.practices.map((practice, index) => (
                        <MenuItem key={index} value={practice.id}>
                          {practice.practiceName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Link to="/addevent">
            <Button variant="contained" color="primary" className="btn">
              Add event
            </Button>
          </Link>
          <Grid item xs={12} md={6} className="other-events__wrapper">
            <Paper className="other-events__wrapper_item">
              <Typography variant="h6">Upcoming Events</Typography>
              <Typography>{selectedPractice.address1}</Typography>
              <Typography>{selectedPractice.city}</Typography>
              <br />
              <Typography>{selectedPractice.email}</Typography>
              <Typography>{selectedPractice.fullName}</Typography>
              <br />
              <Typography>{selectedPractice.practiceType}</Typography>
            </Paper>
          </Grid>
          <AddPractice />
        </Grid>
      )}
    </React.Fragment>
  );
};

const mapDispatchToProps = {
  getListPractices: getListPractices
};

const mapStateToProps = state => {
  const { practices, user } = state;
  return { practices, user };
};

const connectedLookup = connect(mapStateToProps, mapDispatchToProps)(Lookup);
export { connectedLookup as Lookup };
