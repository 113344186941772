import { pagesConstants } from "../_constants";

const initialState = {
  pending: false,
  error: ""
};

export const pages = (state = initialState, action) => {
  switch (action.type) {
    case pagesConstants.GET_PAGE_BY_ID_REQUEST:
      return {
        ...state,
        pending: true,
        error: ""
      };
    case pagesConstants.GET_PAGE_BY_ID_SUCCESS:
      return {
        ...state,
        pending: false,
        error: ""
      };
    case pagesConstants.GET_PAGE_BY_ID_ERROR:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    default:
      return {
        ...state
      };
  }
};
