import React, { useState } from "react";
import { Grid, TextField, Button } from "@material-ui/core";
import {  RemoveCircleOutline, Save } from "@material-ui/icons";
import moment from "moment";

// import { findAttendeeByEmailAPI } from "../../_api/attendees";

const Attendee = props => {
  const [attendeeInfo, setAttendeeInfo] = useState(props.attendee);

  // const findAttendee = async email => {
  //   const findedAttendee = await findAttendeeByEmailAPI(email, props.userToken);

  //   if (
  //     findedAttendee.data.data.attendees &&
  //     findedAttendee.data.data.attendees.length > 0 &&
  //     email
  //   ) {
  //     const { firstName, lastName } = findedAttendee.data.data.attendees[0];
  //     setAttendeeInfo({ ...attendeeInfo, firstName, lastName, email });
  //   } else {
  //     setAttendeeInfo({ ...attendeeInfo, email });
  //   }
  // };

  return (
    <Grid container alignItems="center" className="Attendee">
      <Grid item xs={12} md={3} className="input-item">
        <TextField
          id={`attendee-firstName-${attendeeInfo.firstName}`}
          fullWidth
          variant="outlined"
          disabled={props.disabled ? true : false}
          value={attendeeInfo.firstName}
          label="First name"
          error={props.error && attendeeInfo.firstName.length === 0}
          onChange={e => {
            setAttendeeInfo({
              ...attendeeInfo,
              firstName: e.target.value
            });
          }}
        />
      </Grid>
      <Grid item xs={12} md={3} className="input-item">
        <TextField
          id={`attendee-lastName-${attendeeInfo.lastName}`}
          fullWidth
          variant="outlined"
          disabled={props.disabled ? true : false}
          value={attendeeInfo.lastName}
          label="Last name"
          error={props.error && attendeeInfo.lastName.length === 0}
          onChange={e => {
            setAttendeeInfo({
              ...attendeeInfo,
              lastName: e.target.value
            });
          }}
        />
      </Grid>
      <Grid item xs={12} md={3} className="input-item">
        <TextField
          id={`attendee-email-${attendeeInfo.email}`}
          fullWidth
          variant="outlined"
          disabled={props.disabled ? true : false}
          value={attendeeInfo.email}
          label="E-mail"
          error={props.error && attendeeInfo.email.length === 0}
          onChange={e => {
            const email = e.target.value;

            // if (props.findAttendee) {
            //   findAttendee(email);
            // } else {
           
            setAttendeeInfo({
              ...attendeeInfo,
              email
            });
          //}
          }}
        />
      </Grid>
      <Grid item xs={12} md={2} className="input-item">
        <TextField
          id={`attendee-surveys-${attendeeInfo.surveys}`}
          fullWidth
          variant="outlined"
          disabled={props.disabled ? true : false}
          value={
            attendeeInfo.surveys.length > 0
              ? moment(attendeeInfo.surveys[0].submittedOn).format("MM.DD.YYYY")
              : "Not Complete"
          }
          label="Survey"
          // onChange={e => {
          //   setAttendeeInfo({
          //     ...attendeeInfo,
          //     surveys: e.target.value
          //   });
          // }}
        />
      </Grid>
      {!props.disabled && (
        <Grid item xs={12} md={1} className="input-item">
          {props.isNew ? (
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => props.newAttend(attendeeInfo)}
            >
              Save
            </Button>
          ) : (
            <Save
              className="Attendee__remove"
              onClick={() => props.editAttend(attendeeInfo)}
            />
          )}
          {props.unAssingAttend && (
            <RemoveCircleOutline
              className="Attendee__remove"
              onClick={() => props.unAssingAttend(attendeeInfo.id)}
            />
          )}
        </Grid>
      )}
    </Grid>
  );
};

export { Attendee };
