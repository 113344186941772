export const attendeesConstants = {
  GET_ATTENDEES_LIST_REQUEST: "GET_ATTENDEES_LIST_REQUEST",
  GET_ATTENDEES_LIST_SUCCESS: "GET_ATTENDEES_LIST_SUCCESS",
  GET_ATTENDEES_LIST_ERROR: "GET_ATTENDEES_LIST_ERROR",

  ADD_ATTENDEE_REQUEST: "ADD_ATTENDEE_REQUEST",
  ADD_ATTENDEE_SUCCESS: "ADD_ATTENDEE_SUCCESS",
  ADD_ATTENDEE_ERROR: "ADD_ATTENDEE_ERROR",

  EDIT_ATTENDEE_REQUEST: "EDIT_ATTENDEE_REQUEST",
  EDIT_ATTENDEE_SUCCESS: "EDIT_ATTENDEE_SUCCESS",
  EDIT_ATTENDEE_ERROR: "EDIT_ATTENDEE_ERROR",

  UNASSIGN_ATTENDEE_REQUEST: "UNASSIGN_ATTENDEE_REQUEST",
  UNASSIGN_ATTENDEE_SUCCESS: "UNASSIGN_ATTENDEE_SUCCESS",
  UNASSIGN_ATTENDEE_ERROR: "UNASSIGN_ATTENDEE_ERROR",

  SENDEMAIL_ATTENDEE_REQUEST: "SENDEMAIL_ATTENDEE_REQUEST",
  SENDEMAIL_ATTENDEE_SUCCESS: "SENDEMAIL_ATTENDEE_SUCCESS",
  SENDEMAIL_ATTENDEE_ERROR: "SENDEMAIL_ATTENDEE_ERROR"
};
