export const userConstants = {
  USER_LOGIN_REQUEST: "USER_LOGIN_REQUEST",
  USER_LOGIN_SUCCESS: "USER_LOGIN_SUCCESS",
  USER_LOGIN_ERROR: "USER_LOGIN_ERROR",

  USER_REGISTER_REQUEST: "USER_REGISTER_REQUEST",
  USER_REGISTER_SUCCESS: "USER_REGISTER_SUCCESS",
  USER_REGISTER_ERROR: "USER_REGISTER_ERROR",  

  RESET_PASSWORD_REQUEST: "RESET_PASSWORD_REQUEST",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_ERROR: "RESET_PASSWORD_ERROR",

  USER_LOGOUT: "USER_LOGOUT",

  USER_EMAIL_REQUEST: "USER_EMAIL_REQUEST",
  USER_EMAIL_SUCCESS: "USER_EMAIL_SUCCESS",
  USER_EMAIL_ERROR: "USER_EMAIL_ERROR",
};

export const userType = {
  DEFAULT: "",
  MANAGER: "manager",
  EDUCATOR: "educator"
};
