import React, {  useEffect,useState } from "react";
import { Grid ,Button} from "@material-ui/core";
import MaterialTable from "material-table";
import { connect } from "react-redux";
import { GetEmailList } from "../../../../_actions/events.actions";
import { Visibility } from "@material-ui/icons";

import { useHistory } from "react-router-dom";
const EmailPage = ({ GetEmailList, user, events }) => {

  const [eventList, setEventList] = useState({ data: [] }); 
  const history = useHistory();
  useEffect(() => {
    const fetchData = async () => {
      const data=await GetEmailList();
      setEventList({
        data
      });
    };
 
    fetchData();
  }, [GetEmailList]);
  const ViewEmail = async emailparams => {

    try {
      localStorage.setItem("currentemail", JSON.stringify(emailparams));
      history.push(`/viewemail/1`);
    }
    catch (error) {

    }
  }; 
  return (
    <React.Fragment>
      <Grid item xs={12}>
        
        <MaterialTable
          title="Emails"
          columns={[
            {
              title: "From",
              field: "from",
              sorting: true
            },
            { title: "To", field: "to", sorting: true },
            {
              title: "Date",
              field: "date",
              sorting: true
            },
            {
              title: "Subject",
              field: "subject",
              sorting: true
            },
            {
              title: "Action",
              field: "id",
              render: tableData => {
                return (
                  <Button
                  
                  onClick={() =>ViewEmail(tableData)}
                >
                     <Visibility />
              </Button>
                
                )
              }
            }
          ]}
          data={eventList.data.map(email => {
       
      
    
            return {
              from:email.from,
              date:email.date,
              to:email.to,
              subject:email.subject,              
              body:email.body
           
            };
          })}
          options={{
            sorting: true,
            filtering: false,
            toolbar: true,
            draggable: false,
            search: true 
          }}
        />
      </Grid>
    </React.Fragment>
  );
};

const mapDispatchToProps = {
  GetEmailList:GetEmailList
 
};

const mapStateToProps = state => {
  const { events, user } = state;
  return { events, user };
};

const connectedEmailPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(EmailPage);
export { connectedEmailPage as EmailPage };
