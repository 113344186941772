import React from "react";
import { Grid, Toolbar, Typography, Button } from "@material-ui/core";

export const Footer = () => {
  return (
    <Toolbar className="Footer">
      <Grid container>
        <Grid container justify="space-between" alignItems="center">
          <div>
            <Typography>Copyright 2019 - ProbioraHealth</Typography>
          </div>
          <div>
            <Button color="inherit">PRIVACY </Button>
            <Button color="inherit">SUPPORT</Button>
          </div>
        </Grid>
      </Grid>
    </Toolbar>
  );
};
