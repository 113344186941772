import React from "react";
import { Typography, Button, Modal } from "@material-ui/core";
import {
  CheckCircleOutline,
  ErrorOutline,
  ArrowForward
} from "@material-ui/icons";

const ModalAlert = props => {
  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      open={props.open}
      onClose={props.close}
      className="ModalAlert"
    >
      <div className="ModalAlert__container">
        {props.success ? (
          <CheckCircleOutline className="ModalAlert__container_icon" />
        ) : (
          <ErrorOutline className="ModalAlert__container_icon" />
        )}
        <Typography variant="h5" className="ModalAlert__container_title">
          {props.title}
        </Typography>
        <Typography>{props.message}</Typography>
        <Button
          variant="contained"
          color="primary"
          className="btn"
          fullWidth
          onClick={props.close}
        >
          Go back <ArrowForward />
        </Button>
      </div>
    </Modal>
  );
};

export { ModalAlert };
