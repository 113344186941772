import { practicessConstants } from "../_constants";

const initialState = {
  practices: [],
  pending: false,
  error: ""
};

export const practices = (state = initialState, action) => {
  switch (action.type) {
    case practicessConstants.GET_PRACTICES_LIST_REQUEST:
      return {
        ...state,
        pending: true,
        error: ""
      };
    case practicessConstants.GET_PRACTICES_LIST_SUCCESS:
      return {
        ...state,
        pending: false,
        practices: action.payload,
        error: ""
      };
    case practicessConstants.GET_PRACTICES_LIST_ERROR:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    case practicessConstants.ADD_NEW_PRACTICE_REQUEST:
      return {
        ...state,
        pending: true,
        error: ""
      };
    case practicessConstants.ADD_NEW_PRACTICE_SUCCESS:
      return {
        ...state,
        pending: false,
        error: ""
      };
    case practicessConstants.ADD_NEW_PRACTICE_ERROR:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    case practicessConstants.EDIT_PRACTICE_REQUEST:
      return {
        ...state,
        pending: true,
        error: ""
      };
    case practicessConstants.EDIT_PRACTICE_SUCCESS:
      return {
        ...state,
        pending: false,
        error: ""
      };
    case practicessConstants.EDIT_PRACTICE_ERROR:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    case practicessConstants.SET_ARCHIVE_STATUS_REQUEST:
      return {
        ...state,
        pending: true,
        error: ""
      };
    case practicessConstants.SET_ARCHIVE_STATUS_SUCCESS:
      return {
        ...state,
        pending: false,
        error: ""
      };
    case practicessConstants.SET_ARCHIVE_STATUS_ERROR:
      return {
        ...state,
        pending: false,
        error: action.payload
      };
      case practicessConstants.GEOLOCATION_PRACTICE_REQUEST:
        return {
          ...state,
          pending: true,
          error: ""
        };
      case practicessConstants.GEOLOCATION_PRACTICE_SUCCESS:
        return {
          ...state,
          pending: false,
          error: ""
        };
      case practicessConstants.GEOLOCATION_PRACTICE_ERROR:
        return {
          ...state,
          pending: false,
          error: action.payload
        };
  
    default:
      return {
        ...state
      };
  }
};
