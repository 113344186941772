import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Grid, Typography, TextField, Avatar } from "@material-ui/core";
import { useParams } from "react-router-dom";
import moment from "moment";
import { getUserByID } from "../../../../_actions/users.actions";
import { server } from "../../../../_constants/server.constants";

const ViewUser = ({ user, users, getUserByID }) => {
  
  
  let { id } = useParams();

  const [userData, setUserData] = useState({
    username: "",
    email: "",
    firstName: "",
    lastName: "",
    contactPhone: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    discountCode: "",
    contractStart: "",
    contractEnd: "",
    Territory:"",
    photo: {
      name: "",
      url: ""
    }
  });

  useEffect(() => {
    const loadUserInfo = async () => {
      const userInfo = await getUserByID(id, user.jwt);
     
      if (userInfo.errors) {
        let errMassage = "";
        userInfo.errors.forEach(error => {
          errMassage += `${error.message}\n`;
        });

        alert(errMassage);
      } else {
        setUserData({ ...userInfo });
      }
    };

    if (user.jwt) loadUserInfo();
  }, [getUserByID, id, user.jwt]);

  return (
    <React.Fragment>
      <Grid item xs={12} className="AddEvent">
        <Grid container>
          <Grid item xs={12} md={6}>
            <Typography variant="h5" style={{ marginBottom: 20 }}>
              View User
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Avatar
            alt={`${userData.firstName}-${userData.lastName}`}
            src={userData.photo ? `${server}${userData.photo.url}` : null}
          />
        </Grid>
        <Grid container>
          <Grid item xs={12} md={4} className="input-item">
            <TextField
              id="firstName"
              fullWidth
              variant="outlined"
              label="First name"
              disabled
              value={userData.firstName}
            />
          </Grid>
          <Grid item xs={12} md={4} className="input-item">
            <TextField
              id="lastName"
              fullWidth
              variant="outlined"
              label="Last name"
              disabled
              value={userData.lastName}
            />
          </Grid>
          <Grid item xs={12} md={4} className="input-item">
            <TextField
              id="discountCode"
              fullWidth
              variant="outlined"
              label="Discount Code"
              disabled
              value={userData.discountCode}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={4} className="input-item">
            <TextField
              id="contactPhone"
              fullWidth
              variant="outlined"
              label="Phone"
              disabled
              value={userData.contactPhone}
            />
          </Grid>
          <Grid item xs={12} md={4} className="input-item">
            <TextField
              id="email"
              fullWidth
              variant="outlined"
              label="Email"
              disabled
              value={userData.email}
            />
          </Grid>
          <Grid item xs={12} md={4} className="input-item">
            <TextField
              id="Territory"
              fullWidth
              variant="outlined"
              label="Territory"
              disabled
              value={userData.Territory}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={4} className="input-item">
            <TextField
              id="address1"
              fullWidth
              variant="outlined"
              label="Address - Street"
              disabled
              value={userData.address1}
            />
          </Grid>
          <Grid item xs={12} md={4} className="input-item">
            <TextField
              id="address2"
              fullWidth
              variant="outlined"
              label="Suite/Number"
              disabled
              value={userData.address2}
            />
          </Grid>
          <Grid item xs={12} md={4} style={{ paddingLeft: 20 }}>
            <Grid container>
            <Grid item xs={12} md={4} className="input-item">
                <TextField
                  id="city"
                  fullWidth
                  variant="outlined"
                  label="City"
                  disabled
                  value={userData.city}
                />
              </Grid>
              <Grid item xs={12} md={4} className="input-item">
                <TextField
                  id="state"
                  fullWidth
                  variant="outlined"
                  label="State"
                  disabled
                  value={userData.state}
                />
              </Grid>
              <Grid item xs={12} md={4} className="input-item">
                <TextField
                  id="zip"
                  fullWidth
                  variant="outlined"
                  label="Zip"
                  disabled
                  value={userData.zip}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={4} className="input-item">
            <TextField
              id="contractStart"
              fullWidth
              label="Contract Start"
              disabled
              value={moment(userData.contractStart).format(
                "MM.DD.YYYY hh:mm A"
              )}
            />
          </Grid>
          <Grid item xs={12} md={4} className="input-item">
            <TextField
              id="contractEnd"
              fullWidth
              label="Contract end"
              disabled
              value={moment(userData.contractEnd).format("MM.DD.YYYY hh:mm A")}
            />
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

const mapDispatchToProps = {
  getUserByID: getUserByID
};

const mapStateToProps = state => {
  const { users, user } = state;
  return { users, user };
};

const connectedViewUser = connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewUser);
export { connectedViewUser as ViewUser };
