import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import moment from "moment";
import { Grid } from "@material-ui/core";
import { getEventsList } from "../../_actions/events.actions";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";

const Calendar = props => {
  const { getEventsList, user, events } = props;

  const history = useHistory();

  useEffect(() => {
    const loadEventsList = () => {
      getEventsList(user.jwt);
    };

    if (user.jwt) loadEventsList();
  }, [getEventsList, user.jwt]);

  return (
    <React.Fragment>
      {events.events && events.events.length > 0 && (
        <Grid container>
          <Grid item xs={12} className="calendar-item">
            <FullCalendar
              defaultView="dayGridMonth"
              plugins={[dayGridPlugin]}
              firstDay={1}
              eventClick={data => {
                history.push(`/event/${data.event.id}`);
              }}
              events={events.events.map(event => {
                return {
                  id: event.id,
                  title: event.eventName,
                  start: moment(event.eventStart).toDate()
                };
              })}
            />
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
};

const mapDispatchToProps = {
  getEventsList: getEventsList
};

const mapStateToProps = state => {
  const { events, user } = state;
  return { events, user };
};

const connectedCalendar = connect(
  mapStateToProps,
  mapDispatchToProps
)(Calendar);
export { connectedCalendar as Calendar };
