import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Grid, Typography, List, ListItem } from "@material-ui/core";
import { getEventsList } from "../../../_actions/events.actions";
import { updateGeoLocation } from "../../../_actions/practices.actions";
import { EventsMap } from "../../../components/Map";
import LoadingOverlay from 'react-loading-overlay';
const EventsMapPage = ({ getEventsList, user, events,updateGeoLocation }) => {
  const [selectedEvent, setSelectedEvent] = useState({});
  const [active, setactive] = useState(false);

  const updateGeoLocationcall = async event => {
   
    try {
  
const data={};

data.id=event.practice.id;
data.practiceName=event.practice.practiceName;
data.practiceType=event.practice.practiceType;
data.contactPhone=event.practice.contactPhone;
data.address2=event.practice.address2;
data.address1=event.practice.address1;
data.state=event.practice.state;
data.city=event.practice.city;
data.zip=event.practice.zip;
data.SalesforceID=event.practice.SalesforceID?event.practice.SalesforceID:"";

    let result= await updateGeoLocation(data, user.jwt);
    if(result.data.data.setGeoCodes.latitude!=="")
    {
    event.latitude=result.data.data.setGeoCodes.latitude;
    }
    if(result.data.data.setGeoCodes.longitude!=="")
    {

    event.longitude=result.data.data.setGeoCodes.longitude;
    }

      setTimeout(() => {
        selectPractice(event);
      }, 2000);

      
  
   
    }
    catch (error) {

    }
  };
  const selectPractice = event => {
      
          const newSelectedEvent = {
      ...event,
      latitude: Number( event.practice!=null?event.practice.latitude:0) || "",
      longitude: Number(event.practice!=null?event.practice.longitude:0) || ""
      
    };

    setSelectedEvent(newSelectedEvent);
    setactive(false);
  };

  useEffect(() => {
    const loadEventsList = () => {
      getEventsList(user.jwt).then(events => {
        selectPractice(events[events.length - 1]);
 
      });
  
    };

    if (user.jwt) loadEventsList();
  }, [getEventsList, user.jwt]);

  return (

   <Grid item xs={12}>
     <LoadingOverlay
  active={active}
  spinner
  text='Loading Map.......'
  >
    <Grid item xs={12} className="EventsMapPage">
      <Typography variant="h5" style={{ marginBottom: 20 }}>
        Map 
      </Typography>
      {events.events && events.events.length > 0 && (
  
        <Grid container>
          <Grid item xs={12} md={4}>
            <List component="nav" className="events-list">
              {events.events.map((event, index) => (
             
               
                <ListItem
                  button
                  divider
                  selected={selectedEvent.id === event.id ? true : false}
                  onClick={() => {
                    updateGeoLocationcall(event);
                    setactive(true);
                    //selectPractice(event);
                  }}
                  key={index}
                  className="list-item"
                >
                
                  <Grid container direction="column">
                    <Grid item xs={12}>
                      <Typography variant="h6" noWrap>
                        {event.practice!=null?event.practice.practiceName:""}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography noWrap>
                        {moment(event.eventStart).format("MM.DD.YYYY hh:mmA")}
                      </Typography>
                    </Grid>
                  </Grid>
                </ListItem>
             
            
                ))}
            </List>
          </Grid>
          <Grid item xs={8} md={8}>
            <EventsMap selectedEvent={selectedEvent}  />
          </Grid>
        </Grid>
               
      )}
      {/* <Grid container>
        <Grid item xs={12} md={4}>
          <Link to="/addpractice" className="btn-link">
            <Button variant="contained" color="primary">
              Add Practice
            </Button>
          </Link>
          <Link
            to="/listevents"
            className="btn-link"
            style={{ marginLeft: 20 }}
          >
            <Button variant="contained" color="primary">
              View events
            </Button>
          </Link>
        </Grid>
      </Grid> */}


    </Grid>
    </LoadingOverlay>
   </Grid>
  );
};

const mapDispatchToProps = {
  getEventsList: getEventsList,
  updateGeoLocation:updateGeoLocation
};

const mapStateToProps = state => {
  const { events, user } = state;
  return { events, user };
};

const connectedEventsMapPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(EventsMapPage);
export { connectedEventsMapPage as EventsMapPage };
