import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Grid,
  Typography,
  Button,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Paper,
  createMuiTheme
} from "@material-ui/core";

import Autocomplete from '@material-ui/lab/Autocomplete';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';

import { Info, Clear } from "@material-ui/icons";
import { ModalAlert } from "../../../components/Modal";

import moment from "moment";
import { addEvent, getEventsList } from "../../../_actions/events.actions";
import {
  getListPractices,
  addPractice,
  updatePractice
} from "../../../_actions/practices.actions";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";

const AddEvent = ({
  user,
  events,
  getEventsList,
  practices,
  addEvent,
  getListPractices,
  addPractice,
  updatePractice
}) => {
  const history = useHistory();

  const [eventData, setEventData] = useState({
    practice: "",
    practiceName: "",
    expectedAttendees: 0,
    eventStart: moment().toDate(),
    contactPerson: "",
    contactPhone: "",
    email: "",
    additionalInfo: "",
    eventDuration: "h05",
    attendees: "",
    eventName: "",
    address1: "",
    address2: "",
    practiceType: "",
    state: "",
    city: "",
    zip: "",
    status: "upcoming",
    eventsInPractice: []
  });
  const [modalAlert, setModalAlert] = useState({
    open: false,
    success: true,
    title: "",
    message: ""
  });

  useEffect(() => {
    const loadData = async () => {
      getListPractices(user.jwt);
      getEventsList(user.jwt);
    };

    if (user.jwt) loadData();
  }, [getListPractices, getEventsList, user.jwt]);

  const sendResults = async () => {

    try {
      if (isValidForm()) {
        // check is new practice or from list
        if (eventData.practice) {
          const practiceData = {
            id: eventData.practice,
            email: eventData.email,
            contactPhone: eventData.contactPhone,
            contactPerson: eventData.contactPerson,
            practiceName: eventData.practiceName,
            address1: eventData.address1,
            address2: eventData.address2,
            practiceType: eventData.practiceType,
            state: eventData.state,
            city: eventData.city,
            zip: eventData.zip,
            author: eventData.author
          };
          await updatePractice(practiceData, user.jwt, user.user.id);
          await addEvent(eventData, user.jwt, user.user.id);
        } else {
          await addPracticeAndEvent();
        }
        setModalAlert({
          open: true,
          success: true,
          title: "SUCCESS!",
          message: "New Event has been added to the system."
        });
      }
      else {
        setModalAlert({
          open: true,
          success: true,
          title: "WARNING!",
          message: "Not all the fields are field in"
        });
      }
    } catch (error) {
      setModalAlert({
        open: true,
        success: false,
        title: "ERROR!",
        message: error
      });
    }
  };

  const addPracticeAndEvent = async () => {
    const newPracticeID = await addPractice(eventData, user.jwt, user.user.id);
    await addEvent(
      { ...eventData, practice: newPracticeID },
      user.jwt,
      user.user.id
    );
  };

  const [showWarn, setShowWarn] = useState(false);

  const isShowWarn = selectedDate => {
    const existEventsInSelectedDate = eventData.eventsInPractice.find(event => {
      if (
        moment(event.eventStart).format("YYYY MM DD") ===
        moment(selectedDate).format("YYYY MM DD")
      ) {
        return true;
      }
      return false;
    });

    setShowWarn(existEventsInSelectedDate ? true : false);
  };

  const [showEmptyFields, setShowEmptyFields] = useState(false);

  const isValidForm = () => {
    if (
      eventData.expectedAttendees &&
      eventData.eventStart &&
      eventData.contactPerson &&
      eventData.contactPhone &&
      eventData.email &&
      eventData.eventDuration &&
      eventData.eventName &&
      eventData.address1 &&
      eventData.practiceType &&
      eventData.state &&
      eventData.city &&
      eventData.zip
    ) {
      setShowEmptyFields(false);
      return true;
    } else {
      // alert("Not all the fields are field in");
      setShowEmptyFields(true);
      return false;
    }
  };

  const selectPractice = practice => {
    const practiceID = practice ? practice.id : '';
    if (practices.practices.length > 0) {

      const selectedEvent = practices.practices.find(practice => practice.id === practiceID);
      setEventData({
        ...eventData,
        practice: practiceID,
        practiceName: selectedEvent ? selectedEvent.practiceName : "",
        address1: selectedEvent ? selectedEvent.address1 : "",
        address2: selectedEvent ? selectedEvent.address2 : "",
        city: selectedEvent ? selectedEvent.city : "",
        state: selectedEvent ? selectedEvent.state : "",
        zip: selectedEvent ? selectedEvent.zip : "",
        email: selectedEvent ? selectedEvent.email : "",
        contactPhone: selectedEvent ? selectedEvent.contactPhone : "",
        practiceType: selectedEvent && selectedEvent.practiceType !== '' && selectedEvent.practiceType !== null ? selectedEvent.practiceType : "other",
        contactPerson: selectedEvent ? selectedEvent.contactPerson : "",
        eventsInPractice: selectedEvent ? selectedEvent.events : null,
      });
    }
  };

  const formLabelsTheme = createMuiTheme({
    overrides: {
      MuiFormLabel: {
        asterisk: {
          color: "#db3131",
          "&$error": {
            color: "#db3131"
          }
        }
      }
    }
  });

  return (
    <React.Fragment>
      {modalAlert.open && (
        <ModalAlert
          close={() => {
            setModalAlert({
              ...modalAlert,
              open: false
            });

            if (modalAlert.title === 'SUCCESS!') {
              history.push('/listevents')
            }
          }}
          success={modalAlert.success}
          title={modalAlert.title}
          message={modalAlert.message}
          open={modalAlert.open}
        />
      )}
      <MuiThemeProvider theme={formLabelsTheme}>
        <Grid item xs={12} className="AddEvent">
          <Typography variant="h5">Add a New Event</Typography>
          <Grid container>
            <Typography variant="h6">Practice Information</Typography>
            <Grid container alignItems="flex-end">
              <Grid item xs={12} md={6} className="input-item">
                <Grid container alignItems="center">
                  <Grid item xs={11}>
                    <FormControl fullWidth variant="outlined">
                      <Autocomplete
                        className="practice-autocomplete"
                        id="practices-auto"
                        options={practices.practices}
                        getOptionLabel={option => option.practiceName}
                        onChange={(e, value) => { selectPractice(value); }}
                        renderInput={params => (
                          <TextField {...params} variant="outlined" fullWidth />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={1}>
                    <Clear
                      onClick={() => {
                        setEventData({
                          ...eventData,
                          practice: "",
                          practiceName: "",
                          address1: "",
                          address2: "",
                          city: "",
                          state: "",
                          zip: "",
                          email: "",
                          contactPhone: "",
                          practiceType: "",
                          contactPerson: ""
                        });
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} className="input-item">
                <TextField
                  id="new-practice-name"
                  fullWidth
                  variant="outlined"
                  label="New Practice name"
                  value={eventData.practiceName}
                  onChange={e =>
                    setEventData({
                      ...eventData,
                      practiceName: e.target.value
                    })
                  }
                />
              </Grid>
            </Grid>
            <Grid container alignItems="flex-end">
              <Grid item xs={6} md={3} className="input-item">
                <TextField
                  required
                  id="address1"
                  fullWidth
                  variant="outlined"
                  label="Address 1"
                  value={eventData.address1}
                  error={!eventData.address1 && showEmptyFields}
                  onChange={e =>
                    setEventData({
                      ...eventData,
                      address1: e.target.value
                    })
                  }
                />
              </Grid>
              <Grid item xs={6} md={3} className="input-item">
                <TextField
                  id="address2"
                  fullWidth
                  variant="outlined"
                  label="Address 2 (optional)"
                  value={eventData.address2}
                  onChange={e =>
                    setEventData({
                      ...eventData,
                      address2: e.target.value
                    })
                  }
                />
              </Grid>
              <Grid item xs={6} md={2} className="input-item">
                <TextField
                  required
                  id="city"
                  fullWidth
                  variant="outlined"
                  label="City"
                  value={eventData.city}
                  error={!eventData.city && showEmptyFields}
                  onChange={e =>
                    setEventData({
                      ...eventData,
                      city: e.target.value
                    })
                  }
                />
              </Grid>
              <Grid item xs={6} md={2} className="input-item">
                <TextField
                  required
                  id="state"
                  fullWidth
                  variant="outlined"
                  label="State"
                  value={eventData.state}
                  error={!eventData.state && showEmptyFields}
                  onChange={e =>
                    setEventData({
                      ...eventData,
                      state: e.target.value
                    })
                  }
                />
              </Grid>
              <Grid item xs={6} md={2} className="input-item">
                <TextField
                  required
                  id="zip"
                  fullWidth
                  variant="outlined"
                  label="Zip code"
                  value={eventData.zip}
                  error={!eventData.zip && showEmptyFields}
                  onChange={e =>
                    setEventData({
                      ...eventData,
                      zip: e.target.value
                    })
                  }
                />
              </Grid>
            </Grid>
            <Grid container alignItems="flex-end">
              <Grid item xs={6} md={4} className="input-item">
                <TextField
                  required
                  id="email"
                  fullWidth
                  variant="outlined"
                  label="E-Mail"
                  value={eventData.email}
                  error={!eventData.email && showEmptyFields}
                  onChange={e =>
                    setEventData({
                      ...eventData,
                      email: e.target.value
                    })
                  }
                />
              </Grid>
              <Grid item xs={6} md={4} className="input-item">
                <TextField
                  required
                  fullWidth
                  variant="outlined"
                  id="phone-number"
                  value={eventData.contactPhone}
                  error={!eventData.contactPhone && showEmptyFields}
                  label="Contact phone"
                  onChange={e =>
                    setEventData({ ...eventData, contactPhone: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={6} md={4} className="input-item">
                <FormControl required fullWidth variant="outlined">
                  <InputLabel id="practice-type-label">Practice Type</InputLabel>
                  <Select
                    labelId="practice-type-label"
                    id="practice-type"
                    fullWidth
                    value={eventData.practiceType}
                    error={!eventData.practiceType && showEmptyFields}
                    onChange={e =>
                      setEventData({
                        ...eventData,
                        practiceType: e.target.value
                      })
                    }
                  >


                    <MenuItem value={"Endodontist"}>Endodontist</MenuItem>
                    <MenuItem value={"GeneralDentistry"}>General Dentistry</MenuItem>
                    <MenuItem value={"OralSurgeonPathologist"}>Oral Surgeon Pathologist</MenuItem>
                    <MenuItem value={"Orthodontist"}>Orthodontist</MenuItem>
                    <MenuItem value={"PediatricDentistry"}>Pediatric Dentistry</MenuItem>
                    <MenuItem value={"Prosthodontist"}>Prosthodontist</MenuItem>
                    <MenuItem value={"DDSDMDStudent"}>DDS/DMD Student</MenuItem>
                    <MenuItem value={"RDHStudent"}>RDH Student</MenuItem>
                    <MenuItem value={"Other"}>Other</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container alignItems="flex-end">
              <Grid item xs={12} md={6} className="input-item">
                <TextField
                  required
                  id="event-name"
                  fullWidth
                  variant="outlined"
                  label="Event name"
                  value={eventData.eventName}
                  error={!eventData.eventName && showEmptyFields}
                  onChange={e =>
                    setEventData({
                      ...eventData,
                      eventName: e.target.value
                    })
                  }
                />
              </Grid>
            </Grid>
            {/* <Grid container alignItems="flex-end">
            <Grid item xs={12} md={6} className="input-item">
            <FormControl fullWidth variant="outlined">
                <InputLabel id="event-status-label">Status</InputLabel>
                <Select
                  labelId="event-status-label"
                  id="event-status"
                  fullWidth
        
                  value={eventData.status}
                  error={!eventData.status && showEmptyFields}
                  onChange={e =>
                    setEventData({
                      ...eventData,
                      status: e.target.value
                    })
                  }
                >


                  <MenuItem   value={"upcoming"}>Upcoming</MenuItem>
                  <MenuItem value={"complete"}>Complete</MenuItem>                   
                  <MenuItem value={"cancelled"}>Cancel</MenuItem>
                 
                </Select>
              </FormControl>
            </Grid> 
          </Grid>*/}
          </Grid>
          <Grid container>
            <Typography variant="h6">Contact Person</Typography>
            <Grid container alignItems="flex-end">
              <Grid item xs={12} md={6} className="input-item">
                <TextField
                  required
                  id="full-name"
                  fullWidth
                  variant="outlined"
                  label="Full Name"
                  value={eventData.contactPerson}
                  error={!eventData.contactPerson && showEmptyFields}
                  onChange={e =>
                    setEventData({
                      ...eventData,
                      contactPerson: e.target.value
                    })
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Typography variant="h6">Event Information</Typography>
            <Grid container>
              <Grid item xs={12} md={4} className="input-item">
                <TextField
                  required
                  id="NumberExpectedAttendees"
                  fullWidth
                  variant="outlined"
                  label="Number of expected Attendees"
                  value={eventData.expectedAttendees}
                  error={!eventData.expectedAttendees && showEmptyFields}
                  onChange={e =>
                    setEventData({
                      ...eventData,
                      expectedAttendees: Number(e.target.value)
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} md={4} className="input-item">
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="event-duraton-label">
                    Duration of Event
                </InputLabel>
                  <Select
                    labelId="event-duraton-label"
                    id="event-duraton"
                    fullWidth
                    value={eventData.eventDuration}
                    error={!eventData.eventDuration && showEmptyFields}
                    onChange={e =>
                      setEventData({
                        ...eventData,
                        eventDuration: e.target.value
                      })
                    }
                  >
                    <MenuItem value={"h05"}>0.5 hours</MenuItem>
                    <MenuItem value={"h1"}>1 hour</MenuItem>
                    <MenuItem value={"h2"}>2 hours</MenuItem>
                    <MenuItem value={"h3"}>3 hours</MenuItem>
                    <MenuItem value={"h4"}>4 hours</MenuItem>
                    <MenuItem value={"h5"}>5 hours</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4} className="input-item">
                <DatePicker
                  selected={eventData.eventStart}
                  onChange={e => {
                    const selectedDate = e;
                    isShowWarn(selectedDate);

                    setEventData({
                      ...eventData,
                      eventStart: selectedDate
                    });
                  }}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  timeCaption="time"
                  dateFormat="MMMM d, yyyy h:mm aa"
                />

              </Grid>
            </Grid>
          </Grid>
          <Grid container alignItems="flex-end">
            <Typography variant="h6">Additional Information</Typography>
            <Grid container>
              <Grid item xs={12} className="input-item">
                <TextField
                  id="additional-information"
                  multiline
                  fullWidth
                  variant="outlined"
                  rows="4"
                  value={eventData.additionalInfo}
                  onChange={e =>
                    setEventData({
                      ...eventData,
                      additionalInfo: e.target.value
                    })
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Button
            variant="contained"
            color="primary"
            className="btn"
            onClick={sendResults}
          >
            SUBMIT EVENT
        </Button>
          {showWarn && (
            <Grid container className="message">
              <Paper className="message__paper">
                <div className="message__paper_icon">
                  <Info />
                </div>
                <Typography>
                  Another event already exists for this office on{" "}
                  {moment(eventData.eventStart).format("MM.DD.YYYY")}
                </Typography>
              </Paper>
            </Grid>
          )}
        </Grid>
      </MuiThemeProvider>

    </React.Fragment>
  );
};

const mapDispatchToProps = {
  addEvent: addEvent,
  getListPractices: getListPractices,
  getEventsList: getEventsList,
  addPractice: addPractice,
  updatePractice: updatePractice
};

const mapStateToProps = state => {
  const { user, practices, events } = state;
  return { user, practices, events };
};

const connectedAddEvent = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddEvent);
export { connectedAddEvent as AddEvent };
