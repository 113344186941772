export const practicessConstants = {
  GET_PRACTICES_LIST_REQUEST: "GET_PRACTICES_LIST_REQUEST",
  GET_PRACTICES_LIST_SUCCESS: "GET_PRACTICES_LIST_SUCCESS",
  GET_PRACTICES_LIST_ERROR: "GET_PRACTICES_LIST_ERROR",

  ADD_NEW_PRACTICE_REQUEST: "ADD_NEW_PRACTICE_REQUEST",
  ADD_NEW_PRACTICE_SUCCESS: "ADD_NEW_PRACTICE_SUCCESS",
  ADD_NEW_PRACTICE_ERROR: "ADD_NEW_PRACTICE_ERROR",

  EDIT_PRACTICE_REQUEST: "EDIT_PRACTICE_REQUEST",
  EDIT_PRACTICE_SUCCESS: "EDIT_PRACTICE_SUCCESS",
  EDIT_PRACTICE_ERROR: "EDIT_PRACTICE_ERROR",

  SET_ARCHIVE_STATUS_REQUEST: "SET_ARCHIVE_STATUS_REQUEST",
  SET_ARCHIVE_STATUS_SUCCESS: "SET_ARCHIVE_STATUS_SUCCESS",
  SET_ARCHIVE_STATUS_ERROR: "SET_ARCHIVE_STATUS_ERROR" ,
  
  GEOLOCATION_PRACTICE_REQUEST: "GEOLOCATION_PRACTICE_REQUEST",
  GEOLOCATION_PRACTICE_SUCCESS: "GEOLOCATION_PRACTICE_SUCCESS",
  GEOLOCATION_PRACTICE_ERROR: "GEOLOCATION_PRACTICE_ERROR",
};
