import React, {  useEffect } from "react";

// import {
//   Grid,
//   Typography,
//   FormControl,
//   InputLabel,
//   Select,
//   MenuItem,
//   Button
// } from "@material-ui/core";

import {
  Grid,
 
  Button
 
} from "@material-ui/core";
import MaterialTable from "material-table";

import { connect } from "react-redux";
import moment from "moment";
import { Link, useHistory } from "react-router-dom";
import { getInvoicesEvents, approveInvoice, processInvoice } from "../../../../_actions/invoices.actions";
import { getListPractices } from "../../../../_actions/practices.actions";
import { editEvent } from "../../../../_actions/events.actions";
const InvoicesList = ({
  getInvoicesEvents,
  getListPractices,
  user,
  invoices,
  practices,
  approveInvoice,
  processInvoice,
  editEvent
}) => {
  // const [practiceData, setPracticeData] = useState({
  //   practice: "",
  //   practiceName: ""
  // });
  const history = useHistory();
  useEffect(() => {
    const loadInvoicesList = () => {
      getListPractices(user.jwt);
      getInvoicesEvents(user.jwt);
    };

    if (user.jwt) loadInvoicesList();
  }, [getInvoicesEvents, getListPractices, user.jwt]);

  const sendApproved = async eventParams => {

    try {


      const Invoicedata = {
        id: eventParams.id
      };

      const data = {
        id: eventParams.event.id,
        eventName: eventParams.event.eventName,
        approvedOn: new Date().toISOString(),

        status: "approved"
      };


      await editEvent(data, user.jwt, user.user.id);



      await approveInvoice(Invoicedata, user.jwt);
      getListPractices(user.jwt);
      getInvoicesEvents(user.jwt);
      history.push("/invoices");
    }
    catch (error) {

    }
  };
  const sendProcessed = async eventParams => {

    try {





      const Invoicedata = {
        id: eventParams.id
      };



      const data = {
        id: eventParams.event.id,
        eventName: eventParams.event.eventName,
        processedOn: new Date().toISOString(),

        status: "processed"
      };


      await editEvent(data, user.jwt, user.user.id);

      await processInvoice(Invoicedata, user.jwt);
      getListPractices(user.jwt);
      getInvoicesEvents(user.jwt);

      history.push("/invoices");
    }
    catch (error) {
      console.log(error);
    }
  };
  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Grid container>
          {/* <Grid item xs={12} md={6}>
            <Typography variant="h5" style={{ marginBottom: 20 }}>
              Invoices
            </Typography>
          </Grid> */}
          {/* <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel id="select-educator">Select Practice</InputLabel>
              <Select
                labelId="select-practice-label"
                id="select-practice"
                fullWidth
                value={practiceData.practice ? practiceData.practice : ""}
                onChange={e => {
                  const selectedPractice = practices.practices.find(
                    practice => {
                      if (practice.id === e.target.value) return true;
                      return false;
                    }
                  );

                  setPracticeData({
                    practice: selectedPractice.id || "",
                    practiceName: selectedPractice.practiceName || ""
                  });
                }}
              >
                {!practices.pending &&
                  !practices.error &&
                  practices.practices.map((practice, index) => (
                    <MenuItem key={index} value={practice.id}>
                      {practice.practiceName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid> */}
        </Grid>
       
        {invoices.invoices && invoices.invoices.length > 0 && (
        
          <MaterialTable
            title="Invoices"
            columns={[
              {
                title: 'Event Name',
                field: 'eventName',
                width: '300px'
              },
              {
                title: 'Practice Name',
                field: 'practiceName',
                width: '300px'
              }, {
                title: 'Date of Event',
                field: 'eventStart'
              }, {
                title: 'Educator',
                field: 'educator'
              },
              //  {
              //   title: 'Attendees',
              //   field: 'attendees'
              // },
               {
                title: 'Event Cost',
                field: 'eventCost',
                render: tableData => {
                  return (
                    `${ "$"+ parseFloat(Number (tableData.eventCost)).toFixed(2)}`
                  
                  )
                }
              }
               ,
              //  {
              //   title: 'Educator Fee',
              //   field: 'educatorFee'
              // }, 
              {
                 title: 'Total Cost',
                 field: 'totalCost',
                 render: tableData => {
                  return (
                    `${ "$"+ parseFloat(Number (tableData.totalCost)).toFixed(2)}`
                  )
                }
               }, 
              {
                title: 'Event Details',
                field: 'id',
                render: tableData => {
                  return (
                    tableData.event?
                    <Link   to={`/event/${tableData.event.id}`}>view</Link>:""
                  )
                }
              }, {
                title: 'Invoice Date',
                field: 'dateSubmitted'
              }, {
                title: 'Approve',
                field: 'approvedOn',
                render: tableData => {
                  return (
                    tableData.approvedOn ? moment(tableData.approvedOn).format(
                      "MM.DD.YYYY hh:mm A"
                    ) : <Button
                      color="primary"
                      onClick={() => sendApproved(tableData)}
                    >
                        APPROVE
                  </Button>
                  )
                }
              }, {
                title: 'Approved by',
                field: 'approvedBy'
              }, {
                title: 'Process',
                field: 'processedOn',
                render: tableData => {
                  return (
                    tableData.processedOn ? moment(tableData.processedOn).format(
                      "MM.DD.YYYY hh:mm A"
                    ) : <Button
                      color="primary"
                      onClick={() => sendProcessed(tableData)}
                    >
                        PROCESS
                  </Button>
                  )
                }
              }, {
                title: 'Processed by',
                field: 'provessedBy'
              }
            ]}
      
            data={invoices.invoices.map(invoice => (
             
              {
           
              eventName:invoice.event?invoice.event.eventName:'',
              practiceName: invoice.event ? invoice.event.practice? invoice.event.practice.practiceName : '':'',
              eventStart: moment(invoice.event && invoice.event.eventStart).format(
                "MM.DD.YYYY hh:mm A"
              ),
              educator: invoice.author? invoice.author.username:user.username,
              attendees:invoice.event!=null?invoice.event.attendees!==undefined && invoice.event.attendees!==null   ? invoice.event.attendees.length:0:"",
              eventCost: invoice.lunchCost,
              educatorFee: invoice.educatorFee,
              totalCost: invoice.lunchCost + invoice.educatorFee,
              id: invoice.id,
              dateSubmitted: invoice.dateSubmitted
                ? invoice.dateSubmitted
                : moment().format("MM.DD.YYYY hh:mm A"),
              approvedOn: invoice.approvedOn,
              approvedBy: invoice.approvedBy ? invoice.approvedBy.username : "-",
              provessedBy: invoice.processedBy ? invoice.processedBy.username : '-',
              processedOn: invoice.processedOn,
              event:  invoice.event
            }))}
            options={{
              sorting: true,
              draggable: false,
              filtering: false,
              toolbar: true,
            }}
          >

          </MaterialTable>
        )}
      </Grid>
    </React.Fragment>
  );
};

const mapDispatchToProps = {
  getInvoicesEvents: getInvoicesEvents,
  getListPractices: getListPractices,
  approveInvoice: approveInvoice,
  processInvoice: processInvoice,
  editEvent: editEvent
}
const mapStateToProps = state => {
  const { invoices, user, practices } = state;
  return { invoices, user, practices };
};

const connectedInvoicesList = connect(
  mapStateToProps,
  mapDispatchToProps
)(InvoicesList);
export { connectedInvoicesList as InvoicesList };
