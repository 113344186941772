import axios from "axios";
import { server } from "../_constants";

export const userLoginAPI = (loginName, password) => {
  return axios.post(
    `${server}/auth/local`,
    {
      identifier: loginName,
      password: password
    },
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    }
  );
};
export const userSendemailAPI = (userData) => {
const locationname=window.location.origin;
  return axios.post(
    `${server}/email`,
    {
      to:userData.email,
      subject:"You have been added as ED-NET USER",
      html:" Hello "+userData.firstName +" "+userData.lastName+" !!!!<br/>  <br/> <br/>here is your  username <b>"+ userData.username+"</b> and password <b>"+userData.password +"</b> for login. <br/><br/> Kindly user below link for login into application <br/> <br/> "+locationname+"/#/ <br/><br/> Thanks"
     
    },
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    }
  );
};