export const eventsConstants = {
  GET_EVENTS_LIST_REQUEST: "GET_EVENTS_LIST_REQUEST",
  GET_EVENTS_LIST_SUCCESS: "GET_EVENTS_LIST_SUCCESS",
  GET_EVENTS_LIST_ERROR: "GET_EVENTS_LIST_ERROR",

  GET_EVENT_BY_ID_REQUEST: "GET_EVENT_BY_ID_REQUEST",
  GET_EVENT_BY_ID_SUCCESS: "GET_EVENT_BY_ID_SUCCESS",
  GET_EVENT_BY_ID_ERROR: "GET_EVENT_BY_ID_ERROR",

  ADD_NEW_EVENT_REQUEST: "ADD_NEW_EVENT_REQUEST",
  ADD_NEW_EVENT_SUCCESS: "ADD_NEW_EVENT_SUCCESS",
  ADD_NEW_EVENT_ERROR: "ADD_NEW_EVENT_ERROR",

  EDIT_EVENT_REQUEST: "EDIT_EVENT_REQUEST",
  EDIT_EVENT_SUCCESS: "EDIT_EVENT_SUCCESS",
  EDIT_EVENT_ERROR: "EDIT_EVENT_ERROR",
  
 SURVEY_EVENT_REQUEST: "SURVEY_EVENT_REQUEST",
 SURVEY_EVENT_SUCCESS: "SURVEY_EVENT_SUCCESS",
 SURVEY_EVENT_ERROR: "SURVEY_EVENT_ERROR",

DELETE_EVENT_REQUEST: "DELETE_EVENT_REQUEST",
DELETE_EVENT_SUCCESS: "DELETE_EVENT_SUCCESS",
DELETE_EVENT_ERROR: "DELETE_EVENT_ERROR",


EMAIL_LIST_REQUEST: "EMAIL_LIST_REQUEST",
EMAIL_LIST_SUCCESS: "EMAIL_LIST_SUCCESS",
EMAIL_LIST_ERROR: "EMAIL_LIST_ERROR"
};
