import React from "react";
import { connect } from "react-redux";
import { Grid, Container, Typography } from "@material-ui/core";
import { EventsTable } from "../../components/EventsTable";
import { Calendar } from "../../components/Calendar";
import { UpcomingEventBlock } from "../../components/UpcomingEventBlock";
import { ApproveEventsBlock } from "../../components/ApproveEventsBlock";
// import { userType } from "../../_constants";
// import { server } from "../../_constants";
import moment from "moment";
import { EventsMap } from "../../components/Map";

const Dashboard = ({ user, events }) => {
  let nearestEvent;

  if(events!==null && events.events!==null )
  {
  if (events.events.length > 0) {
    const now = moment();
    let minimalDiff = -Infinity;

    events.events.forEach(event => {

      const eventData = moment(event.eventStart);
      const differentBeetwenNow = now.diff(eventData);

      if (differentBeetwenNow < 0 && event.status!=="approved") {
        if (differentBeetwenNow > minimalDiff) {
          minimalDiff = differentBeetwenNow;
          nearestEvent = event;
        }
      }
    });
    if (nearestEvent) {
      nearestEvent = {
        ...nearestEvent,
        latitude: Number(nearestEvent.practice.latitude) || "",
        longitude: Number(nearestEvent.practice.longitude) || ""
      };
    }
  }
}

  return (
    <React.Fragment>
      <Grid item xs={4} className="Dashboard">
        <Container>

          {user.user.role.type === "manager" ? (
            <ApproveEventsBlock />
          ) : (
              <UpcomingEventBlock />
            )}
          {/* <div className="newsBoard Dashboard__board">
            <Typography variant="h5">EdNet News</Typography>
            <div className="newsBoard__news">
              <Typography variant="body1">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam
                at porttitor sem. Aliquam erat volutpat.
              </Typography>
              <Typography variant="body1"> */}
                {/* <Link to="/">
                  Read more <ArrowForward />
                </Link> */}
              {/* </Typography>
            </div>
            <div className="newsBoard__news">
              <Typography variant="body1">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam
                at porttitor sem. Aliquam erat volutpat.
              </Typography>
              <Typography variant="body1"> */}
                {/* <Link to="/">
                  Read more <ArrowForward />
                </Link> */}
              {/* </Typography>
            </div> */}

            {/* <a href={server} style={user.user.role.type === "manager" ? { display: "flex" } : { display: "none" }} target="_blank" rel="noopener noreferrer">
              <Button variant="contained" color="primary">
                Edit
              </Button>
            </a> */}
          {/* </div> */}
        </Container>
      </Grid>
      <Grid item xs={8} className="Dashboard">
        <Grid container direction="column">
          <Grid item xs={12}>
            <EventsTable />
          </Grid>
          <Typography variant="h5"></Typography>
          <Grid item xs={12}>
            <Calendar />
          </Grid>
          {/* <Grid item xs={12} className="LNL-events-controls">
            <Link to="/listevents" className="btn-link" style={{ marginRight: 40 }}>
              <Button variant="contained" color="primary">
                View events
              </Button>
            </Link>
            <Link to="/addevent" className="btn-link">
              <Button variant="contained" color="primary">
                Add event
              </Button>
            </Link>
          </Grid> */}
          {nearestEvent && (
            <Grid item xs={12}>
              <Typography variant="h5"></Typography>
              <EventsMap selectedEvent={nearestEvent} />
            </Grid>
          )}
        </Grid>
      </Grid>

    </React.Fragment>
  );
};

const mapStateToProps = state => {
  const { user, events } = state;
  return { user, events };
};

const connectedDashboard = connect(mapStateToProps, null)(Dashboard);
export { connectedDashboard as Dashboard };
