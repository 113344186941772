import { practicessConstants } from "../_constants";
import {
  getListPracticesAPI,
  updatePracticeAPI,
  addPracticeAPI,
  archivePracticeAPI,
  updateGeoLocationAPI
} from "../_api/practices";
import { parseGraphQLError } from "../_helpers/errors";

export const getListPractices = token => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch({ type: practicessConstants.GET_PRACTICES_LIST_REQUEST });

      getListPracticesAPI(token)
      .then(data => {
        dispatch({
          type: practicessConstants.GET_PRACTICES_LIST_SUCCESS,
          payload: data.data
        });
      
         resolve(data);
      
      })
      .catch(error => {
        let errorMessage = parseGraphQLError(error);

        dispatch({
          type: practicessConstants.GET_PRACTICES_LIST_ERROR,
          payload: errorMessage
        });
          reject("errorMessage");
       
      });
  });
};

export const addPractice = (mutation, token, id) => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch({ type: practicessConstants.ADD_NEW_PRACTICE_REQUEST });
    addPracticeAPI(mutation, token, id)
      .then(data => {
        dispatch({
          type: practicessConstants.ADD_NEW_PRACTICE_SUCCESS
        });

        resolve(data.data.data.createPractice.practice.id);
      })
      .catch(error => {
        let errorMessage = parseGraphQLError(error);

        dispatch({
          type: practicessConstants.ADD_NEW_PRACTICE_ERROR,
          payload: errorMessage
        });

        reject(errorMessage);
      });
  });
};

export const updatePractice = (mutation, token, id) => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch({ type: practicessConstants.EDIT_PRACTICE_REQUEST });
    updatePracticeAPI(mutation, token, id)
      .then(() => {
        dispatch({
          type: practicessConstants.EDIT_PRACTICE_SUCCESS
        });

        resolve();
      })
      .catch(error => {
        let errorMessage = parseGraphQLError(error);

        dispatch({
          type: practicessConstants.EDIT_PRACTICE_ERROR,
          payload: errorMessage
        });

        reject(errorMessage);
      });
  });
};

export const archivePractice = (
  practiceID,
  archiveStatus,
  token
) => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch({ type: practicessConstants.SET_ARCHIVE_STATUS_REQUEST });

    archivePracticeAPI(practiceID, archiveStatus, token)
      .then(data => {
        dispatch({
          type: practicessConstants.SET_ARCHIVE_STATUS_SUCCESS
        });

        resolve(data);
      })
      .catch(error => {
        let errorMessage = parseGraphQLError(error);

        dispatch({
          type: practicessConstants.SET_ARCHIVE_STATUS_ERROR,
          payload: errorMessage
        });

        reject(errorMessage);
      });
  });
};

export const updateGeoLocation = (mutation, token) => dispatch => {
  return new Promise((resolve, reject) => {

    dispatch({ type: practicessConstants.GEOLOCATION_PRACTICE_REQUEST });
    updateGeoLocationAPI(mutation, token)
      .then(data => {
        dispatch({
          type: practicessConstants.GEOLOCATION_PRACTICE_SUCCESS,
          payload:data.data
        });

      resolve(data);
 
   
     
      })
      .catch(error => {
        let errorMessage = parseGraphQLError(error);

        dispatch({
          type: practicessConstants.GEOLOCATION_PRACTICE_ERROR,
          payload: errorMessage
        });
        reject(errorMessage);
       
      });
  });
};
