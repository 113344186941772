import React, { useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Grid,
  Typography,
  Button,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Paper,
  createMuiTheme
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ModalAlert } from "../../../components/Modal";
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';

import {
  addPractice,
  getListPractices
} from "../../../_actions/practices.actions";

const AddPractice = ({ user, addPractice, getListPractices, practices }) => {
  const history = useHistory();
  const [practiceData, setPracticeData] = useState({
    practiceName: "",
    practiceType: "",
    contactPerson: "",
    contactPhone: "",
    email: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: ""
   
  });

  const [modalAlert, setModalAlert] = useState({
    open: false
  });

  const sendResults = async () => {
    try {
      if (isValidForm()) {
        await addPractice(practiceData, user.jwt, user.user.id);
        setModalAlert(true);

        setModalAlert({
          open: true,
          success: true,
          title: "SUCCESS!",
          message: "New Practice has been added to the system."
        });
      }
      else {

        setModalAlert({
          open: true,
          success: true,
          title: "WARNING!",
          message: "Not all the fields are field in."
        });
      }
    } catch (error) {
      setModalAlert({
        open: true,
        success: false,
        title: "ERROR!",
        message: error
      });
    }
  };

  const [showEmptyFields, setShowEmptyFields] = useState(false);
  const [selectedPractice, setSelectedPractice] = useState(null);

  const isValidForm = () => {
    if (
      practiceData.practiceName &&
      practiceData.practiceType &&
      practiceData.contactPerson &&
      practiceData.contactPhone &&
      practiceData.email &&
      practiceData.address1 &&
      practiceData.city &&
      practiceData.state &&
      practiceData.zip
    ) {
      setShowEmptyFields(false);
      return true;
    } else {
      ///alert("Not all the fields are field in");
      setShowEmptyFields(true);
      return false;
    }
  };

  const formLabelsTheme = createMuiTheme({
    overrides: {
      MuiFormLabel: {
        asterisk: {
          color: "#db3131",
          "&$error": {
            color: "#db3131"
          }
        }
      }
    }
  });

  return (
    <React.Fragment>
      {modalAlert.open && (
        <ModalAlert
          close={() => {
            setModalAlert({
              ...modalAlert,
              open: false
            });
            history.push("/listpractices");
          }}
          success={modalAlert.success}
          title={modalAlert.title}
          message={modalAlert.message}
          open={modalAlert.open}
        />
      )}
      <Grid item xs={12} className="AddEvent">
        <Typography variant="h5">Add New Practice</Typography>
        <Grid container>
          <Typography variant="h6">Practice Look-up</Typography>
          <Grid container alignItems="flex-end">
            <Grid item xs={12} md={6} className="input-item">
              <Autocomplete
                className="practice-autocomplete"
                id="practices-auto"
                options={practices.practices}
                getOptionLabel={option => option.practiceName}
                style={{ width: 300 }}
                onChange={(e, value) => { setSelectedPractice(value); }}
                renderInput={params => (
                  <TextField {...params} variant="outlined" fullWidth />
                )}
              />
              {selectedPractice && (
                <Paper className="Practice-item-wrapper">
                  <Grid
                    container
                    alignItems="center"
                    className="Practice-item__element"
                  >
                    <Grid item xs={12}>
                      <Typography variant="h6" noWrap>
                        {selectedPractice.practiceName}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography noWrap>
                        {`${selectedPractice.address1} ${selectedPractice.address2}`}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography noWrap>
                        {`${selectedPractice.city}, ${selectedPractice.state} ${selectedPractice.zip}`}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography noWrap>
                        {selectedPractice.email}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography noWrap>
                        {selectedPractice.author.username}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography noWrap>
                        {selectedPractice.practiceType}
                      </Typography>
                    </Grid>
                  </Grid>
                </Paper>
              )}
            </Grid>
          </Grid>
        </Grid>
        <MuiThemeProvider theme={formLabelsTheme}>
          <Grid container>
            <Typography variant="h6">Practice Information</Typography>
            <Grid container alignItems="flex-end">
              <Grid item xs={12} md={6} className="input-item">
                <TextField
                  required
                  id="practice-name"
                  fullWidth
                  variant="outlined"
                  label="Add New Practice"
                  value={practiceData.practiceName}
                  error={!practiceData.practiceName && showEmptyFields}
                  onChange={e =>
                    setPracticeData({
                      ...practiceData,
                      practiceName: e.target.value
                    })
                  }
                />
              </Grid>
            </Grid>
            <Grid container alignItems="flex-end">
              <Grid item xs={6} md={3} className="input-item">
                <TextField
                  required
                  id="address1"
                  fullWidth
                  variant="outlined"
                  label="Address 1"
                  value={practiceData.address1}
                  error={!practiceData.address1 && showEmptyFields}
                  onChange={e =>
                    setPracticeData({
                      ...practiceData,
                      address1: e.target.value
                    })
                  }
                />
              </Grid>
              <Grid item xs={6} md={3} className="input-item">
                <TextField
                  id="address2"
                  fullWidth
                  variant="outlined"
                  label="Address 2 (optional)"
                  value={practiceData.address2}
                  onChange={e =>
                    setPracticeData({
                      ...practiceData,
                      address2: e.target.value
                    })
                  }
                />
              </Grid>
              <Grid item xs={6} md={2} className="input-item">
                <TextField
                  required
                  id="city"
                  fullWidth
                  variant="outlined"
                  label="City"
                  value={practiceData.city}
                  error={!practiceData.city && showEmptyFields}
                  onChange={e =>
                    setPracticeData({
                      ...practiceData,
                      city: e.target.value
                    })
                  }
                />
              </Grid>
              <Grid item xs={6} md={2} className="input-item">
                <TextField
                  required
                  id="state"
                  fullWidth
                  variant="outlined"
                  label="State"
                  value={practiceData.state}
                  error={!practiceData.state && showEmptyFields}
                  onChange={e =>
                    setPracticeData({
                      ...practiceData,
                      state: e.target.value
                    })
                  }
                />
              </Grid>
              <Grid item xs={6} md={2} className="input-item">
                <TextField
                  required
                  id="zip"
                  fullWidth
                  variant="outlined"
                  label="Zip code"
                  value={practiceData.zip}
                  error={!practiceData.zip && showEmptyFields}
                  onChange={e =>
                    setPracticeData({
                      ...practiceData,
                      zip: e.target.value
                    })
                  }
                />
              </Grid>
            </Grid>
            <Grid container alignItems="flex-end">
              <Grid item xs={6} md={4} className="input-item">
                <TextField
                  required
                  id="email"
                  fullWidth
                  variant="outlined"
                  label="E-Mail"
                  value={practiceData.email}
                  error={!practiceData.email && showEmptyFields}
                  onChange={e =>
                    setPracticeData({
                      ...practiceData,
                      email: e.target.value
                    })
                  }
                />
              </Grid>
              <Grid item xs={6} md={4} className="input-item">
                <TextField
                  required
                  fullWidth
                  variant="outlined"
                  id="phone-number"
                  label="Contact Phone"
                  value={practiceData.contactPhone}
                  error={!practiceData.contactPhone && showEmptyFields}
                  onChange={e =>
                    setPracticeData({
                      ...practiceData,
                      contactPhone: e.target.value
                    })
                  }
                  placeholder="+1 (___)__-__-___-__"
                />
              </Grid>
              <Grid item xs={6} md={4} className="input-item">
                <FormControl required fullWidth variant="outlined">
                  <InputLabel id="practice-type-label">Practice Type</InputLabel>
                  <Select
                    labelId="practice-type-label"
                    id="practice-type"
                    fullWidth
                    value={practiceData.practiceType}
                    error={!practiceData.practiceType && showEmptyFields}
                    onChange={e =>
                      setPracticeData({
                        ...practiceData,
                        practiceType: e.target.value
                      })
                    }
                  >
                    <MenuItem value={"Endodontist"}>Endodontist</MenuItem>
                    <MenuItem value={"GeneralDentistry"}>General Dentistry</MenuItem>
                    <MenuItem value={"OralSurgeonPathologist"}>Oral Surgeon/Pathologist</MenuItem>
                    <MenuItem value={"Orthodontist"}>Orthodontist</MenuItem>
                    <MenuItem value={"PediatricDentistry"}>Prosthodontist</MenuItem>
                    <MenuItem value={"Prosthodontist"}>Prosthodontist</MenuItem>
                    <MenuItem value={"DDSDMDStudent"}>DDS/DMD Student</MenuItem>
                    <MenuItem value={"RDHStudent"}>RDH Student</MenuItem>
                    <MenuItem value={"Other"}>Other</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Typography variant="h6">Contact Person</Typography>
            <Grid container alignItems="flex-end">
              <Grid item xs={12} md={6} className="input-item">
                <TextField
                  required
                  id="full-name"
                  fullWidth
                  variant="outlined"
                  label="Full Name"
                  value={practiceData.contactPerson}
                  error={!practiceData.contactPerson && showEmptyFields}
                  onChange={e =>
                    setPracticeData({
                      ...practiceData,
                      contactPerson: e.target.value
                    })
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </MuiThemeProvider>

        <Button
          variant="contained"
          color="primary"
          className="btn"
          onClick={sendResults}
        >
          Add practice
        </Button>
      </Grid>
    </React.Fragment>
  );
};

const mapDispatchToProps = {
  addPractice: addPractice,
  getListPractices: getListPractices
};

const mapStateToProps = state => {
  const { user, practices } = state;
  return { user, practices };
};

const connectedAddPractice = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddPractice);
export { connectedAddPractice as AddPractice };
