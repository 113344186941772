import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Button,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Paper
} from "@material-ui/core";
import { AddCircle, Close,Info, Description, Image  } from "@material-ui/icons";
import { ModalAlert } from "../../../components/Modal";

import { useParams, useHistory } from "react-router-dom";
import { connect } from "react-redux";

import moment from "moment";
import { Attendee } from "../../../components/Attendee";
import { getEventByID, editEvent ,deleteEventByID} from "../../../_actions/events.actions";
import { uploadFile } from "../../../_api/files";

import {
  getListPractices,
  updatePractice
} from "../../../_actions/practices.actions";
import {
  addAttendee,
  editAttendee,
  unAssignAttendee
} from "../../../_actions/attendees.actions";
import { createInvoice, editInvoice } from "../../../_actions/invoices.actions";
import { getUsersList } from "../../../_actions/users.actions";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { server } from "../../../_constants";
import axios from "axios";
// const eventStatus = {
//   PENDING: "pending",
//   CANCELLED: "cancelled"
// };

const EditEvent = ({
  getEventByID,
  getListPractices,
  updatePractice,
  editEvent,
  addAttendee,
  editAttendee,
  unAssignAttendee,
  createInvoice,
  editInvoice,
  user,
  practices,
  getUsersList,
  users
}) => {
  const history = useHistory();
  const { id } = useParams();
  const [filesList, setFilesList] = useState(null);
  const [showEmptyFields, setShowEmptyFields] = useState(false);
  useEffect(() => {
    const loadData = async () => {
      const event = await getEventByID(id, user.jwt);
         getListPractices(user.jwt);
        await getUsersList(user.jwt);
      setFilesList(event.data.data.event.invoice ? event.data.data.event.invoice.receipts : []);
      
      setEventData({
        id: event.data.data.event.id,
        eventName: event.data.data.event.eventName,
        expectedAttendees: event.data.data.event.expectedAttendees,
        eventStart: moment(event.data.data.event.eventStart).toDate(),
        additionalInfo: event.data.data.event.additionalInfo,
        eventDuration: event.data.data.event.eventDuration,
        attendees: event.data.data.event.attendees,
        invoice: event.data.data.event.invoice,
        status:event.data.data.event.status,
        practice: event.data.data.event.practice.id,
        email: event.data.data.event.practice.email,
        contactPhone: event.data.data.event.practice.contactPhone,
        contactPerson: event.data.data.event.practice.contactPerson,
        practiceName: event.data.data.event.practice.practiceName,
        address1: event.data.data.event.practice.address1,
        address2: event.data.data.event.practice.address2,
        practiceType: event.data.data.event.practice.practiceType,
        state: event.data.data.event.practice.state,
        city: event.data.data.event.practice.city,
        zip: event.data.data.event.practice.zip,
        author: event.data.data.event.practice.author,
        isInvoiceExist: event.data.data.event.invoice ? true : false
       
      });
    };

    if (user.jwt) loadData();
  }, [getEventByID, getUsersList,getListPractices, id, user.jwt]);

  const [eventData, setEventData] = useState(null);
  const [modalAlert, setModalAlert] = useState({
    open: false,
    success: true,
    title: "",
    message: ""
  });
  const [addNewAttendeeBlock, setAddNewAttendeeBlock] = useState(false);

  const loadAttendees = async () => {
    const event = await getEventByID(id, user.jwt);

    setEventData({
      ...eventData,
      attendees: event.data.data.event.attendees
    });
  };

  const sendResults = async eventParams => {
    try {
      if (isValidForm()) {
        // let filesIDs = [];

        // if (eventData.invoice.receipts.length > 0) {
        //   sendFiles          filesIDs = await sendFiles();
        // }

        if (eventData.invoice) {
          const filesIDs = await sendFiles();
          if (eventData.isInvoiceExist) {
            // editInvoice
            if (eventData.invoice.lunchCost) {
              const data = {
                id: parseFloat(eventData.invoice.id),
                lunchCost: parseFloat(eventData.invoice.lunchCost),
                educatorFee: parseFloat(eventData.invoice.lunchCost),
                totalCost: parseFloat(eventData.invoice.lunchCost),
                receipts:filesIDs
              };
            
              await editInvoice(data, user.jwt);

            
            }
          } else {
            // if invoice don't exist then create a new one
            if (eventData.invoice.lunchCost) {
              const data = {
                lunchCost: parseFloat(eventData.invoice.lunchCost),
                educatorFee: parseFloat(eventData.invoice.lunchCost),
                totalCost: parseFloat(eventData.invoice.lunchCost),
                receipts:filesIDs,
                event: eventData.id
              };
              const emaildata = {
             
                lunchCost: parseFloat(eventData.invoice.lunchCost),
                educatorFee: parseFloat(eventData.invoice.lunchCost),
                totalCost: parseFloat(eventData.invoice.lunchCost),
                receipts:filesIDs,
                event: eventData
              };
              await createInvoice(data, user.jwt);
              SendEmail(emaildata);
            }
          }
        }

        const data = {
          id: eventData.id,
          eventName: eventData.eventName,
          expectedAttendees: Number(eventData.expectedAttendees),
          eventStart: eventData.eventStart,
          contactPerson: eventData.contactPerson,
          additionalInfo: eventData.additionalInfo,
          eventDuration: eventData.eventDuration,
          attendees: eventData.attendees.map(attendee => {
            return Number(attendee.id);
          }),
          practice: eventData.practice
        };

        const practiceData = {
          id: eventData.practice,
          email: eventData.email,
          contactPhone: eventData.contactPhone,
          contactPerson: eventData.contactPerson,
          practiceName: eventData.practiceName,
          address1: eventData.address1,
          address2: eventData.address2,
          practiceType: eventData.practiceType,
          state: eventData.state,
          city: eventData.city,
          zip: eventData.zip,
          author: eventData.author
        };
        
        await updatePractice(practiceData, user.jwt, user.user.id);
        await editEvent(data, user.jwt, user.user.id);
        setModalAlert({
          open: true,
          success: true,
          title: "SUCCESS!",
          message: "The event has been edited."
        });
      }
    } catch (error) {
      setModalAlert({
        open: true,
        success: false,
        title: "ERROR!",
        message: error
      });
    }
  };
  const DeleteEvent = async eventParams => {
    try {

      
      const data = {
        id: eventData.id,
        status:"cancelled",
        eventName: eventData.eventName
      };

      await editEvent(data, user.jwt, user.user.id);
     //await deleteEventByID(eventData.id, user.jwt);
     history.push("/listevents");
    //  setModalAlert({
    //   open: true,
    //   success: true,
    //   title: "SUCCESS!",
    //   message: "The event has been deleted"
    // });
    
    }
    catch (error) {
      setModalAlert({
        open: true,
        success: false,
        title: "ERROR!",
        message: error
      });
    }
  };
  const SendEmail=(emaildata)=>{

    let author=emaildata.event.author!==undefined && emaildata.event.author!==null  ?emaildata.event.author.username:user.user.username;
    let practiceName=emaildata.event.practiceName!==null?emaildata.event.practiceName:"";
  

    const managers = users.users.filter(user => user.role.type === 'manager');
  if(managers.length>0)
  {
    axios.post(
      `${server}/email`,
      {
        to:managers[0].email,
        subject:emaildata.event.eventName +' Event Cost has added ',
        html:'Dear Manager, <br/><br/>  A new event cost has been added to the EdNet Interface. The details are listed below: <br/>  <br/>Educator: '+author+'<br/>  Practice Name :'+practiceName+'  <br/> Event Name :'+emaildata.event.eventName+'  <br/> Event Date:'+emaildata.event.eventStart+'  <br/>  Event Cost : $'+emaildata.totalCost+'    <br/> Thanks'
       
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      }
    );
  }
  }
  const [showAttendeeError, setShowAttendeeError] = useState(false);

  const addNewAttendee = () => {
    setAddNewAttendeeBlock(true);
  };

  const isValidAttendeeForm = data => {
    const { email, firstName, lastName } = data;
    if (email && firstName && lastName) return true;
    return false;
  };

  const newAttend = data => {
    const { email, firstName, lastName } = data;

    if (isValidAttendeeForm(data)) {
      const newAttendee = {
        email: email,
        firstName: firstName,
        lastName: lastName,
        event: Number(id)
      };
   
      addAttendee(newAttendee, user.jwt,user.user.id)
        .then(() => {
          loadAttendees(id, user.jwt);
          setModalAlert({
            open: true,
            success: false,
            title: "SUCCESS!",
            message: "Attendee added"
          });
          //alert("Attendee added");
          setAddNewAttendeeBlock(false);
        })
        .catch(err => alert(err));
    } else {
      setModalAlert({
        open: true,
        success: false,
        title: "WARNING!",
        message: "Not all the fields are field in"
      });
      //alert("Not all the fields are field in");
      setShowAttendeeError(true);
    }
  };

  const editAttend = data => {
    const { email, firstName, lastName } = data;

    if (isValidAttendeeForm(data)) {
      const editedAttendee = {
        id: data.id,
        email: email,
        firstName: firstName,
        lastName: lastName
      };

      editAttendee(editedAttendee, user.jwt)
        .then(() =>
        setModalAlert({
          open: true,
          success: false,
          title: "SUCCESS!",
          message:"Attendee edited"
        })
        //alert("Attendee edited")
        )
        .catch(err =>

          setModalAlert({
            open: true,
            success: false,
            title: "ERROR!",
            message:err
          })
           //alert(err)
           
           );
    } else {
      setModalAlert({
        open: true,
        success: false,
        title: "WARNING!",
        message:"Not all the fields are field in"
      })
      //alert("Not all the fields are field in");
    }
  };

  const unAssingAttend = async attendID => {
    const unAssignResult = await unAssignAttendee(attendID, id, user.jwt);

    if (unAssignResult) {
      loadAttendees(id, user.jwt);
      setModalAlert({
        open: true,
        success: false,
        title: "SUCCESS!",
        message:"Attendee unassigned"
      })
      //alert("Attendee unassigned");
    } else {
      setModalAlert({
        open: true,
        success: false,
        title: "ERROR!",
        message:"Error"
      })
      //alert("Error");
    }
  };
  const sendFiles = () => {
    return new Promise((resolve, reject) => {
      const files = filesList;
      let filesToUpload = new FormData();
      let filesOnServer = [];

      files.forEach(file => {
        // check if file is not exist on server
        if (!file.id) {
          filesToUpload.append("files", file);
        } else {
          filesOnServer.push(Number(file.id));
        }
      });

      // if exist any file to upload
      if (filesToUpload.get("files")) {
        uploadFile(filesToUpload, user.jwt)
          .then(data => {
            let uploadedFiles = [];
            data.data.forEach(file => {
              uploadedFiles.push(file.id);
            });

            resolve([...filesOnServer, ...uploadedFiles]);
          })
          .catch(err => {
            reject(err);
          });
      } else {
        resolve(filesOnServer);
      }
    });
  };

  const removeFileFromList = name => {
    const newFileList = filesList.filter(file => {
      if (file.name === name) return false;
      return true;
    });

    setFilesList(newFileList);
  };

  const getFileIcon = file => {
  
    const mimeType = file.mime || file.type;

    const format = mimeType.split("/")[0];

    switch (format) {
      case "image":
        return <Image />;

      default:
        return <Description />;
    }
  
  };
  const isValidForm = () => {
    if (
      eventData.practiceName &&
      eventData.expectedAttendees &&
      eventData.eventStart &&
      eventData.contactPerson &&
      eventData.contactPhone &&
      eventData.email &&
      eventData.eventDuration &&
      eventData.eventName &&
      eventData.address1 &&
      eventData.practiceType &&
      eventData.state &&
      eventData.city &&
      eventData.zip
    ) {
      setShowEmptyFields(false);
      return true;
    } else {
      //alert("Not all the fields are field in");
      setShowEmptyFields(true);
      return false;
    }
  };


  return (
    <React.Fragment>
           {modalAlert.open && (
        <ModalAlert
          close={() => {
            setModalAlert({
              ...modalAlert,
              open: false
            });

           history.push("/listevents");
          }}
          success={modalAlert.success}
          title={modalAlert.title}
          message={modalAlert.message}
          open={modalAlert.open}
        />
      )}
      {eventData && (
        <Grid item xs={12} className="AddEvent">
          <Typography variant="h5">{eventData.eventName}</Typography>
          <Grid container>
            <Typography variant="h6">Practice Information</Typography>
            <Grid container alignItems="flex-end">
              <Grid item xs={12} md={6} className="input-item">
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="select-practice-label">
                    Select Practice
                  </InputLabel>
                  <Select
                    labelId="select-practice-label"
                    id="select-practice"
                    fullWidth
                    value={eventData.practice}
                    onChange={e => {
                      const selectedPractice = practices.practices.find(
                        practice => {
                          if (practice.id === e.target.value) return true;
                          return false;
                        }
                      );

                      setEventData({
                        ...eventData,
                        practice: selectedPractice.id || "",
                        practiceName: selectedPractice.practiceName || "",
                        address1: selectedPractice.address1 || "",
                        address2: selectedPractice.address2 || "",
                        city: selectedPractice.city || "",
                        state: selectedPractice.state || "",
                        zip: selectedPractice.zip || "",
                        email: selectedPractice.email || "",
                        contactPhone: selectedPractice.contactPhone || "",
                        practiceType: selectedPractice.practiceType || "",
                        contactPerson: selectedPractice.contactPerson || "",
                        status:selectedPractice.status||"upcoming"
                      });
                    }}
                  >
                    {!practices.pending &&
                      !practices.error &&
                      practices.practices.map((practice, index) => (
                        <MenuItem key={index} value={practice.id}>
                          {practice.practiceName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} className="input-item">
                <TextField
                  id="event-name"
                  fullWidth
                  variant="outlined"
                  label="Event name"
                  value={eventData.eventName}
                  error={!eventData.eventName && showEmptyFields}
                  onChange={e =>
                    setEventData({
                      ...eventData,
                      eventName: e.target.value
                    })
                  }
                />
              </Grid>
            </Grid>
            <Grid container alignItems="flex-end">
              <Grid item xs={6} md={3} className="input-item">
                <TextField
                  id="address1"
                  fullWidth
                  variant="outlined"
                  label="Address 1"
                  value={eventData.address1}
                  error={!eventData.address1 && showEmptyFields}
                  onChange={e =>
                    setEventData({
                      ...eventData,
                      address1: e.target.value
                    })
                  }
                />
              </Grid>
              <Grid item xs={6} md={3} className="input-item">
                <TextField
                  id="address2"
                  fullWidth
                  variant="outlined"
                  label="Address 2"
                  value={eventData.address2}
                  onChange={e =>
                    setEventData({
                      ...eventData,
                      address2: e.target.value
                    })
                  }
                />
              </Grid>
              <Grid item xs={6} md={2} className="input-item">
                <TextField
                  id="city"
                  fullWidth
                  variant="outlined"
                  label="City"
                  value={eventData.city}
                  error={!eventData.city && showEmptyFields}
                  onChange={e =>
                    setEventData({
                      ...eventData,
                      city: e.target.value
                    })
                  }
                />
              </Grid>
              <Grid item xs={6} md={2} className="input-item">
                <TextField
                  id="state"
                  fullWidth
                  variant="outlined"
                  label="State"
                  value={eventData.state}
                  error={!eventData.state && showEmptyFields}
                  onChange={e =>
                    setEventData({
                      ...eventData,
                      state: e.target.value
                    })
                  }
                />
              </Grid>
              <Grid item xs={6} md={2} className="input-item">
                <TextField
                  id="zip"
                  fullWidth
                  variant="outlined"
                  label="Zip code"
                  value={eventData.zip}
                  error={!eventData.zip && showEmptyFields}
                  onChange={e =>
                    setEventData({
                      ...eventData,
                      zip: e.target.value
                    })
                  }
                />
              </Grid>
            </Grid>
            <Grid container alignItems="flex-end">
              <Grid item xs={6} md={4} className="input-item">
                <TextField
                  id="email"
                  fullWidth
                  variant="outlined"
                  label="E-Mail"
                  value={eventData.email}
                  error={!eventData.email && showEmptyFields}
                  onChange={e =>
                    setEventData({
                      ...eventData,
                      email: e.target.value
                    })
                  }
                />
              </Grid>
              <Grid item xs={6} md={4} className="input-item">
                <TextField
                  fullWidth
                  variant="outlined"
                  id="phone-number"
                  label="Contact phone"
                  value={eventData.contactPhone}
                  error={!eventData.contactPhone && showEmptyFields}
                  onChange={e =>
                    setEventData({ ...eventData, contactPhone: e.target.value })
                  }
                  placeholder="+1 (___)__-__-___-__"
                />
              </Grid>
              <Grid item xs={6} md={4} className="input-item">
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="practice-type-label">
                    Practice Type
                  </InputLabel>
                  <Select
                    labelId="practice-type-label"
                    id="practice-type"
                    fullWidth
                    value={eventData.practiceType}
                    error={!eventData.practiceType && showEmptyFields}
                    onChange={e =>
                      setEventData({
                        ...eventData,
                        practiceType: e.target.value
                      })
                    }
                  >
                  <MenuItem value={"Endodontist"}>Endodontist</MenuItem>
                  <MenuItem value={"GeneralDentistry"}>General Dentistry</MenuItem>                   
                  <MenuItem value={"OralSurgeonPathologist"}>Oral Surgeon Pathologist</MenuItem>
                  <MenuItem value={"Orthodontist"}>Orthodontist</MenuItem>                   
                  <MenuItem value={"PediatricDentistry"}>Pediatric Dentistry</MenuItem>
                  <MenuItem value={"Prosthodontist"}>Prosthodontist</MenuItem>                   
                  <MenuItem value={"DDSDMDStudent"}>DDS/DMD Student</MenuItem>
                  <MenuItem value={"RDHStudent"}>RDH Student</MenuItem> 
                  <MenuItem value={"Other"}>Other</MenuItem> 
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Typography variant="h6">Contact Person</Typography>
            <Grid container alignItems="flex-end">
              <Grid item xs={12} md={6} className="input-item">
                <TextField
                  id="full-name"
                  fullWidth
                  variant="outlined"
                  label="Full Name"
                  value={eventData.contactPerson}
                  error={!eventData.contactPerson && showEmptyFields}
                  onChange={e =>
                    setEventData({
                      ...eventData,
                      contactPerson: e.target.value
                    })
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Typography variant="h6">Event Information</Typography>
            <Grid container>
              <Grid item xs={12} md={4} className="input-item">
                <TextField
                  id="expectedAttendees"
                  fullWidth
                  variant="outlined"
                  label="Number of expected Attendees"
                  value={eventData.expectedAttendees}
                  error={!eventData.expectedAttendees && showEmptyFields}
                  onChange={e =>
                    setEventData({
                      ...eventData,
                      expectedAttendees: e.target.value
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} md={4} className="input-item">
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="event-duraton-label">
                    Duration of Event
                  </InputLabel>
                  <Select
                    labelId="event-duraton-label"
                    id="event-duraton"
                    fullWidth
                    value={eventData.eventDuration}
                    error={!eventData.eventDuration && showEmptyFields}
                    onChange={e =>
                      setEventData({
                        ...eventData,
                        eventDuration: e.target.value
                      })
                    }
                  >
                    <MenuItem value={"h05"}>0.5 hours</MenuItem>
                    <MenuItem value={"h1"}>1 hour</MenuItem>
                    <MenuItem value={"h2"}>2 hours</MenuItem>
                    <MenuItem value={"h3"}>3 hours</MenuItem>
                    <MenuItem value={"h4"}>4 hours</MenuItem>
                    <MenuItem value={"h5"}>5 hours</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4} className="input-item">
                <DatePicker
                  selected={eventData.eventStart}
                  onChange={e => {
                    const selectedDate = e;
                    setEventData({
                      ...eventData,
                      eventStart: selectedDate
                    });
                  }}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  timeCaption="time"
                  dateFormat="MMMM d, yyyy h:mm aa"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container alignItems="flex-end">
            <Typography variant="h6">Additional Information</Typography>
            <Grid container>
              <Grid item xs={12} className="input-item">
                <TextField
                  id="additional-information"
                  multiline
                  fullWidth
                  variant="outlined"
                  rows="4"
                  value={eventData.additionalInfo}
                  onChange={e =>
                    setEventData({
                      ...eventData,
                      additionalInfo: e.target.value
                    })
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          
          {eventData.attendees && (
            <Grid container alignItems="flex-end">
              <Typography variant="h6">Attendees</Typography>
              <Grid container className="Attendees__titles">
                <Grid item xs={12} md={3} className="input-item">
                  <Typography>First Name</Typography>
                </Grid>
                <Grid item xs={12} md={3} className="input-item">
                  <Typography>Last Name</Typography>
                </Grid>
                <Grid item xs={12} md={3} className="input-item">
                  <Typography>E-mail</Typography>
                </Grid>
                <Grid item xs={12} md={2} className="input-item">
                  <Typography>Survey</Typography>
                </Grid>
                <Grid item xs={12} md={1} className="input-item">
                  <Typography>Action</Typography>
                </Grid>
              </Grid>
              {eventData.attendees.map(attendee => {
                return (
                  <Attendee
                    attendee={attendee}
                    newAttend={newAttend}
                    editAttend={editAttend}
                    findAttendee={true}
                    unAssingAttend={unAssingAttend}
                    key={attendee.id}
                    userToken={user.jwt}
                    error={showAttendeeError}
                  />
                );
              })}
              {addNewAttendeeBlock && (
                <Attendee
                  isNew
                  attendee={{
                    id: Math.floor(Math.random() * 10000),
                    firstName: "",
                    lastName: "",
                    email: "",
                    surveys: ""
                  }}
                  newAttend={newAttend}
                  findAttendee={true}
                  userToken={user.jwt}
                  error={showAttendeeError}
                />
              )}
              <Button
                variant="contained"
                color="primary"
                className="btn"
                onClick={addNewAttendee}
              >
                <AddCircle /> Add Attendee
              </Button>
            </Grid>
          )}
         
          <Grid container  >
            <Typography variant="h6">Event cost</Typography>
            <Grid container alignItems="flex-end">
              <Grid item xs={12} md={6} className="input-item">
                <TextField
                  id="finance"
                  fullWidth
                  variant="outlined"
                  label="Total cost of Event"
                  value={eventData.invoice ? eventData.invoice.lunchCost : 0}
                  onChange={e =>
                    setEventData({
                      ...eventData,
                      invoice: {
                        ...eventData.invoice,
                        lunchCost: e.target.value
                      }
                    })
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container className="message">
          <Paper className="message__paper">
      <Grid item xs={12} className="upload-container">
        {!filesList.length > 0 ? (
          <React.Fragment>
            <div className="message__paper_icon">
              <Info />
            </div>
            <Typography align="center">
              No receipts have been added for this event. Click the button below
              to add.
            </Typography>
          </React.Fragment>
        ) : null}
        <Grid container  item    className="FinanceModal__container">
          {[...filesList].map(file => (
            <Grid
              item
              xs={4}
              key={file.name}
              className="FinanceModal__container_item"
            >
              <Grid container className="FinanceModal__file">
                <Grid item xs={12}>
                  <div className="FinanceModal__file_container">
                    {getFileIcon(file)}
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" noWrap>
                    {file.name}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>{file.size}kb</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="outlined"
                    color="default"
                    onClick={() => removeFileFromList(file.name)}
                    fullWidth
                  >
                    Remove file
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
       
      </Grid>
      <Grid  item xs={12}   className="upload-container">


      <div className="upload-btn-wrapper">
          <Button
            variant="contained"
            color="primary" 
            className="upload-btn-wrapper__btn "
            fullWidth
          >
            add files
          </Button>
          <input     
            type="file"
            name="myfile"
            multiple
            fullWidth
            onChange={e => setFilesList([...filesList, ...e.target.files])}
          />
{/*        
        <Button
          variant="contained"
          color="primary" item
          className="upload-btn-wrapper__btn"
          onClick={sendFilesAction}
           
        >
          upload files
        </Button> */}
        </div>
      </Grid>
    </Paper>
            {/* <FilesBlock
              files={eventData.invoice ? eventData.invoice.receipts : []}
              token={user.jwt}
              getFileList={getFileList}
            /> */}
          </Grid>
          <Grid container justify="space-between">
            <Button
              variant="contained"
              color="secondary"
              className="btn"
              onClick={() => DeleteEvent()}
            >
              <Close />
              DELETE
            </Button>
            <Button
              variant="contained"
              color="primary"
              className="btn"
              onClick={() => sendResults({ status: null })}
            >
              SAVE
            </Button>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
};

const mapDispatchToProps = {
  getEventByID: getEventByID,
  getListPractices: getListPractices,
  updatePractice: updatePractice,
  editEvent: editEvent,
  addAttendee: addAttendee,
  editAttendee: editAttendee,
  unAssignAttendee: unAssignAttendee,
  createInvoice: createInvoice,
  editInvoice: editInvoice,
  deleteEventByID:deleteEventByID,
  getUsersList:getUsersList
};

const mapStateToProps = state => {
  const { events, practices, user ,users} = state;
  return { events, practices, user,users };
};

const connectedEditEvent = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditEvent);
export { connectedEditEvent as EditEvent };
