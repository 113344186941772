import React from "react";
import { Calendar } from "../../../components/Calendar";
import { Grid, Typography } from "@material-ui/core";

const EventsCalendar = () => {
  return (
    <React.Fragment>
      <Grid item xs={12} className="EventsMapPage">
        <Typography variant="h5" style={{ marginBottom: 20 }}>
          Calendar
        </Typography>
        <Calendar />
        {/* <Grid container>
          <Grid item xs={12} md={4} className="btns-list-horizontal">
            <Link to="/addevent" className="btn-link">
              <Button variant="contained" color="primary">
                Add Event
              </Button>
            </Link>
            <Link to="/listevents" className="btn-link">
              <Button variant="contained" color="primary">
                View events
              </Button>
            </Link>
          </Grid>
        </Grid> */}
      </Grid>
    </React.Fragment>
  );
};

export { EventsCalendar };
