import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Typography } from "@material-ui/core";

const errors = {
  NOT_FOUND: "No upcoming event found"
};

const UpcomingEventBlock = ({ events }) => {
  let nearestEvent;
  let error;
  if(events!==null && events.events!==null )
  {
  if (events.events.length > 0) {
    const now = moment();
    let minimalDiff = -Infinity;

    events.events.forEach(event => {
      const eventData = moment(event.eventStart);
      const differentBeetwenNow = now.diff(eventData);

      if (differentBeetwenNow < 0) {
        if (differentBeetwenNow > minimalDiff) {
          minimalDiff = differentBeetwenNow;
          nearestEvent = event;
        }
      }
    });

    if (!nearestEvent) {
      error = errors.NOT_FOUND;
    }
  }
  }
  return (
    <div className="upcomingLNL Dashboard__board">
      <Typography variant="h5">Your Next Event</Typography>
      {nearestEvent && !error ? (
        <React.Fragment>
          <Typography variant="h6"><span className="underline">Event Name</span></Typography>
          <Typography variant="body1">{nearestEvent.eventName}</Typography>
          <Typography variant="h6"><span className="underline">Practice</span></Typography>
          <Typography variant="body1">
            {nearestEvent.practice.practiceName}
          </Typography>
          <Typography variant="h6"><span className="underline">Address</span></Typography>
          <Typography variant="body1">
            {`${nearestEvent.practice
              .address1 ||
              nearestEvent.practice.address2 ||
              ""}`}
            <br />
            {`${nearestEvent.practice.city || ""}${
              nearestEvent.practice.state
                ? `, ${nearestEvent.practice.state}`
                : ""
            } ${nearestEvent.practice.zip || ""}`}
          </Typography>

          <Typography variant="h6"><span className="underline">Date &  Time</span></Typography>
          <Typography variant="body1">{moment(nearestEvent.eventStart).format("MM.DD.YYYY hh:mm A") }</Typography>
        </React.Fragment>
      ) : (
        <Typography variant="body1">{error}</Typography>
      )}
    </div>
  );
};

const mapStateToProps = state => {
  const { events } = state;
  return { events };
};

const connectedUpcomingEventBlock = connect(
  mapStateToProps,
  null
)(UpcomingEventBlock);
export { connectedUpcomingEventBlock as UpcomingEventBlock };
