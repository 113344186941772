import { resourcesConstants } from "../_constants";

const initialState = {
  resources: [],
  pending: false,
  error: ""
};

export const resources = (state = initialState, action) => {
  switch (action.type) {
    case resourcesConstants.GET_RESOURCES_BY_CATEGORY_REQUEST:
      return {
        ...state,
        pending: true,
        error: ""
      };

    case resourcesConstants.GET_RESOURCES_BY_CATEGORY_SUCCESS:
      return {
        ...state,
        resources: action.payload,
        pending: false,
        error: ""
      };

    case resourcesConstants.GET_RESOURCES_BY_CATEGORY_ERROR:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    default:
      return {
        ...state
      };
  }
};
