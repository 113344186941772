import axios from "axios";
import { server } from "../_constants";

export const uploadFile = (formData, token) => {
  return axios.post(`${server}/upload`, formData, {
    headers: {
      Authorization: "bearer " + token,
      "Content-Type": "multipart/form-data"
    }
  });
};
export const EmailsList = () => {
  return  axios.get(`https://ednet.info/json-file.json`,  {
    headers: {
      "Content-Type": "application/json"
    }
  });
};