import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import moment from "moment";
// import { useHistory } from "react-router-dom";
import {
  Grid,
  Typography,
  Button,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Paper
} from "@material-ui/core";
import { AddCircle } from "@material-ui/icons";
import { Attendee } from "../../../components/Attendee";
import { getEventByIDAPI } from "../../../_api/events";

import {
  getEventsList,
  getEventByID,
  editEvent
} from "../../../_actions/events.actions";
import {
  getListAttendees,
  addAttendee,
  editAttendee,
  unAssignAttendee
} from "../../../_actions/attendees.actions";
import { ModalAlert } from "../../../components/Modal";
import { validateEmail } from "../../../_helpers/email";

const Attendees = ({

  getEventsList,
  getEventByID,
  getListAttendees,
  addAttendee,
  editAttendee,
  unAssignAttendee,
  user,
  events,
  attendees
}) => {
  const { eventid } = useParams();
  const [selectedEvent, setSelectedEvent] = useState({ id: "", eventName: "" });
  const [showAttendeeError, setShowAttendeeError] = useState(false);
  const [modalAlert, setModalAlert] = useState({
    open: false,
    success: true,
    title: "",
    message: ""
  });

  useEffect(() => {
    const loadEventsList = () => {
      getEventsList(user.jwt);
    };

    if (user.jwt) loadEventsList();
  }, [getEventsList, user.jwt]);

  const loadAttendees = async eventID => {
    const event = await getEventByIDAPI(eventID, user.jwt);
    setSelectedEvent(event.data.data.event);
  };

  const [attendsList, setAttendsList] = useState([]);

  const [addNewAttendeeBlock, setAddNewAttendeeBlock] = useState(false);

  
  const addNewAttendee = () => {
    if (!selectedEvent.id) {
      setModalAlert({
        open: true,
        success: true,
        title: "WARNING!",
        message:"Please, select the event!"
      });

    } else {
      setAddNewAttendeeBlock(true);
      const id = Math.floor(Math.random() * 10000);

      setAttendsList([
        ...attendsList,
        {
          id: id,
          firstName: "",
          lastName: "",
          email: "",
          surveys:""
        }
      ]);
    }
  };

  const isValidForm = data => {
    const { email, firstName, lastName } = data;
    const isEmailValid = validateEmail(email);

    if (isEmailValid && firstName && lastName) return true;
    if (!isEmailValid)
    setModalAlert({
      open: true,
      success: true,
      title: "WARNING!",
      message:"Email is not valid"
    });

    return false;
  };

  const newAttend = data => {
    const { email, firstName, lastName } = data;

    if (isValidForm(data)) {
      const newAttendee = {
        email: email,
        firstName: firstName,
        lastName: lastName,
        event: selectedEvent.id
      };

      addAttendee(newAttendee, user.jwt,user.user.id)
        .then(() => {

          loadAttendees(selectedEvent.id, user.jwt);
         
          setModalAlert({
            open: true,
            success: true,
            title: "SUCCESS!",
            message:"Attendee added"
          });
   
          setAddNewAttendeeBlock(false);
        })
        .catch(err =>

          setModalAlert({
            open: true,
            success: true,
            title: "ERROR!",
            message:err
          })

           
           );
    } else {
      setModalAlert({
        open: true,
        success: true,
        title: "WARNING!",
        message:"Not all the fields are field in"
      })
  
      setShowAttendeeError(true);
    }
  };

  const editAttend = data => {
 
    const { email, firstName, lastName } = data;

    if (isValidForm(data)) {
      const editedAttendee = {
        id: data.id,
        email: email,
        firstName: firstName,
        lastName: lastName
      };

      editAttendee(editedAttendee, user.jwt)
        .then(() => {
          loadAttendees(selectedEvent.id, user.jwt);
          setModalAlert({
            open: true,
            success: true,
            title: "SUCCESS!",
            message:"Attendee edited"
          })
    
        })
        .catch(err =>
          setModalAlert({
            open: true,
            success: true,
            title: "ERROR!",
            message:err
          })

          );
    } else {
      setModalAlert({
        open: true,
        success: true,
        title: "WARNING!",
        message:"Not all the fields are field in"
      })

    }
  };

  const unAssingAttend = async attendID => {
    const unAssignResult = await unAssignAttendee(
      attendID,
      selectedEvent.id,
      user.jwt
    );

    if (unAssignResult) {
      loadAttendees(selectedEvent.id, user.jwt);
      setModalAlert({
        open: true,
        success: true,
        title: "SUCCESS!",
        message:"Attendee Removed"
      })
   
    } else {
      setModalAlert({
        open: true,
        success: true,
        title: "ERROR!",
        message:"Error"
      })

    }
  };

  return (
    <React.Fragment>
         {modalAlert.open && (
        <ModalAlert
          close={() => {
            setModalAlert({
              ...modalAlert,
              open: false
            });

           
          }}
          success={modalAlert.success}
          title={modalAlert.title}
          message={modalAlert.message}
          open={modalAlert.open}
        />
      )}
      {!events.pending && !events.error && events.events.length > 0 && (
        <Grid item xs={12} className="Attendees">
          <Grid container justify="space-between">
            <Grid item xs={12} md={6}>
              <Typography variant="h4">Attendees</Typography>
              <Typography>
                {selectedEvent.eventName} <br />
                {selectedEvent.eventStart &&
                  moment(selectedEvent.eventStart).format("MM.DD.YYYY hh:mm A")}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="select-practice-label">Select Event</InputLabel>
                <Select
                  labelId="select-practice-label"
                  id="select-practice"
                  fullWidth
                  value={
                    selectedEvent.id ||
                    (eventid && !selectedEvent.id ? loadAttendees(eventid) : "")
                  }
                  onChange={e => loadAttendees(e.target.value)}
                >
                  {events.events.map(event => (
                    <MenuItem value={event.id} key={event.id}>
                      {event.eventName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {selectedEvent.attendees && (
                <Grid container className="Attendees__count-block">
                  <Grid
                    item
                    xs={12}
                    md={6}
                    className="Attendees__count-block_wrapper"
                  >
                    <Paper className="Attendees__count-block_wrapper_item">
                      <Typography>
                        Anticipated Attendees: {selectedEvent.expectedAttendees}
                      </Typography>
                    </Paper>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    className="Attendees__count-block_wrapper"
                  >
                    <Paper className="Attendees__count-block_wrapper_item">
                      <Typography>
                        Total Attendees: {selectedEvent.attendees.length}
                      </Typography>
                    </Paper>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid container>
            <Grid container className="Attendees__titles">
              <Grid item xs={12} md={3} className="input-item">
                <Typography>First Name</Typography>
              </Grid>
              <Grid item xs={12} md={3} className="input-item">
                <Typography>Last Name</Typography>
              </Grid>
              <Grid item xs={12} md={3} className="input-item">
                <Typography>E-mail</Typography>
              </Grid>
              <Grid item xs={12} md={2} className="input-item">
                <Typography>Survey</Typography>
              </Grid>
              <Grid item xs={12} md={1} className="input-item">
                <Typography>Action</Typography>
              </Grid>
            </Grid>
            {selectedEvent.attendees &&
              selectedEvent.attendees.map(attendee => {
                return (
                  <Attendee
                    attendee={attendee}
                    newAttend={newAttend}
                    editAttend={editAttend}
                    findAttendee={true}
                    unAssingAttend={unAssingAttend}
                    error={showAttendeeError}
                    key={attendee.id}
                  />
                );
              })}
            {addNewAttendeeBlock && (
              <Attendee
                isNew
                attendee={{
                  id: Math.floor(Math.random() * 10000),
                  firstName: "",
                  lastName: "",
                  email: "",
                  surveys: ""
                }}
                newAttend={newAttend}
                findAttendee={true}
                userToken={user.jwt}
                error={showAttendeeError}
              />
            )}
            <Button
              variant="contained"
              color="primary"
              className="btn"
              onClick={addNewAttendee}
            >
              <AddCircle /> Add Attendee
            </Button>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
};

const mapDispatchToProps = {
  getEventsList: getEventsList,
  editEvent: editEvent,
  getEventByID: getEventByID,
  getListAttendees: getListAttendees,
  addAttendee: addAttendee,
  editAttendee: editAttendee,
  unAssignAttendee: unAssignAttendee
};

const mapStateToProps = state => {
  const { events, user, attendees } = state;
  return { events, user, attendees };
};

const connectedAttendees = connect(
  mapStateToProps,
  mapDispatchToProps
)(Attendees);
export { connectedAttendees as Attendees };
