import { ListEvents } from "../../containers/Events/ListEvents";
import { AddEvent } from "../../containers/Events/AddEvent";
import { EditEvent } from "../../containers/Events/EditEvent";
import { ViewEvent } from "../../containers/Events/ViewEvent";
import { Attendees } from "../../containers/Events/Attendees";
import { EventsMapPage } from "../../containers/Events/EventsMapPage";
import { EventsCalendar } from "../../containers/Events/EventsCalendar";
import { ListPractice } from "../../containers/Practices/ListPractice";
import { AddPractice } from "../../containers/Practices/AddPractice";
import { ViewPractice } from "../../containers/Practices/ViewPractice";
import { EditPractice } from "../../containers/Practices/EditPractice";
import { Lookup } from "../../containers/Practices/Lookup";
import { ResourcesList } from "../../containers/Resources/ResourcesList";
import { Survey } from '../../containers/survey';
import { ChangePassword } from '../../containers/ChangePassword';
import { ThankYou } from '../../containers/ThankYou';
import { userType } from "../../_constants";

const pagesEducator = [
  {
    name: "ListEvents",
    to: "/listevents",
    private: true,
    roles: [userType.EDUCATOR, userType.MANAGER],
    component: ListEvents
  },
  {
    name: "AddEvent",
    to: "/addevent",
    private: true,
    roles: [userType.EDUCATOR, userType.MANAGER],
    component: AddEvent
  },
  {
    name: "AddEventWithSelectedPractice",
    to: "/addevent/:practice",
    private: true,
    roles: [userType.EDUCATOR, userType.MANAGER],
    component: AddEvent
  },
  {
    name: "EditEvent",
    to: "/editevent/:id",
    private: true,
    roles: [userType.EDUCATOR, userType.MANAGER],
    component: EditEvent
  },
  {
    name: "ViewEvent",
    to: "/event/:id",
    private: true,
    roles: [userType.EDUCATOR, userType.MANAGER],
    component: ViewEvent
  },
  {
    name: "Attendees",
    to: "/attendees",
    private: true,
    roles: [userType.EDUCATOR, userType.MANAGER],
    component: Attendees
  },
  {
    name: "Attendees",
    to: "/attendees/:eventid",
    private: true,
    roles: [userType.EDUCATOR, userType.MANAGER],
    component: Attendees
  },
  {
    name: "EventsMapPage",
    to: "/map",
    private: true,
    roles: [userType.EDUCATOR, userType.MANAGER],
    component: EventsMapPage
  },
  {
    name: "EventsCalendar",
    to: "/calendar",
    private: true,
    roles: [userType.EDUCATOR, userType.MANAGER],
    component: EventsCalendar
  },
  {
    name: "ListPractice",
    to: "/listpractices",
    private: true,
    roles: [userType.EDUCATOR, userType.MANAGER],
    component: ListPractice
  },
  {
    name: "AddPractice",
    to: "/addpractice",
    private: true,
    roles: [userType.EDUCATOR, userType.MANAGER],
    component: AddPractice
  },
  {
    name: "ViewPractice",
    to: "/practice/:id",
    private: true,
    roles: [userType.EDUCATOR, userType.MANAGER],
    component: ViewPractice
  },
  {
    name: "EditPractice",
    to: "/editpractice/:id",
    private: true,
    roles: [userType.EDUCATOR, userType.MANAGER],
    component: EditPractice
  },
  {
    name: "Lookup",
    to: "/lookup",
    private: true,
    roles: [userType.EDUCATOR, userType.MANAGER],
    component: Lookup
  },
  {
    name: "ResourcesList",
    to: "/resourceslist",
    private: true,
    roles: [userType.EDUCATOR, userType.MANAGER],
    component: ResourcesList
  },
  {
    name: "Survey",
    to: "/survey",
    private: false,
    roles: [userType.EDUCATOR, userType.MANAGER],
    component: Survey
  },
  {
    name: "ThankYou",
    to: "/Thankyou",
    private: false,
    roles: [userType.EDUCATOR, userType.MANAGER],
    component: ThankYou
  },
  {
    name: "ChangePassword",
    to: "/changepassword",
    private: true,
    roles: [userType.EDUCATOR, userType.MANAGER],
    component: ChangePassword
  }
];

export { pagesEducator };
