import { Home } from "../../containers/Home";
import { Dashboard } from "../../containers/Dashboard";
import { ForgotPassword } from "../../containers/ForgotPassword";
import { ResetPassword } from "../../containers/ForgotPassword/ResetPassword";

import { userType } from "../../_constants";

const servicePages = [
  {
    name: "Home",
    to: "/",
    private: false,
    component: Home
  },
  {
    name: "Forgot Password",
    to: "/forgotpassword",
    private: false,
    component: ForgotPassword
  },
  {
    name: "Reset Password",
    to: "/resetpassword",
    private: false,
    component: ResetPassword
  },
  {
    name: "Dashboard",
    to: "/dashboard",
    private: true,
    roles: [userType.EDUCATOR, userType.MANAGER],
    component: Dashboard
  },
  {
    name: "No match",
    to: "*",
    private: false,
    component: Home
  }
];

export { servicePages };
