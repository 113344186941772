import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Grid, Typography, TextField, Button, createMuiTheme } from "@material-ui/core";
import { forgotPasswordAPi } from "../../_api/password";
import { parseGraphQLError } from "../../_helpers/errors";
import { ModalAlert } from "../../components/Modal";
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';

const ForgotPassword = () => {
  const history = useHistory();

  const [resetEmail, setResetEmail] = useState("");
  const [showError, setShowError] = useState(false);
  const [modalAlert, setModalAlert] = useState({
    open: false,
    success: true,
    title: "",
    message: ""
  });
  const sendEmail = () => {
    if (resetEmail) {
      forgotPasswordAPi(resetEmail)
        .then(data => {
          if (data.data.ok) history.push("/resetpassword");
        })
        .catch(error => {
          let errorMessage = parseGraphQLError(error);
          setModalAlert({
            open: true,
            success: true,
            title: "ERROR!",
            message: errorMessage
          });
          // alert(errorMessage);
        });
    } else {
      setShowError(true);
      setModalAlert({
        open: true,
        success: true,
        title: "WARNING!",
        message: "Not all the fields are field in"
      });
      // alert("Not all the fields are field in");
    }
  };


  const formLabelsTheme = createMuiTheme({
    overrides: {
      MuiFormLabel: {
        asterisk: {
          color: "#db3131",
          "&$error": {
            color: "#db3131"
          }
        }
      }
    }
  });

  return (
    <React.Fragment>
      {modalAlert.open && (
        <ModalAlert
          close={() => {
            setModalAlert({
              ...modalAlert,
              open: false
            });
            history.push("/dashboard");
          }}
          success={modalAlert.success}
          title={modalAlert.title}
          message={modalAlert.message}
        />
      )}
      <Grid container direction="column">
        <MuiThemeProvider theme={formLabelsTheme}>
          <Typography variant="h5" className="input-field">
            Forgot Password
        </Typography>
          <Grid item xs={3} className="input-field">
            <TextField
              required
              id={`reset-email`}
              fullWidth
              variant="outlined"
              value={resetEmail}
              label="Enter email"
              error={showError && resetEmail.length === 0}
              onChange={e => {
                setResetEmail(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={3} className="input-field">
            <Button
              variant="contained"
              color="primary"
              className="input-field"
              fullWidth
              onClick={() => {
                sendEmail();
              }}
            >
              Submit
        </Button>
          </Grid>
        </MuiThemeProvider>

      </Grid>
    </React.Fragment>
  );
};

export { ForgotPassword };
