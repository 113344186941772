import React, { useState, useEffect } from "react";
import { Grid, Typography, Button, Modal, TextField,Paper } from "@material-ui/core";
import { Info, Description, Image } from "@material-ui/icons";
import MaterialTable from "material-table";
import { connect } from "react-redux";
import { getEventsList,editEvent } from "../../_actions/events.actions";
import{updateGeoLocation} from "../../_actions/practices.actions";
import { createInvoice } from "../../_actions/invoices.actions";
import { LibraryAdd, Create, Visibility,Clear } from "@material-ui/icons";
import { ModalAlert } from "../../components/Modal";
import { Link } from "react-router-dom";
import moment from "moment";
// import { FilesBlock } from "../../components/FilesBlock";
import { uploadFile } from "../../_api/files";
import { useHistory } from "react-router-dom";
import { server } from "../../_constants";
import { getUsersList } from "../../_actions/users.actions";
import axios from "axios";
const EventsTable = ({ getEventsList,users, getUsersList, createInvoice, editEvent, user, events,updateGeoLocation }) => {
  const [selectedEvent, setSelectedEvent] = useState(null);

  useEffect(() => {
    const loadEventsList = async () => {
      await getEventsList(user.jwt);
     await getUsersList(user.jwt);
    };

    if (user.jwt) loadEventsList();
  }, [getEventsList,getUsersList, user.jwt]);

 
  const [modalAlert, setModalAlert] = useState({
    open: false,
    success: true,
    title: "",
    message: ""
  });
   const history = useHistory();

  const createNewInvoice = data => {
    createInvoice(data, user.jwt)
      .then(() => {
        setModalAlert({
          open: true,
          success: true,
          title: "SUCCESS!",
          message: "Added a new invoice"
        });
        //alert("Added a new invoice");
  
        setSelectedEvent(null);
      })
      .catch(err =>
        setModalAlert({
          open: true,
          success: true,
          title: "ERROR!",
          message: err
        })
       
         
         
         );
  };
  const closemodal = data => {

    setSelectedEvent(null);
    window.location.reload();
  };
  const updateGeoLocationcall =  practiveparam => {
    
    try {

const data={};

data.id=practiveparam.practice.id;
data.practiceName=practiveparam.practice.practiceName;
data.practiceType=practiveparam.practice.practiceType;
data.contactPhone=practiveparam.practice.contactPhone;
data.address2=practiveparam.practice.address2;
data.address1=practiveparam.practice.address1;
data.state=practiveparam.practice.state;
data.city=practiveparam.practice.city;
data.zip=practiveparam.practice.zip;
data.SalesforceID=practiveparam.practice.SalesforceID?practiveparam.practice.SalesforceID:"";

      updateGeoLocation(data, user.jwt);
      setTimeout(() => {
        window.open('/#/event/'+practiveparam.id, '_blank');
      }, 1000);
 
   
 
   
    }
    catch (error) {

    }
  };
  // const sendApproved =  async eventParams => {
 
  //   try {
   
  //       const data = {
  //         id: eventParams.id,
  //         eventName: eventParams.eventName,
  //         approvedOn:new Date().toISOString(),
       
  //         status: "approved"
  //       };

 
  //       await editEvent(data, user.jwt, user.user.id);
  //       getEventsList(user.jwt);
  //       history.push("/listevents");
  //     }
  //    catch (error) {
 
  //   }
  // };
  // const sendProcessed =  async eventParams => {
 
  //   try {
     
  //       const data = {
  //         id: eventParams.id,
  //         eventName: eventParams.eventName,
  //         processedOn:new Date().toISOString(),
       
  //         status: "processed"
  //       };

 
  //       await editEvent(data, user.jwt, user.user.id);
  //       getEventsList(user.jwt);
       
    
  //     }
  //    catch (error) {
  //   console.log(error);
  //   }
  // };

  return (
    <React.Fragment>
      {modalAlert.open && (
        <ModalAlert
          close={() => {
            setModalAlert({
              ...modalAlert,
              open: false
            });

           history.push("/listevents");
          }}
          success={modalAlert.success}
          title={modalAlert.title}
          message={modalAlert.message}
          open={modalAlert.open}
        />
      )}
      <Grid item xs={12}>

        { selectedEvent!==null  && (
          <FinanceModal
            event={selectedEvent}
            createInvoice={createNewInvoice}
            close={closemodal}
            user={user}
            users={users}

          />
        )}
        {!events.pending && !events.error && events.events.length > 0 && (
   
          <MaterialTable
            title="Events"
            columns={[

              
              // {
              //   title: "Author Name",
              //   field: "author",
              //   filtering: false,
              //   cellStyle: {
              //     width: 100,
              //     maxWidth:100
              //   },
              // },
              {
                title: "Event Name",
                field: "eventName",
                filtering: false,
                cellStyle: {
                  width: 100,
                  maxWidth:100
                },
              },
              {
                title: "Practice",
                field: "practice",
                filtering: false,
              },
              {
                title: "Date",
                field: "date",
                filtering: false,
              },
              {
                title: "Status",
                field: "status",
                filtering: false,
                render: tableData => {

                  return (
                  
                    <div >
                    
                    {tableData.event.status==="approved" && tableData.event.status!=="processed"?  ("Invoice "+  tableData.event.status.charAt(0).toUpperCase()+ tableData.event.status.slice(1)):tableData.event.status==="processed"? ("Payment "+  tableData.event.status.charAt(0).toUpperCase()+ tableData.event.status.slice(1)):("Event "+  tableData.event.status.charAt(0).toUpperCase()+ tableData.event.status.slice(1))}
                    </div>
                  //   <div style={(tableData.event.status.contain("approve") ||tableData.event.status.contain("process"))? { display: "flex" }: { display: "none" }}>
                    

                  //   {   tableData.event.status.charAt(0).toUpperCase()+ tableData.event.status.slice(1)}
                  // </div>
                  );
                }
              },
              {
                title: "Event Cost",
                field: "eventcost",
                filtering: false,
                cellStyle: {
                  width: 100,
                  maxWidth:100
                },
                render: tableData => {
         
                  return (
                    <React.Fragment>
                  
                     {tableData.event.invoice ? (
                      //  `${user.user.role.type==="manager"?"$"+ parseFloat(tableData.event.invoice.totalCost):"$"+ parseFloat(tableData.event.invoice.totalCost- tableData.event.invoice.educatorFee)}`
                        `${ "$"+ parseFloat(Number (tableData.event.invoice.totalCost- tableData.event.invoice.educatorFee)).toFixed(2)}`
                      ) : (
                        //user.user.role.type!=="manager"?
                     
                        <LibraryAdd  
                          onClick={() => {
                            setSelectedEvent({
                              ...tableData.event
                            });
                          }}
                        /> 
                   
                       // :"$"+ 0
                      )}
                    </React.Fragment>
                  );
                }
              },
              {
                title: "Attendees",
                field: "attendees",
                filtering: false,
                sorting: false,
                render: tableData => {
                  
                  return (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span style={{ marginRight: 8 }}>
                      {tableData.event.attendees.length+"/"+tableData.event.expectedAttendees}
                      </span>
                     
                      <Link   to={`/attendees/${tableData.event.id}`}>
                        <Create />
                      </Link>
                    </div>
                  );
                }
              },
              
              {
                title: "Survey",
                field: "survey",
                filtering: false,
                render: tableData => {
              
                  return (
                    <div style={{ display: "flex"}} >
                         {tableData.survey!=null?(tableData.survey.length +"/" +(tableData.attendees!=null? tableData.attendees.length:0)):0}
                    </div>
                  );
                }
              
              },
              {
                title: "Educator",
                field: "author",
                hidden:user.user.role.type==="manager"? false:true,
                render: tableData => {
              
                  return (
                    <div style={user.user.role.type==="manager"?{ display: "flex" }:{ display: "none" }}>
                         {tableData.author}
                    </div>
                  );
                }
              },
            //   {
             
            //     title: "Approved",
            //     field: "Approved",
            //     hidden:user.user.role.type==="manager"? false:true,
            //     render: tableData => {

            //       return (
            //         <div style={{ display: "flex" }}>
            //            {tableData.event.approvedOn!==null && tableData.event.approvedOn!=='' ? (
            //                           moment(tableData.event.approvedOn).format("MM.DD.YYYY hh:mm A") 
            //         ) : (
            //           <Button
            //             color="primary"
            //             onClick={() =>
            //               sendApproved(tableData.event)
                         
            //           }
            //           >
            //             APPROVE
            //           </Button>
            //         )}
            //         </div>
            //       );
            //     }
            //   }
            // ,
            //   {
            //     title: "Processed",
            //     field: "Processed",
            //     hidden:user.user.role.type==="manager"? false:true,
            //     render: tableData => {
            //       return (
            //         <div style={user.user.role.type==="manager"?{ display: "flex" }:{ display: "none" }}>
            //               {tableData.event.processedOn!==null && tableData.event.processedOn!==''? (
            //    moment(tableData.event.processedOn).format("MM.DD.YYYY hh:mm A")
 
            //         ) : (
                     
            //           <Button
            //             color="primary"
            //             onClick={()=>
            //               sendProcessed(tableData.event)
            //             }
            //           >
            //             Process
            //           </Button>
            //         )}
            //         </div>
            //       );
            //     }
            //   }
            //   ,
              // {
              //   title: "Send Survey",
              //   field: "Send Survey",
              //   hidden:user.user.role.type==="manager"? false:true,
              //   render: tableData => {
              
              //     return (
              //       <div style={user.user.role.type==="manager"?{ display: "flex" }:{ display: "none" }}>
              //             {tableData.event.surveySentOn!==null && tableData.event.surveySentOn!==''? (
              //  moment(tableData.event.surveySentOn).format("MM.DD.YYYY hh:mm A")
 
              //       ) : (
                     
              //         <Button
              //           color="primary"
              //           onClick={()=>
              //             sendSurvey(tableData.event)
              //           }
              //         >
              //           SEND SURVEY
              //         </Button>
              //       )}
              //       </div>
              //     );
              //   }
              // },

              {
                title: "Action",
                field: "action",
                filtering: false,
                sorting: false,
                render: tableData => {
                  return (
                    <div style={{ display: "flex" }}>
                      <Link to={`/editevent/${tableData.event.id}`}>
                        <Create />
                      </Link>
                      <Button
                  
                  onClick={() =>updateGeoLocationcall(tableData.event)}
                >
                     <Visibility />
              </Button>
                      {/* <Link to={`/event/${tableData.event.id}`}>
                        <Visibility />
                      </Link> */}
                    </div>
                  );
                }
              }
            ]}
            data={events.events.map(event => {
       

    
              return {
                eventName:event.eventName,
                practice: event.practice
                  ? event.practice.practiceName
                  : event.eventName,
                date: moment(event.eventStart).format("MM.DD.YYYY hh:mm A"),
                status: event.status!=='' &&    event.status!==null ? event.status : "Upcoming",
                survey:event.surveys,
                eventcost: "",
                details: event.additionalInfo,
                attendees: event.attendees,
                action: "",
                event: event,
                processedOn:moment(event.processedOn).format("MM.DD.YYYY hh:mm A"),
                approvedOn:moment(event.approvedOn).format("MM.DD.YYYY hh:mm A"),
                author:event.author ? event.author.username : '-',
              };
            })}
            options={{
              sorting: true,
              filtering: false,
              toolbar: true,
              draggable: false,
              search: true 
            }}
          />
        )}
      </Grid>
    </React.Fragment>
  );
};

const FinanceModal = props => {
  const [filesList, setFilesList] = useState([]);
  const [financeValue, setFinanceValue] = useState("");
  const{user,users}=props;
  const { event } = props;
  const managers = users.users.filter(user => user.role.type === 'manager');

  const sendFiles = () => {
    return new Promise((resolve, reject) => {
      const files = filesList;
      let filesToUpload = new FormData();
      let filesOnServer = [];
if(files.length!==0)
{
      files.forEach(file => {
        // check if file is not exist on server
        if (!file.id) {
          filesToUpload.append("files", file);
        } else {
          filesOnServer.push(Number(file.id));
        }
      });

      // if exist any file to upload
      if (filesToUpload.get("files")) {
        uploadFile(filesToUpload, props.user.jwt)
          .then(data => {
            let uploadedFiles = [];
            data.data.forEach(file => {
              uploadedFiles.push(file.id);
            });

            resolve([...filesOnServer, ...uploadedFiles]);
          })
          .catch(err => {
            reject(err);
          });
      } else {
        resolve(filesOnServer);
      }
    }
    else
    {
      resolve(0);
    }
    });
 
  };

  const removeFileFromList = name => {
    const newFileList = filesList.filter(file => {
      if (file.name === name) return false;
      return true;
    });

    setFilesList(newFileList);
  };

  // const sendFilesAction = async () => {
  //   debugger;
  //   const filesIDs = await sendFiles();
  //  //  getFileList(filesIDs);
  //   // setFilesList(filesIDs);
  //   // alert("Files added");
  // };

  const getFileIcon = file => {
  
    const mimeType = file.mime || file.type;

    const format = mimeType.split("/")[0];

    switch (format) {
      case "image":
        return <Image />;

      default:
        return <Description />;
    }
  
  };
  const uploadFiles = async () => {
  
  if(event.invoice ? event.invoice.receipts : [])
  {
    const filesIDs = await sendFiles();
   
  if(filesIDs!==0)
  {

    if (financeValue) {
      const emaildata = {
        lunchCost: parseFloat(financeValue),
        educatorFee: parseFloat(financeValue),
        totalCost: parseFloat(financeValue),
        receipts: filesIDs,
        event: event
      };
      const data = {
        lunchCost: parseFloat(financeValue),
        educatorFee: parseFloat(financeValue),
        totalCost: parseFloat(financeValue),
        receipts: filesIDs,
        event: event.id
      };
        //Send EMAIL to MANAGER 
      
      props.createInvoice(data);
      SendEmail(emaildata);
      props.close();
    }
    else
    {
      const emaildata = {
        lunchCost: parseFloat(financeValue),
        educatorFee: parseFloat(financeValue),
        totalCost: parseFloat(financeValue),
        receipts: filesIDs,
        event: event
      };
      const data = {
        lunchCost: parseFloat(financeValue),
        educatorFee: parseFloat(financeValue),
        totalCost: parseFloat(financeValue),
      
        event: event.id
      };
        //Send EMAIL to MANAGER 
       
      props.createInvoice(data);
      SendEmail(emaildata);
      props.close();
    }
    } else {

      alert("You must enter a Total Event Cost and at least one receipt");
    }
  }
  };
  const SendEmail=(data)=>{

    let authors=data.event.author!==null && data.event.author!==undefined?data.event.author.username:user.username;
    let practiceNames=data.event.practice!==null?data.event.practice.practiceName:"";


   if(managers.length>0)
   {
    
    axios.post(
      `${server}/email`,
      {
        to:managers[0].email,
        subject:data.event.eventName +' Event Cost has added ',
        html:'Dear Manager, <br/><br/>  A new event cost has been added to the EdNet Interface. The details are listed below: <br/>  <br/>Educator: '+authors+'<br/>  Practice Name :'+practiceNames+'  <br/> Event Name :'+data.event.eventName+'  <br/> Event Date:'+data.event.eventStart+'  <br/>  Event Cost : $'+data.totalCost+'    <br/> Thanks'
       
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      }
    );
   }

  }

  return (
    <Modal
      aria-labelledby="finance-modal-title"
      aria-describedby="finance-modal-description"
      disableAutoFocus={true}
      open={true}
    

    >
           
      <div className="FinanceModal">
      <Button variant="contained" className="buttonset" color="primary"     onClick={() =>   window.location.reload()}>
              <Clear />
            
              </Button>
        <Typography variant="h2">EVENT COST</Typography>
       
        <Grid container>
          <Grid item xs={12}>
            <Grid container className="FinanceModal__container">
              <Grid item xs={6} className="FinanceModal__container_item">
                <Typography variant="h6">{event.practice!=null?event.practice.practiceName:""}</Typography>
                <Typography>
                  {event.eventName}
                  {/* {`${event.practice.zip} ${event.practice.address1 ||
                  event.practice.address2}, ${event.practice.city}, ${event.practice.state}`} */}
                  </Typography>
                <Typography>
                  {moment(event.eventStart).format("MM.DD.YYYY hh:mm A")}
                </Typography>
              </Grid>
            </Grid>
            <Grid container className="FinanceModal__container">
              <Grid item xs={12} className="FinanceModal__container_item">
                <Typography variant="h6">Enter Total EVENT Cost</Typography>
                <TextField
                  id="totalCost"
                  label="Event Cost"
                  variant="outlined"
                  fullWidth
                  value={financeValue}
                  onChange={e => setFinanceValue(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} className="FinanceModal__container_item">
                <Typography variant="h6">Receipts</Typography>
                <Paper className="message__paper">
      <Grid item xs={12} className="upload-container">
        {!filesList.length > 0 ? (
          <React.Fragment>
            <div className="message__paper_icon">
              <Info />
            </div>
            <Typography align="center">
              No receipts have been added for this event. Click the button below
              to add.
            </Typography>
          </React.Fragment>
        ) : null}
        <Grid container  item    className="FinanceModal__container">
          {[...filesList].map(file => (
            <Grid
              item
              xs={4}
              key={file.name}
              className="FinanceModal__container_item"
            >
              <Grid container className="FinanceModal__file">
                <Grid item xs={12}>
                  <div className="FinanceModal__file_container">
                    {getFileIcon(file)}
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" noWrap>
                    {file.name}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>{file.size}kb</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="outlined"
                    color="default"
                    onClick={() => removeFileFromList(file.name)}
                    fullWidth
                  >
                    Remove file
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
       
      </Grid>
    
      <Grid container item   className="upload-container">

      <Grid container justify="space-between"></Grid>
      <Grid container justify="space-between"></Grid>
      <div className="upload-btn-wrapper">
      <Typography align="center">
          <Button
            variant="contained"
            color="primary" 
            className="upload-btn-wrapper__btn "
            item   
          >
            add files
          </Button>
          </Typography>
          <input item    
            type="file"
            name="myfile"
            multiple
            onChange={e => setFilesList([...filesList, ...e.target.files])}
          />
{/*        
        <Button
          variant="contained"
          color="primary" item
          className="upload-btn-wrapper__btn"
          onClick={sendFilesAction}
           
        >
          upload files
        </Button> */}
        </div>
      </Grid>
    </Paper>
                {/* <FilesBlock
                  files={event.invoice ? event.invoice.receipts : []}
                  token={props.user.jwt}
                  getFileList={getFileList}
                /> */}
              </Grid>
            </Grid>
            <Grid container  className="FinanceModal__container">
              <Button
                variant="contained"
                color="primary"
                className="upload-btn-wrapper__btn buttonmargin"            
                onClick={uploadFiles}
                item
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
};

const mapDispatchToProps = {
  getEventsList: getEventsList,
  createInvoice: createInvoice,
  editEvent: editEvent,
  updateGeoLocation:updateGeoLocation,
  getUsersList:getUsersList
  
};

const mapStateToProps = state => {
  const { events, user ,users} = state;
  return { events, user ,users};
};

const connectedEventsTable = connect(
  mapStateToProps,
  mapDispatchToProps
)(EventsTable);
export { connectedEventsTable as EventsTable };
