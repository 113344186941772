import React from "react";
import { Button, Menu, MenuItem } from "@material-ui/core";
import { Link } from "react-router-dom";
import { ArrowDropDown } from "@material-ui/icons";

export const DropDownButton = ({ name, options }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button aria-controls="menu" aria-haspopup="true" onClick={handleClick}>
        {name}
        <ArrowDropDown />
      </Button>
      <Menu
        id="menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {options.map(option => (
          <Link
            to={option.to ? option.to : "/"}
            onClick={option.click}
            style={{
              color: "rgba(0, 0, 0, 0.87)",
              textDecoration: "none"
            }}
            key={option.name}
          >
            <MenuItem onClick={handleClose}>{option.name}</MenuItem>
          </Link>
        ))}
      </Menu>
    </div>
  );
};
