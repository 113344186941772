import React from "react";
import { Grid, Button } from "@material-ui/core";
import { EventsTable } from "../../../components/EventsTable";

import { Link } from "react-router-dom";

const ListEvents = () => {
  return (
    <React.Fragment>
      <Grid item xs={12}>
        <EventsTable />
        <Link to="/addevent" className="btn-link">
          <Button variant="contained" color="primary" style={{ marginTop: 20 }}>
            Add event
          </Button>
        </Link>
      </Grid>
    </React.Fragment>
  );
};

export { ListEvents };
