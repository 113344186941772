import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Grid,
  Typography,
  TextField,
  Button,
  Avatar,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from "@material-ui/core";
// import { Close } from "@material-ui/icons";
import MomentUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import { useParams,useHistory } from "react-router-dom";
import { ModalAlert } from "../../../../components/Modal";
import { getUserByID, editNewUser,deleteUser } from "../../../../_actions/users.actions";
import { uploadFile } from "../../../../_api/files";
import { server } from "../../../../_constants/server.constants";

const EditUser = ({ user, getUserByID, editNewUser,deleteUser }) => {
  let { id } = useParams();
  const history = useHistory();
  const [userData, setUserData] = useState();
  const [photoObj, setPhotoObj] = useState({});

  useEffect(() => {
    const loadUserData = async () => {

      const userDatasss = await getUserByID(id, user.jwt);

      setUserData({
        ...userDatasss
      });

      userDatasss.photo &&
        setPhotoObj({
          file: userDatasss.photo,
          url: `${server}${userDatasss.photo.url}`
        });
    };

    if (user.jwt) loadUserData();
  }, [getUserByID, id, user.jwt]);
  
  const [modalAlert, setModalAlert] = useState({
    open: false,
    success: true,
    title: "",
    message: ""
  });

  const [showEmptyFields, setShowEmptyFields] = useState(false);

  const isValidForm = () => {
    if (
      userData.username &&
      userData.email &&
      userData.role &&
      userData.firstName &&
      userData.lastName &&
      userData.contactPhone &&
      userData.address1 &&
      userData.city &&
      userData.state &&
      userData.Territory &&
      userData.zip &&
      userData.discountCode &&
      userData.contractStart &&
      userData.contractEnd &&
      userData.password 
    ) {
      setShowEmptyFields(false);
      return true;
    } else {
      //alert("Not all the fields are field in");
      setShowEmptyFields(true);
      return false;
    }
  };

  const sendData = async () => {
    try {
      if (isValidForm()) {
      
        const newPhotoID = await uploadPhoto();      
     
    if( newPhotoID!==0  )
    {
      const editedUser = { ...userData, photo: newPhotoID};  
      
    
      await editNewUser(editedUser, user.jwt);
        
    }
    else
    {
      
  if(userData.photo!==null && userData.photo!=="")
  {
        const editedUser = { ...userData,photo:userData.photo.id};
        await editNewUser(editedUser, user.jwt); 
  }
  else
  {
    const editedUser = { ...userData}; 
    await editNewUser(editedUser, user.jwt);
  }
       
    }
        setModalAlert({
          open: true,
          success: true,
          title: "SUCCESS!",
          message: "New User has been edited."
        });
      }
      else
      {
        setModalAlert({
          open: true,
          success: true,
          title: "WARNING!",
          message: "Not all required  fields are field in"
        });
      }
    } catch (error) {
      setModalAlert({
        open: true,
        success: false,
        title: "ERROR!",
        message: error
      });
    }
  };

  const uploadPhoto = () => {

    return new Promise((resolve, reject) => {
      let filesToUpload = new FormData();
     
    if(photoObj!==null)
    {
      if( photoObj.file!==null &&  photoObj.file!==undefined)
      {
     if( photoObj.file.url===null || photoObj.file.url===undefined)
     {
      filesToUpload.append("files", photoObj.file);

      if (filesToUpload.get("files")) {
        uploadFile(filesToUpload, user.jwt)
          .then(data => {
          
            resolve(data.data[0].id);
          })
          .catch(err => {
            reject(err);
          });
      } else {
        setModalAlert({
          open: true,
          success: true,
          title: "WARNING!",
          message: "Please, select a image"
        });
        //alert("Please, select a image");
      }
    }
    else
    {
      resolve(0);
    }
  }
    else
    {
      resolve(0);
    }
  }
  else
  {
    resolve(0);
  }
    });
   
  };
  // const deleteUserRecord=async ()=>{
  //   try {

  //     await deleteUser(userData.id, user.jwt);
  //     history.push("/userslist");
  //    //  setModalAlert({
  //    //   open: true,
  //    //   success: true,
  //    //   title: "SUCCESS!",
  //    //   message: "The event has been deleted"
  //    // });
     
  //    }
  //    catch (error) {
  //      setModalAlert({
  //        open: true,
  //        success: false,
  //        title: "ERROR!",
  //        message: error
  //      });
  //    }
  // }

  // const selectPhoto = async () => {
  //   const newPhotoID = await uploadPhoto();
  //   setUserData({ ...userData, photo: newPhotoID });
  //   setModalAlert({
  //     open: true,
  //     success: true,
  //     title: "SUCCESS!",
  //     message: "The photo is uploaded"
  //   });
  //   //alert("The photo is uploaded");
  // };

  return (
    <React.Fragment>
      {modalAlert.open && (
        <ModalAlert
        close={() => {
          setModalAlert({
            ...modalAlert,
            open: false
          });

         history.push("/userslist");
        }}
          success={modalAlert.success}
          title={modalAlert.title}
          message={modalAlert.message}
          open={modalAlert.open}
        />
      )}
      {userData && (
        <Grid item xs={12} className="AddEvent">
          <Grid container>
            <Grid item xs={12} md={6}>
              <Typography variant="h5" style={{ marginBottom: 20 }}>
                New User
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={4} className="input-item">
              <TextField
                id="first-name"
                fullWidth
                variant="outlined"
                label="First name"
                value={userData.firstName}
                error={!userData.firstName && showEmptyFields}
                onChange={e =>
                  setUserData({
                    ...userData,
                    firstName: e.target.value,
                    username: `${e.target.value}${userData.lastName}`
                  })
                }
              />
            </Grid>
            <Grid item xs={12} md={4} className="input-item">
              <TextField
                id="first-name"
                fullWidth
                variant="outlined"
                label="Last name"
                value={userData.lastName}
                error={!userData.lastName && showEmptyFields}
                onChange={e =>
                  setUserData({
                    ...userData,
                    lastName: e.target.value,
                    username: `${userData.firstName}${e.target.value}`
                  })
                }
              />
            </Grid>
            <Grid item xs={12} md={4} className="input-item">
              <FormControl fullWidth variant="outlined">
                <InputLabel id="discount-code-label">Discount Code</InputLabel>
                <Select
                  labelId="discount-code-label"
                  id="discount-code"
                  fullWidth
                  value={userData.discountCode}
                  error={!userData.discountCode && showEmptyFields}
                  onChange={e =>
                    setUserData({
                      ...userData,
                      discountCode: e.target.value
                    })
                  }
                >
                    <MenuItem value={"pro50ed111"}>pro50ed111</MenuItem>
                  <MenuItem value={"pro50ed112"}>pro50ed112</MenuItem>
                  <MenuItem value={"pro50ed113"}>pro50ed113</MenuItem>
                  <MenuItem value={"pro50ed114"}>pro50ed114</MenuItem>
                  <MenuItem value={"pro50ed115"}>pro50ed115</MenuItem>
                  <MenuItem value={"pro50ed116"}>pro50ed116</MenuItem>
                  <MenuItem value={"pro50ed117"}>pro50ed117</MenuItem>
                  <MenuItem value={"pro50ed118"}>pro50ed118</MenuItem>
                  <MenuItem value={"pro50ed119"}>pro50ed119</MenuItem>
                  <MenuItem value={"pro50ed120"}>pro50ed120</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={4} className="input-item">
              <TextField
                id="first-name"
                fullWidth
                variant="outlined"
                label="Phone"
                value={userData.contactPhone}
                error={!userData.contactPhone && showEmptyFields}
                onChange={e =>
                  setUserData({
                    ...userData,
                    contactPhone: e.target.value
                  })
                }
              />
            </Grid>
            <Grid item xs={12} md={4} className="input-item">
              <TextField
                id="first-name"
                fullWidth
                variant="outlined"
                label="Email"
                value={userData.email}
                error={!userData.email && showEmptyFields}
                onChange={e =>
                  setUserData({
                    ...userData,
                    email: e.target.value
                  })
                }
              />
            </Grid>
            <Grid item xs={12} md={4} className="input-item">
              <FormControl fullWidth variant="outlined">
                <InputLabel id="territory-label">Territory</InputLabel>
                <Select
                  labelId="territory-label"
                  id="territory"
                  fullWidth
                  value={userData.Territory}
                  error={!userData.Territory && showEmptyFields}
                  onChange={e =>
                    setUserData({
                      ...userData,
                      Territory: e.target.value
                    })
                  }
                >
                  <MenuItem value={"Pacific"}>Pacific</MenuItem>
                  <MenuItem value={"Rocky Mountains"}>Rocky Mountains</MenuItem>
                  <MenuItem value={"Southwest"}>Southwest</MenuItem>
                  <MenuItem value={"Midwest"}>Midwest</MenuItem>
                  <MenuItem value={"Northeast"}>Northeast</MenuItem>
                  <MenuItem value={"Southeast"}>Southeast</MenuItem>
                  <MenuItem value={"Noncontiguous"}>Noncontiguous</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={4} className="input-item">
              <TextField
                id="first-name"
                fullWidth
                variant="outlined"
                label="Address - Street"
                value={userData.address1}
                error={!userData.address1 && showEmptyFields}
                onChange={e =>
                  setUserData({
                    ...userData,
                    address1: e.target.value
                  })
                }
              />
            </Grid>
            <Grid item xs={12} md={4} className="input-item">
              <TextField
                id="first-name"
                fullWidth
                variant="outlined"
                label="Suite/Number"
                value={userData.address2}
             
                onChange={e =>
                  setUserData({
                    ...userData,
                    address2: e.target.value
                  })
                }
              />
            </Grid>
       
            <Grid item xs={12} md={4} style={{ paddingLeft: 20 }}>
              <Grid container>
              <Grid item xs={12} md={4} className="input-item">
                  <TextField
                    required
                    id="city"
                    fullWidth
                    variant="outlined"
                    label="City"
                    value={userData.city}
                    error={!userData.city && showEmptyFields}
                    onChange={e =>
                      setUserData({
                        ...userData,
                        city: e.target.value
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} md={4} className="input-item">
                  <TextField
                    id="first-name"
                    fullWidth
                    variant="outlined"
                    label="State"
                    value={userData.state}
                    error={!userData.state && showEmptyFields}
                    onChange={e =>
                      setUserData({
                        ...userData,
                        state: e.target.value
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} md={4} className="input-item">
                  <TextField
                    id="first-name"
                    fullWidth
                    variant="outlined"
                    label="Zip"
                    value={userData.zip}
                    error={!userData.zip && showEmptyFields}
                    onChange={e =>
                      setUserData({
                        ...userData,
                        zip: e.target.value
                      })
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={4} className="input-item">
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/DD/YYYY"
                  fullWidth
                  id="date-picker"
                  label="Contract Start"
                  value={userData.contractStart}
                  error={!userData.contractStart && showEmptyFields}
                  onChange={e =>
                    setUserData({
                      ...userData,
                      contractStart: e.toISOString()
                    })
                  }
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                  className="input-item"
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} md={4} className="input-item">
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/DD/YYYY"
                  fullWidth
                  id="date-picker"
                  label="Contract End"
                  value={userData.contractEnd}
                  error={!userData.contractEnd && showEmptyFields}
                  onChange={e =>
                    setUserData({
                      ...userData,
                      contractEnd: e.toISOString()
                    })
                  }
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                  className="input-item"
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} md={4} className="input-item">
              <TextField
                id="password"
                fullWidth
                variant="outlined"

                label="Password"
                value={userData.password}
                error={!userData.password && showEmptyFields}
                onChange={e =>
                  setUserData({
                    ...userData,
                    password: e.target.value
               
                  })
                }
              />
            </Grid>
          
          </Grid>
          <Grid container className="upload-container">
            <Avatar  
              alt={`${userData.firstName}-${userData.lastName}`}
              src={photoObj.url}
            />
             <Grid item xs={12} md={4} className="input-item"></Grid>
            <div className="upload-btn-wrapper">
              <Button
                variant="contained"
                color="primary"
                className="upload-btn-wrapper__btn"
              >
                select photo
              </Button>
              <input
                type="file"
                name="myfile"
                accept="image/*"
                onChange={e => {
                  const file = e.target.files[0];
                  const url = URL.createObjectURL(file);
                  setPhotoObj({ file, url });
                }}
              />
            </div>
            {/* <Button
              variant="contained"
              color="primary"
              className="upload-btn-wrapper__btn"
              onClick={selectPhoto}
            >
              upload photo
            </Button> */}
          </Grid>
          <Grid container justify="space-between">
            {/* <Button variant="contained" color="secondary" className="btn" onClick={deleteUserRecord}>
              <Close />
              DELETE
            </Button> */}
            <Button
              variant="contained"
              color="primary"
              className="btn"
              onClick={sendData}
            >
              SAVE
            </Button>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
};

const mapDispatchToProps = {
  getUserByID: getUserByID,
  editNewUser: editNewUser,
  deleteUser:deleteUser
};

const mapStateToProps = state => {
  const { events, user } = state;
  return { events, user };
};

const connectedEditUser = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditUser);
export { connectedEditUser as EditUser };
