import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Grid,
  Typography,
  TextField,
  Button
 
} from "@material-ui/core";

import { useHistory } from "react-router-dom";
import { ModalAlert } from "../../components/Modal";
import { getUserByID, editNewUser,deleteUser } from "../../_actions/users.actions";
import { userLogout } from "../../_actions/user.actions";
const ChangePassword = ({ user, getUserByID, editNewUser,deleteUser,userLogout }) => {
  
  const history = useHistory();
  const [userData, setUserData] = useState();

  useEffect(() => {
    const loadUserData = async () => {
      const userDatasss = await getUserByID(user.user.id, user.jwt);

      setUserData({
        ...userDatasss
      });

     
    };

    if (user.jwt) loadUserData();
  }, [getUserByID, user.user.id, user.jwt]);

  const [modalAlert, setModalAlert] = useState({
    open: false,
    success: true,
    title: "",
    message: ""
  });
  const [passwordData, setPasswordData] = useState({
    password: "",
    passwordConfirmation: ""
  
  });
  const [showError, setShowError] = useState(false);

  const resetPasswordHandler = async ()  => {
    const {  password, passwordConfirmation } = passwordData;

    if ( password && passwordConfirmation) {
      if (password !== passwordConfirmation) {
        setShowError(true);
        setModalAlert({
                 open: true,
              success: false,
              title: "ERROR!",
              message: "Password does not match"
              });
      
    } else {
      const editedUser = { ...userData, password: password };  
     
      setShowError(false);
      await editNewUser(editedUser, user.jwt);
      userLogout();
    }
     
    } else {
      setShowError(true);
      setModalAlert({
        open: true,
     success: false,
     title: "ERROR!",
     message: "Something went wrong"
     });
    }
  };
  

  return (
    <React.Fragment>
      {modalAlert.open && (
        <ModalAlert
        close={() => {
          setModalAlert({
            ...modalAlert,
            open: false
          });
if(modalAlert.title==="SUCCESS!")
{
         history.push("/login");

         }
        }}
          success={modalAlert.success}
          title={modalAlert.title}
          message={modalAlert.message}
          open={modalAlert.open}
        />
      )}
      {userData && (
     <Grid container direction="column">
     <Typography variant="h5" className="input-field">
       Change Password
     </Typography>
     <Grid item xs={3}>
      
         <Grid item xs={12} className="input-field">
           <TextField
             id={`reset-password`}
             fullWidth
             variant="outlined"
             value={passwordData.password}
             label="Enter password"
             type="password"
             error={showError && passwordData.password.length === 0}
             onChange={e => {
               setPasswordData({
                 ...passwordData,
                 password: e.target.value
               });
             }}
           />
         </Grid>
         <Grid item xs={12} className="input-field">
           <TextField
             id={`reset-password-confirm`}
             fullWidth
             variant="outlined"
             type="password"
             value={passwordData.passwordConfirmation}
             label="Confirm password"
             error={
               showError && passwordData.passwordConfirmation.length === 0
             }
             onChange={e => {
               setPasswordData({
                 ...passwordData,
                 passwordConfirmation: e.target.value
               });
             }}
           />
         </Grid>
         <Grid item xs={12} className="input-field">
           <Button
             variant="contained"
             color="primary"
             fullWidth
             onClick={() => {
               resetPasswordHandler();
             }}
           >
             Submit
           </Button>
         </Grid>
       </Grid>
     </Grid>
  
      )}
    </React.Fragment>
  );
};

const mapDispatchToProps = {
  getUserByID: getUserByID,
  editNewUser: editNewUser,
  deleteUser:deleteUser,
  userLogout:userLogout
};

const mapStateToProps = state => {
  const { events, user } = state;
  return { events, user };
};

const connectedChangePassword = connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePassword);
export { connectedChangePassword as ChangePassword };
