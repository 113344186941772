import { invoicesConstants } from "../_constants";
import {
  getInvoicesEventsAPI,
  createInvoiceAPI,
  editInvoiceAPI,
  approveInvoiceAPI,
  processInvoiceAPI
} from "../_api/invoices";
import { parseGraphQLError } from "../_helpers/errors";

export const getInvoicesEvents = token => dispatch => {
  dispatch({ type: invoicesConstants.GET_INVOICES_LIST_REQUEST });

  getInvoicesEventsAPI(token)
    .then(data => {
      dispatch({
        type: invoicesConstants.GET_INVOICES_LIST_SUCCESS,
        payload: data.data.data.invoices
      });
    })
    .catch(error => {
      let errorMessage = parseGraphQLError(error);

      dispatch({
        type: invoicesConstants.GET_INVOICES_LIST_ERROR,
        payload: errorMessage
      });
    });
};

export const createInvoice = (data, token) => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch({ type: invoicesConstants.CREATE_INVOICE_REQUEST });

    createInvoiceAPI(data, token)
      .then(data => {
        dispatch({
          type: invoicesConstants.CREATE_INVOICE_SUCCESS,
          payload: data.data.data.invoices
        });

        resolve();
      })
      .catch(error => {
        let errorMessage = parseGraphQLError(error);

        dispatch({
          type: invoicesConstants.CREATE_INVOICE_ERROR,
          payload: errorMessage
        });

        reject(errorMessage);
      });
  });
};

export const editInvoice = (data, token) => dispatch => {

  return new Promise((resolve, reject) => {
    dispatch({ type: invoicesConstants.EDIT_INVOICE_REQUEST });

    editInvoiceAPI(data, token)
      .then(data => {
        dispatch({
          type: invoicesConstants.EDIT_INVOICE_SUCCESS,
          payload: data.data.data.invoices
        });

        resolve();
      })
      .catch(error => {
        let errorMessage = parseGraphQLError(error);

        dispatch({
          type: invoicesConstants.EDIT_INVOICE_ERROR,
          payload: errorMessage
        });

        reject(errorMessage);
      });
  });
};
export const approveInvoice = (data, token) => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch({ type: invoicesConstants.APPROVE_INVOICE_REQUEST });

   approveInvoiceAPI(data, token)
      .then(data => {
        dispatch({
          type: invoicesConstants.APPROVE_INVOICE_SUCCESS,
          payload: data.data.data.invoices
        });

        resolve();
      })
      .catch(error => {
        let errorMessage = parseGraphQLError(error);

        dispatch({
          type: invoicesConstants.APPROVE_INVOICE_ERROR,
          payload: errorMessage
        });

        reject(errorMessage);
      });
  });
};
export const processInvoice = (data, token) => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch({ type: invoicesConstants.PROCESS_INVOICE_REQUEST });

    processInvoiceAPI(data, token)
      .then(data => {
        dispatch({
          type: invoicesConstants.PROCESS_INVOICE_SUCCESS,
          payload: data.data.data.invoices
        });

        resolve();
      })
      .catch(error => {
        let errorMessage = parseGraphQLError(error);

        dispatch({
          type: invoicesConstants.PROCESS_INVOICE_ERROR,
          payload: errorMessage
        });

        reject(errorMessage);
      });
  });
};