import axios from "axios";
import { server } from "../_constants";

export const getListResourcesByCategoryAPI = (token, category) => {
  return axios.post(
    `${server}/graphql`,
    {
      query: `
        query {
            resources (
                where: {
                category: ${category}
                }
            ) {
            id
            title
            category
            created_at
            file {
                id
                name
                url
                provider
                mime
                provider_metadata
            }
            }
        }
          `
    },
    {
      headers: {
        Authorization: "bearer " + token,
        "Content-Type": "application/json"
      }
    }
  );
};
