import axios from "axios";
import { server } from "../_constants";
import moment from "moment";

export const getListSurveysAPI = token => {
  return axios.post(
    `${server}/graphql`,
    {
      query: `
      query 
      {
          surveys {
            id
            submittedOn
            OfficeName
            JobTitle
            OtherJobTitle
            answer3
            answer4
            answer5
            answer6
            answer7
            answer8
            answer9
            answer10
            answer11
            answer12
            answer13
            answer14
           
          
            educator {
          id
          username
      }
      events {
          id
         eventName
      }
            attendees {
              id
              email
              firstName
              lastName
              surveys {
                id
                submittedOn
              }
            }
        }
      }
          `
    },
    {
      headers: {
        Authorization: "bearer " + token,
        "Content-Type": "application/json"
      }
    }
  );
};

export const getSurveyByIDAPI = (surveyID, token) => {
  return axios.post(
    `${server}/graphql`,
    {
      query: `
      query (
        $id: ID!    
    ) {
      survey (
        id: $id
        ) {

          id
          submittedOn
          OfficeName
          JobTitle
          OtherJobTitle
          answer3
          answer4
          answer5
          answer6
          answer7
          answer8
          answer9
          answer10
          answer11
          answer12
          answer13
          answer14
        
        
           educator {
        id
        username
    }
    event {
        id
       eventName
    }
          attendees {
            id
            email
            firstName
            lastName
            surveys {
              id
              submittedOn
            }
          }
       
      }
    }
    `,
      variables: {
        id: surveyID
      }
    },
    {
      headers: {
        Authorization: "bearer " + token,
        "Content-Type": "application/json"
      }
    }
  );
};

export const addSurveyAPI = (data) => {
 
  return axios.post(
    `${server}/graphql`,
    {
      query: `
      mutation (
    $officeName: String!,
    $attendee: [ID],
    $event: ID!,
    $educator: ID!,
    $jobTitle: String,
		$otherJobTitle: String,
	  $answer3: String,
		$answer4: String,
		$answer5: String, 
		$answer6: String,
		$answer7: String,
		$answer8: String,
		$answer9: String, 
		$answer10: String,
		$answer11: String, 
		$answer12: String,
		$answer13: String, 
		$answer14: String,
	
		$submittedOn:DateTime,
       ) {
      createSurvey(input: {
        data: {
    OfficeName:$officeName,
    attendees:$attendee,
    event:$event,
    educator:$educator,
    JobTitle:$jobTitle,
		OtherJobTitle:$otherJobTitle,
	  answer3:$answer3,
		answer4:$answer4,
		answer5:$answer5, 
	  answer6:$answer6,
  	answer7:$answer7,
		answer8:$answer8,
		answer9:$answer9, 
		answer10:$answer10,
		answer11:$answer11, 
		answer12:$answer12,
		answer13:$answer13, 
		answer14:$answer14,
	
		submittedOn:$submittedOn,
        }
      }) {
        survey {
        id
            respondent
            submittedOn
            OfficeName
            JobTitle
            OtherJobTitle
            answer3
            answer4
            answer5
            answer6
            answer7
            answer8
            answer9
            answer10
            answer11
            answer12
            answer13
            answer14
         
            event{

        id
        eventName
        eventStart
        expectedAttendees
        eventStart
        status
        additionalInfo
        eventDuration
            }
        educator {
            id
            username
        }
        attendees {
          id
          email
          firstName
          lastName
          surveys {
            id
            submittedOn
          }
        }
        }
      }
    }
    `,
      variables: {
              
        officeName:data.officeName,
        attendee:data.attendee,
        event:data.event,
        educator:data.answer15,
        jobTitle:data.jobTitle,
		otherJobTitle:data.otherJobTitle,
	    answer3:data.answer3,
		answer4:data.answer4,
		answer5:data.answer5, 
		answer6:data.answer6,
		answer7:data.answer7,
		answer8:data.answer8,
		answer9:data.answer9, 
		answer10:data.answer10,
		answer11:data.answer11, 
		answer12:data.answer12,
		answer13:data.answer13, 
		answer14:data.answer14,
	
		submittedOn: moment().format('YYYY-MM-DD hh:mm:ss a')
      }
    },
    {
      headers: {
      
        "Content-Type": "application/json"
      }
    }
  );
};


