import axios from "axios";
import { server } from "../_constants";

export const forgotPasswordAPi = email => {
  return axios.post(
    `${server}/auth/forgot-password`,
    {
      email: email
    },
    {
      headers: {
        "Content-Type": "application/json"
      }
    }
  );
};

export const resetPasswordAPI = (code, password, passwordConfirmation) => {
  return axios.post(
    `${server}/auth/reset-password`,
    {
      code,
      password,
      passwordConfirmation
    },
    {
      headers: {
        "Content-Type": "application/json"
      }
    }
  );
};
