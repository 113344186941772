import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import configureStore from "./_store";
// import { BrowserRouter } from "react-router-dom";
import { App } from "./App";
import * as serviceWorker from "./serviceWorker";
import "./_styles/styles.scss";
import { HashRouter } from "react-router-dom";

ReactDOM.render(
  <Provider store={configureStore()}>
  <HashRouter>
    <App />
  </HashRouter>
  </Provider>,
document.getElementById("root")
);
// ReactDOM.render(
//   <Provider store={configureStore()}>
//     <BrowserRouter>
//       <App />
//     </BrowserRouter>
//   </Provider>,
//   document.getElementById("root")
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
