import axios from "axios";
import { server } from "../_constants";

export const getListEvents = token => {
  return axios.post(
    `${server}/graphql`,
    {
      query: `
        query {
            events {
              id
              eventName
              eventStart,
              approvedOn,
              processedOn,
              surveySentOn,
              attendees {
                id
              }
              
               author {
            id
            username
      
           
        }
      
              expectedAttendees
              status
              additionalInfo
              practice {
                id
                practiceName
                address1
                address2
                city
                state
                zip
                email
                contactPhone
                contactPerson
                practiceType
                latitude
                longitude,
                SalesforceID
              }
              attendees {
                id
                email
                firstName
                lastName
                surveys {
                  id
                  submittedOn
                }
              }
              invoice {
                id
                totalCost
                lunchCost
                educatorFee
                receipts {
                  id
                  mime
                  name
                  size
                  url
                },

              }
              surveys {
                id
                submittedOn
              }
            }
          }
          `
    },
    {
      headers: {
        Authorization: "bearer " + token,
        "Content-Type": "application/json"
      }
    }
  );
};

export const getEventByIDAPI = (eventID, token) => {
  
  return axios.post(
    `${server}/graphql`,
    {
      query: `
      query (
        $id: ID!    
    ) {
      event (
        id: $id
        ) {
        id
        eventName
        eventStart
        expectedAttendees
        eventStart
        status
        additionalInfo
        eventDuration
        practice {
          id
          practiceName
          address1
          address2
          city
          state
          zip
          email
          contactPhone
          contactPerson
          practiceType
          latitude
          longitude
        }
        author {
            id
            username
        }
        attendees {
          id
          email
          firstName
          lastName
          surveys {
            id
            submittedOn
          }
        }
        invoice {
          id
          totalCost
          lunchCost
          educatorFee
          receipts {
            id
            mime
            name
            size
            url
          }
        }
      }
    }
    `,
      variables: {
        id: eventID
      }
    },
    {
      headers: {
        Authorization: "bearer " + token,
        "Content-Type": "application/json"
      }
    }
  );
};

export const addEventAPI = (data, token, id) => {
  return axios.post(
    `${server}/graphql`,
    {
      query: `
      mutation (
        $eventName: String!,
        $expectedAttendees: Int,
        $eventStart: DateTime,
        $contactPerson: String,
        $additionalInfo: String,
        $eventDuration: ENUM_EVENT_EVENTDURATION,
        $attendees: [ID],
        $practice: ID) {
      createEvent(input: {
        data: {
          eventName: $eventName,
          expectedAttendees: $expectedAttendees,
          eventStart: $eventStart, 
          contactPerson: $contactPerson, 
          additionalInfo: $additionalInfo, 
          eventDuration: $eventDuration, 
          attendees: $attendees, 
          practice: $practice 
        }
      }) {
        event {
          eventName
          eventStart
          eventDuration
          contactPerson
          practice {
              id
              practiceName
          }
          expectedAttendees
          attendees {
            firstName
            lastName
            email
          }
        }
      }
    }
    `,
      variables: {
        eventName: data.eventName,
        expectedAttendees: data.expectedAttendees,
        eventStart: data.eventStart,
        contactPerson: data.contactPerson,
        additionalInfo: data.additionalInfo,
        eventDuration: data.eventDuration,
        attendees: data.attendees,
        practice: data.practice
      }
    },
    {
      headers: {
        Authorization: "bearer " + token,
        "Content-Type": "application/json"
      }
    }
  );
};

export const editEventAPI = (data, token, id) => {
  return axios.post(
    `${server}/graphql`,
    {
      query: `
      mutation (
        $id: ID!,
        $eventName: String!,
        $eventStart: DateTime,
        $expectedAttendees: Int,
        $contactPerson: String,
        $additionalInfo: String,
        $eventDuration: ENUM_EVENT_EVENTDURATION,
        $attendees: [ID],
        $practice: ID,
        $status: ENUM_EVENT_STATUS,
        $approvedOn: DateTime,
        $processedOn: DateTime
        ) {
      updateEvent(input: {
        where: {
            id: $id
        }
        data: {
          eventName: $eventName,
          expectedAttendees: $expectedAttendees,
          eventStart: $eventStart, 
          contactPerson: $contactPerson, 
          additionalInfo: $additionalInfo, 
          eventDuration: $eventDuration, 
          attendees: $attendees, 
          practice: $practice,
          status: $status,
          processedOn:$processedOn,
          approvedOn:$approvedOn
        }
      }) {
        event {
          eventName
          eventStart
          processedOn 
          approvedOn 
          eventDuration
          contactPerson
          practice {
              id
              practiceName
          }
          expectedAttendees
          invoice {
            totalCost
          }
        }
      }
    }
    `,
      variables: {
        id: data.id,
        eventName: data.eventName,
        expectedAttendees: data.expectedAttendees,
        eventStart: data.eventStart,
        contactPerson: data.contactPerson,
        additionalInfo: data.additionalInfo,
        eventDuration: data.eventDuration,
        attendees: data.attendees,
        practice: data.practice,
        status: data.status,
        approvedOn:data.approvedOn,
        processedOn:data.processedOn

      }
    },
    {
      headers: {
        Authorization: "bearer " + token,
        "Content-Type": "application/json"
      }
    }
  );
};
export const sendSurveyEmailAPI = (eventID, token) => {
  return axios.post(
    `${server}/graphql`,
    {
      query: `
      mutation (
        $id: ID!    
    ) {
      surveyEmail (
        id: $id
        ) {
          id
      }
    }
    `,
      variables: {
        id: eventID        
       
      }
    },
    {
      headers: {
        Authorization: "bearer " + token,
        "Content-Type": "application/json"
      }
    }
  );
};

export const DeleteEventAPI = (eventID, token) => {
  return axios.post(
    `${server}/graphql`,
    {
      query: `mutation (
        $id: ID!
        ) {
      deleteEvent(input: {
        where: {
          id: $id
        }
      }) {
        event {
          eventName
        }
      }
    }
    `,
      variables: {
        id: eventID        
       
      }
    },
    {
      headers: {
        Authorization: "bearer " + token,
        "Content-Type": "application/json"
      }
    }
  );
};