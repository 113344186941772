import React, { useEffect } from "react";
import { Grid, Button } from "@material-ui/core";
import MaterialTable from "material-table";
import { connect } from "react-redux";
// import { ModalAlert } from "../../../components/Modal";

import {
  getListPractices,
  archivePractice,
  
  updateGeoLocation
} from "../../../_actions/practices.actions";

// import { Create, Visibility, Archive } from "@material-ui/icons";

import { Create, Visibility } from "@material-ui/icons";
import { Link } from "react-router-dom";
// import { userType } from "../../../_constants";


const ListPractice = ({
  getListPractices,
  archivePractice,
  user,
  practices,
  updateGeoLocation
}) => {
  // const [modalAlert, setModalAlert] = useEffect({
  //   open: false
  // });
  // const setArchivePracticeStatus = id => {
  //   archivePractice(id, true, user.jwt)
  //     .then(data => {
  //       const { practiceName } = data.data.data.updatePractice.practice;
  //       // setModalAlert({
  //       //   open: true,
  //       //   success: false,
  //       //   title: "SUCCESS!",
  //       //   message: `"${practiceName}" in archive`
  //       // })
  //       alert(`"${practiceName}" in archive`);
  //       getListPractices(user.jwt);
  //     })
  //     .catch(err =>
  //       // setModalAlert({
  //       //   open: true,
  //       //   success: false,
  //       //   title: "ERROR!",
  //       //   message: err
  //       // })
  //       alert(err)
  //       );
  // };
 
  useEffect(() => {
    const loadPracticesList = () => {
      getListPractices(user.jwt);
    };

    if (user.jwt) loadPracticesList();
  }, [getListPractices, user.jwt]);
  
  const updateGeoLocationcall = async practiveparam => {

    try {

const data={};
data.id=practiveparam.id;
data.practiceName=practiveparam.practiceName;
data.practiceType=practiveparam.practiceType;
data.contactPhone=practiveparam.contactPhone;
data.address2=practiveparam.address2;
data.state=practiveparam.state;
data.city=practiveparam.city;
data.zip=practiveparam.zip;
data.SalesforceID=practiveparam.SalesforceID?practiveparam.SalesforceID:"";
data.address1=practiveparam.address1;
 console.log("OralSurgeonPathologist");
      await updateGeoLocation(data, user.jwt);
      setTimeout(() => {
        window.open('/#/practice/'+practiveparam.id, '_blank');
      }, 1000);
 
   
 
   
    }
    catch (error) {

    }
  };
  return (
    <React.Fragment>
        {/* {modalAlert.open && (
        <ModalAlert
          close={() => {
            setModalAlert({
              ...modalAlert,
              open: false
            });
            //history.push("/listpractices");
          }}
          success={modalAlert.success}
          title={modalAlert.title}
          message={modalAlert.message}
        />
      )} */}
    <Grid item xs={12}>
      {practices.practices && practices.practices.length > 0 && (
        <MaterialTable
          title="Practices"
          columns={[
            {
              title: "Practice",
              field: "practice",
              sorting: true
            },
            { title: "Contact", field: "contact" },
            {
              title: "Address",
              field: "address",
              sorting: false,
              render: tableData => {
                return (
                  <React.Fragment>
                    {` ${tableData
                      .tablePracticeItem.address1 ||
                      tableData.tablePracticeItem.address2 ||
                      ""}`}
                    <br />
                    {`${tableData.tablePracticeItem.city || ""}${
                      tableData.tablePracticeItem.state
                        ? `, ${tableData.tablePracticeItem.state}`
                        : ""
                    } ${" "+tableData.tablePracticeItem.zip || ""}`}
                  </React.Fragment>
                );
              }
            },
            {
              title: "Email/Phone",
              field: "email",
              render: tableData => {
                return (
                  <React.Fragment>
                    {`${tableData.tablePracticeItem.email || ""}`}
                    <br />
                    {`${tableData.tablePracticeItem.contactPhone || ""}`}
                  </React.Fragment>
                );
              },
            },
            { title: "Type", field: "type" },


            {
              title: "Action",
              field: "action",
              filtering: false,
              render: tableData => {
                return (
                  <div style={{ display: "flex" }}>
                    <Link
                      to={`/editpractice/${tableData.tablePracticeItem.id}`}
                    >
                      <Create />
                    </Link>
                    <Button
                  
                      onClick={() =>updateGeoLocationcall(tableData.tablePracticeItem)}
                    >
                         <Visibility />
                  </Button>
                    
                    {/* <Link to={`/practice/${tableData.tablePracticeItem.id}`}>
                      <Visibility />
                    </Link> */}
                    {/* {user.user.role.type === userType.MANAGER && (
                      <Archive
                        onClick={() =>
                          setArchivePracticeStatus(
                            tableData.tablePracticeItem.id
                          )
                        }
                      />
                    )} */}
                  </div>
                );
              },
              sorting: false
            }
          ]}
          data={practices.practices
            .filter(practice => {
              if (!practice.archived) {
                return true;
              }
              return false;
            })
            .map(practice => {
              return {
                practice: practice.practiceName,
                contact: practice.contactPerson || "",
                address: "",
                email: "",
                type: practice.practiceType.replace(/([A-Z])/g, ' $1'),
                action: "",
                tablePracticeItem: practice
              };
            })}
          options={{
            sorting: true,
            draggable: false,
            filtering: false,
            toolbar: true,
          }}
        />
      )}
      <Link to="/addpractice" className="btn-link">
        <Button variant="contained" color="primary" style={{ marginTop: 20 }}>
          Add Practice
        </Button>
      </Link>
    </Grid>
    </React.Fragment>
  );
};

const mapDispatchToProps = {
  getListPractices: getListPractices,
  archivePractice: archivePractice,
  updateGeoLocation:updateGeoLocation,
};

const mapStateToProps = state => {
  const { user, practices } = state;
  return { user, practices };
};

const connectedListPractice = connect(
  mapStateToProps,
  mapDispatchToProps
)(ListPractice);
export { connectedListPractice as ListPractice };
