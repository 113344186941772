import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { Grid, Typography, Button, Paper } from "@material-ui/core";
import { Map, Marker, Popup, TileLayer } from 'react-leaflet'


const EventsMap = ({ selectedEvent }) => {

 
  const latitude = Number(selectedEvent.latitude);
  const longitude = Number(selectedEvent.longitude);

  const direction = selectedEvent
    ? latitude && longitude
      ? `${latitude},${longitude}`
      : selectedEvent.address1
    : "";

  return (
    <Grid container className="EventsMap">
      <Grid item xs={12} className="map-container">
        {latitude && longitude ? (
          <Paper variant="outlined" square className="map">

            <Map center={[latitude, longitude]} zoom={12}>
              <TileLayer
                attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <Marker position={[latitude, longitude]}>
                <Popup>
                  {selectedEvent.eventName && (
                    <Typography>{selectedEvent.eventName}</Typography>
                  )}
                  {selectedEvent.practice && (
                    <Typography>
                      {`${selectedEvent.practice.address1 ||
                        selectedEvent.practice.address2}, ${
                        selectedEvent.practice.city
                        }`}
                    </Typography>
                  )}
                  <Typography>
                    {selectedEvent.eventStart &&
                      moment(selectedEvent.eventStart).format(
                        "MM.DD.YYYY hh:mmA"
                      )}
                    <Grid container justify="space-between">
                      <a
                        href={`https://www.google.com.ua/maps/dir//${direction}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="btn-link"
                        style={{
                          display:
                            (latitude && longitude) || selectedEvent.address1
                              ? "block"
                              : "none"
                        }}
                      >
                        <Button variant="contained" color="primary">
                          Get directions
                      </Button>
                      </a>
                      {selectedEvent.id && (
                        <Link
                          to={`/event/${selectedEvent.id}`}
                          className="btn-link"
                          style={{
                            marginLeft: 20
                          }}
                        >
                          <Button variant="contained" color="primary">
                            Click for details
                        </Button>
                        </Link>
                      )}
                    </Grid>
                  </Typography>
                </Popup>

              </Marker>
            </Map>
          </Paper>
        ) : (
            <Paper variant="outlined" square className="no-map">
              <Typography>latitude and longitude is empty</Typography>
            </Paper>
          )}
      </Grid>
    </Grid>
  );
};

export { EventsMap };
