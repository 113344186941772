import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Grid, Typography, List, ListItem } from "@material-ui/core";
import { ArrowForward } from "@material-ui/icons";

const ApproveEventsBlock = ({ events }) => {
  const history = useHistory();

  return (
    events.events &&
    events.events.length > 0 &&  (
      <Grid container className="upcomingLNL Dashboard__board">
        <Typography variant="h5">Events to approve</Typography>
        <Grid item xs={12}>
          <List component="nav" className="events-list">
            {events.events.map((event, index) => (
(
  event.invoice!==null && event.status==="complete" &&
(
              <ListItem  
            
                button
                divider
                onClick={() => {
                  history.push("/invoices");
                }}
                key={index}
                className="list-item"
        
              >
                
                <Grid container  alignItems="center" justify="space-between">
                  <Grid item xs={10}>
                    <Typography variant="h6" noWrap>
                      {event.eventName}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <ArrowForward />
                  </Grid>
                </Grid>
   
              </ListItem>
                
            ))))
          }
          </List>
        </Grid>
      </Grid>
    )
  );
};

const mapStateToProps = state => {
  const { events } = state;
  return { events };
};

const connectedApproveEventsBlock = connect(
  mapStateToProps,
  null
)(ApproveEventsBlock);
export { connectedApproveEventsBlock as ApproveEventsBlock };
