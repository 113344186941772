import React, { useState, useEffect } from "react";
// import { ModalAlert } from "../../../components/Modal";
import {
  Grid,
  Typography,
  Button,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel
} from "@material-ui/core";
import MaterialTable from "material-table";
import { Close, Edit, LocationOn, Print } from "@material-ui/icons";

import { EventsMap } from "../../../components/Map";

import { Link, useParams } from "react-router-dom";
import { connect } from "react-redux";

import moment from "moment";

import {
  getListPractices,
  archivePractice

} from "../../../_actions/practices.actions";


const pastUpcomingEventsTableHeader = [
  {
    title: "Event Date",
    field: "eventDate"
  },
  {
    title: "Event Time",
    field: "eventTime"
  },
  {
    title: "Anticipated Attendees",
    field: "attendees"
  },
  {
    title: "Educator",
    field: "educator"
  }
];

const pastUpcomingEventsTableBody =event => {


  return {
    eventDate: moment(event.eventStart).format("MM.DD.YYYY"),
    eventTime: moment(event.eventStart).format("hh:mmA"),
    attendees: event.attendees!==undefined? event.attendees.length:0,
    educator: event.contactPerson
  };
};

const ViewPractice = ({
  getListPractices,
  archivePractice,
  user,
  practices,
 
}) => {
  
  const now = moment();
  const { id } = useParams();
 
  const [practiceData, setPracticeData] = useState(null);
  const [pastUpcomingEvents, setPastUpcomingEvents] = useState({
    upcomingEvents: [],
    pastEvents: []
  });

  const getUpcomingPastEvents = events => {
    let upcomingEvents = [];
    let pastEvents = [];

    events.forEach(event => {
      const eventData = moment(event.eventStart);
      const differentBeetwenNow = now.diff(eventData);
      if (differentBeetwenNow < 0) {
        upcomingEvents.push(event);
      } else {
        pastEvents.push(event);
      }
    });

    setPastUpcomingEvents({
      upcomingEvents,
      pastEvents
    });
  };

  const setArchivePracticeStatus = () => {
    archivePractice(id, true, user.jwt)
      .then(data => {
        const { practiceName } = data.data.data.updatePractice.practice;
        // setModalAlert({
        //   open: true,
        //   success: false,
        //   title: "SUCCESS!",
        //   message: `"${practiceName}" in archive`
        // })
        alert(`"${practiceName}" in archive`);
      })
      .catch(err =>
        // setModalAlert({
        //   open: true,
        //   success: false,
        //   title: "ERROR!",
        //   message: err
        // })
        alert(err)
        
        
        );
  };

  const print = () => {
    const printContents = document.getElementById("print-data");
    const newWindow = window.open("");
    newWindow.document.write(printContents.outerHTML);
    newWindow.print();
    newWindow.close();
  };

  const direction = practiceData
    ? practiceData.latitude && practiceData.longitude
      ? `${practiceData.latitude},${practiceData.longitude}`
      : practiceData.address1
    : "";
  
  useEffect(() => {
    const loadPracticesList = () => {
      getListPractices(user.jwt);
    };
   
    if (user.jwt) loadPracticesList();
  }, [getListPractices, user.jwt]);

  if (!practiceData && practices.practices.length > 0) {
    const practiceID = parseInt(id);
    const currentPractice = practices.practices.find(practice => {
      if (Number(practice.id) === practiceID) return true;
      return false;
    });


 
 
  if(currentPractice.events.length>0)
  {
    getUpcomingPastEvents(currentPractice.events);
  }
    setPracticeData(currentPractice);
    
    
  }

  
 

  

  return (
    <React.Fragment>
         {/* {modalAlert.open && (
        <ModalAlert
          close={() => {
            setModalAlert({
              ...modalAlert,
              open: false
            });
            //history.push("/listpractices");
          }}
          success={modalAlert.success}
          title={modalAlert.title}
          message={modalAlert.message}
        />
      )} */}
      {practiceData && (
        <Grid item xs={12} className="AddEvent">
          <Grid container justify="space-between">
            <Grid item xs={12} md={6}>
              <Typography variant="h5">{practiceData.practiceName}</Typography>
            </Grid>
            <Grid item xs={12} md={6} className="event-actions">
              <a
                href={`https://www.google.com.ua/maps/dir//${direction}`}
                target="_blank"
                rel="noopener noreferrer"
                className="btn-link event-actions__btn"
                style={{
                  display:
                    (practiceData.latitude && practiceData.longitude) ||
                    practiceData.address1
                      ? "block"
                      : "none"
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                
                >
                  <LocationOn />
                  DIRECTIONS
                </Button>
              </a>
              <Link to={`/editpractice/${id}`} className="btn-link">
                <Button
                  variant="contained"
                  color="primary"
                
                >
                  <Edit />
                  EDIT
                </Button>
              </Link>
              <Button
                variant="contained"
                color="primary"
                className="event-actions__btn"
                onClick={() => print()}
              >
                <Print />
                PRINT
              </Button>
            </Grid>
          </Grid>
          <Grid container>
            <Typography variant="h6">Practice Information</Typography>
            <Grid container alignItems="flex-end">
              <Grid item xs={12} md={6} className="input-item">
                <TextField
                  id="new-practice"
                  fullWidth
                  variant="outlined"
                  label="Practice Name"
                  value={practiceData.practiceName}
                  disabled
                />
              </Grid>
            </Grid>
            <Grid container alignItems="flex-end">
              <Grid item xs={6} md={3} className="input-item">
                <TextField
                  id="address1"
                  fullWidth
                  variant="outlined"
                  label="Address 1"
                  value={practiceData.address1}
                  disabled
                />
              </Grid>
              {practiceData.address2 && (
                <Grid item xs={6} md={3} className="input-item">
                  <TextField
                    id="address2"
                    fullWidth
                    variant="outlined"
                    label="Address 2"
                    value={practiceData.address2}
                    disabled
                  />
                </Grid>
              )}
              
              <Grid item xs={6} md={2} className="input-item">
                <TextField
                  id="city"
                  fullWidth
                  variant="outlined"
                  label="City"
                  value={practiceData.city}
                  disabled
                />
              </Grid>
              <Grid item xs={6} md={2} className="input-item">
                <TextField
                  id="state"
                  fullWidth
                  variant="outlined"
                  label="State"
                  value={practiceData.state}
                  disabled
                />
              </Grid>
              <Grid item xs={6} md={2} className="input-item">
                <TextField
                  id="zip"
                  fullWidth
                  variant="outlined"
                  label="Zip code"
                  value={practiceData.zip}
                  disabled
                />
              </Grid>
            </Grid>
            <Grid container alignItems="flex-end">
              <Grid item xs={6} md={4} className="input-item">
                <TextField
                  id="email"
                  fullWidth
                  variant="outlined"
                  label="E-Mail"
                  value={practiceData.email}
                  disabled
                />
              </Grid>
              <Grid item xs={6} md={4} className="input-item">
                <TextField
                  fullWidth
                  variant="outlined"
                  id="phone-number"
                  label="Contact Phone"
                  value={practiceData.contactPhone}
                  disabled
                  placeholder="+1 (___)__-__-___-__"
                />
              </Grid>
              <Grid item xs={6} md={4} className="input-item">
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="practice-type-label">
                    Practice Type
                  </InputLabel>
                  <Select
                    labelId="practice-type-label"
                    id="practice-type"
                    fullWidth
                    value={practiceData.practiceType}
                    disabled
                  >
                   <MenuItem value={"Endodontist"}>Endodontist</MenuItem>
                  <MenuItem value={"GeneralDentistry"}>General Dentistry</MenuItem>
                  <MenuItem value={"OralSurgeonPathologist"}>Oral Surgeon/Pathologist</MenuItem>
                  <MenuItem value={"Orthodontist"}>Orthodontist</MenuItem>
                  <MenuItem value={"PediatricDentistry"}>Prosthodontist</MenuItem>
                  <MenuItem value={"Prosthodontist"}>Prosthodontist</MenuItem>
                  <MenuItem value={"DDSDMDStudent"}>DDS/DMD Student</MenuItem>
                  <MenuItem value={"RDHStudent"}>RDH Student</MenuItem>
                  <MenuItem value={"Other"}>Other</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Typography variant="h6">Practice contact Person</Typography>
            <Grid container alignItems="flex-end">
              <Grid item xs={12} md={6} className="input-item">
                <TextField
                  id="full-name"
                  fullWidth
                  variant="outlined"
                  label="Full Name"
                  value={practiceData.contactPerson}
                  disabled
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Typography variant="h6">Map</Typography>
            <EventsMap
              selectedEvent={{
                latitude: practiceData.latitude,
                longitude: practiceData.longitude,
                practice: {
                  address1: practiceData.address1,
                  address2: practiceData.address2,
                  city: practiceData.city
                }
              } } 
            />
          </Grid>
          <Grid container justify="space-between" className="other-practices">
            <Grid item xs={12} md={6} className="other-practices__wrapper">
              <MaterialTable
                title="Upcoming Events"
                columns={pastUpcomingEventsTableHeader}
                data={pastUpcomingEvents.upcomingEvents.map(event =>
                
                
                  pastUpcomingEventsTableBody(event)
                )}
                options={{ draggable: false, search: false }}
              />
            </Grid>
            <Grid item xs={12} md={6} className="other-practices__wrapper">
              <MaterialTable
                title="Past Events"
                columns={pastUpcomingEventsTableHeader}
                data={pastUpcomingEvents.pastEvents.map( event =>
               
                  pastUpcomingEventsTableBody(event)
                )}
                options={{ draggable: false, search: false }}
              />
            </Grid>
          </Grid>
          <Grid container justify="space-between">
            <Button
              variant="contained"
              color="secondary"
              className="btn"
              onClick={setArchivePracticeStatus}
            >
              <Close />
              DELETE PRACTICE
            </Button>
          </Grid>
          <div id="print-data">
            <table style={{ border: "1px solid #ddd", width: "100%" }}>
              <tbody>
                <tr>
                  <td
                    style={{
                      border: "1px solid #ddd",
                      padding: 8,
                      width: "50%"
                    }}
                  >
                    <b>Practice Name</b>
                  </td>
                  <td
                    style={{
                      border: "1px solid #ddd",
                      padding: 8,
                      width: "50%"
                    }}
                  >
                    {practiceData.practiceName}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: "1px solid #ddd",
                      padding: 8,
                      width: "50%"
                    }}
                  >
                    <b>Address 1</b>
                  </td>
                  <td
                    style={{
                      border: "1px solid #ddd",
                      padding: 8,
                      width: "50%"
                    }}
                  >
                    {practiceData.address1}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: "1px solid #ddd",
                      padding: 8,
                      width: "50%"
                    }}
                  >
                    <b>Address 2</b>
                  </td>
                  <td
                    style={{
                      border: "1px solid #ddd",
                      padding: 8,
                      width: "50%"
                    }}
                  >
                    {practiceData.address2}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: "1px solid #ddd",
                      padding: 8,
                      width: "50%"
                    }}
                  >
                    <b>City</b>
                  </td>
                  <td
                    style={{
                      border: "1px solid #ddd",
                      padding: 8,
                      width: "50%"
                    }}
                  >
                    {practiceData.city}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: "1px solid #ddd",
                      padding: 8,
                      width: "50%"
                    }}
                  >
                    <b>State</b>
                  </td>
                  <td
                    style={{
                      border: "1px solid #ddd",
                      padding: 8,
                      width: "50%"
                    }}
                  >
                    {practiceData.state}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: "1px solid #ddd",
                      padding: 8,
                      width: "50%"
                    }}
                  >
                    <b>Zip code</b>
                  </td>
                  <td
                    style={{
                      border: "1px solid #ddd",
                      padding: 8,
                      width: "50%"
                    }}
                  >
                    {practiceData.zip}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: "1px solid #ddd",
                      padding: 8,
                      width: "50%"
                    }}
                  >
                    <b>E-Mail</b>
                  </td>
                  <td
                    style={{
                      border: "1px solid #ddd",
                      padding: 8,
                      width: "50%"
                    }}
                  >
                    {practiceData.email}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: "1px solid #ddd",
                      padding: 8,
                      width: "50%"
                    }}
                  >
                    <b>Contact Phone</b>
                  </td>
                  <td
                    style={{
                      border: "1px solid #ddd",
                      padding: 8,
                      width: "50%"
                    }}
                  >
                    {practiceData.contactPhone}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: "1px solid #ddd",
                      padding: 8,
                      width: "50%"
                    }}
                  >
                    <b>Practice Type</b>
                  </td>
                  <td
                    style={{
                      border: "1px solid #ddd",
                      padding: 8,
                      width: "50%"
                    }}
                  >
                    {practiceData.practiceType}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: "1px solid #ddd",
                      padding: 8,
                      width: "50%"
                    }}
                  >
                    <b>Contact Person</b>
                  </td>
                  <td
                    style={{
                      border: "1px solid #ddd",
                      padding: 8,
                      width: "50%"
                    }}
                  >
                    {practiceData.contactPerson}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Grid>
      )}
    </React.Fragment>
  );
};

const mapDispatchToProps = {
  getListPractices: getListPractices,
  archivePractice: archivePractice

};

const mapStateToProps = state => {
  const { practices, user } = state;
  return { practices, user };
};

const connectedViewPractice = connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewPractice);
export { connectedViewPractice as ViewPractice };
