import { surveysConstants } from "../_constants";

const initialState = {
  surveys: [],
  pending: false,
  error: ""
};

export const surveys = (state = initialState, action) => {
  switch (action.type) {
    case surveysConstants.GET_SURVEY_LIST_REQUEST:
     return {
        ...state,
        pending: true,
        error: ""
      };
    case surveysConstants.GET_SURVEY_LIST_SUCCESS:
      return {
        ...state,
        pending: false,
        surveys: action.payload,
        error: ""
      };
    case surveysConstants.GET_SURVEY_LIST_ERROR:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    case surveysConstants.GET_SURVEY_BY_ID_REQUEST:
      return {
        ...state,
        pending: true,
        error: ""
      };

    case surveysConstants.GET_SURVEY_BY_ID_SUCCESS:
      return {
        ...state,
        pending: false,
        error: ""
      };

    case surveysConstants.GET_SURVEY_BY_ID_ERROR:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    case surveysConstants.ADD_SURVEY_REQUEST:
      return {
        ...state,
        pending: true,
        error: ""
      };

    case surveysConstants.ADD_SURVEY_SUCCESS:
      return {
        ...state,
        pending: false,
        error: ""
      };

    case surveysConstants.ADD_SURVEY_ERROR:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    default:
      return {
        ...state
      };
  }
};
