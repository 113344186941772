import { resourcesConstants } from "../_constants";
import { getListResourcesByCategoryAPI } from "../_api/resources";
import { parseGraphQLError } from "../_helpers/errors";

export const getListResourcesByCategory = (token, category) => dispatch => {
  dispatch({ type: resourcesConstants.GET_RESOURCES_BY_CATEGORY_REQUEST });

  getListResourcesByCategoryAPI(token, category)
    .then(data => {
      dispatch({
        type: resourcesConstants.GET_RESOURCES_BY_CATEGORY_SUCCESS,
        payload: data.data.data.resources
      });
    })
    .catch(error => {
      let errorMessage = parseGraphQLError(error);
      
      dispatch({
        type: resourcesConstants.GET_RESOURCES_BY_CATEGORY_ERROR,
        payload: errorMessage
      });
    });
};
