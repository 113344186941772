import { userConstants, userType } from "../_constants";

const initialState = {
  isAuth: null,
  jwt: "",
  user: {
    username: "",
    id: null,
    email: "",
    provider: "local",
    confirmed: false,
    blocked: false,
    role: { id: null, name: "", description: "", type: userType.DEFAULT },
    created_at: "",
    updated_at: "",
    firstName: null,
    lastName: null,
    contactPhone: null,
    address1: null,
    address2: null,
    city: null,
    state: null,
    zip: null,
    discountCode: null,
    contractStart: null,
    contractEnd: null
  },
  pending: false,
  error: ""
};

export const user = (state = initialState, action) => {
  switch (action.type) {
    case userConstants.USER_LOGIN_REQUEST:
      return {
        ...state,
        pending: true,
        error: ""
      };
    case userConstants.USER_LOGIN_SUCCESS:
      return {
        ...state,
        isAuth: true,
        jwt: action.payload.jwt,
        user: {
          ...action.payload.user
        },
        pending: false,
        error: ""
      };
    case userConstants.USER_LOGIN_ERROR:
      return {
        ...state,
        isAuth: false,
        pending: false,
        error: action.payload
      };

    case userConstants.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        pending: true,
        error: ""
      };
    case userConstants.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isAuth: true,
        jwt: action.payload.jwt,
        user: {
          ...action.payload.user
        },
        pending: false,
        error: ""
      };
    case userConstants.RESET_PASSWORD_ERROR:
      return {
        ...state,
        isAuth: false,
        pending: false,
        error: action.payload
      };

    case userConstants.USER_LOGOUT:
      return {
        ...state,
        isAuth: false,
        user: {
          ...initialState.user
        },
        pending: false,
        error: ""
      };

      case userConstants.USER_EMAIL_REQUEST:
        return {
          ...state,
          pending: true,
          error: ""
        };
      case userConstants.USER_EMAIL_SUCCESS:
        return {
          ...state,
          isAuth: true,
          jwt: action.payload.jwt,
          user: {
            ...action.payload.user
          },
          pending: false,
          error: ""
        };
      case userConstants.USER_EMAIL_ERROR:
        return {
          ...state,
          isAuth: false,
          pending: false,
          error: action.payload
        };
    default:
      return {
        ...state
      };
  }
};
