import React from "react";
import { Typography } from "@material-ui/core";

const ViewEmail = () => {
  // let { id } = useParams();
  const data= JSON.parse(localStorage.getItem("currentemail"));
  console.log(data);
  return(
    <div>
       <Typography variant="h5">Email Information</Typography>
        <React.Fragment>
        
            < div className="email-detail">
              <Typography variant="h6"><span >Subject</span></Typography>
              <Typography variant="body1">{data.subject}</Typography>
              <Typography variant="h6"><span >From</span></Typography>
              <Typography variant="body1">{data.from}</Typography>
              <Typography variant="h6"><span >To</span></Typography>
              <Typography variant="body1">{data.email}</Typography>
              <Typography variant="h6"><span >Date</span></Typography>
              <Typography variant="body1">{data.date}</Typography>
              <Typography variant="h6"><span >Details</span></Typography>
              <Typography variant="body1">{data.body} </Typography>
              {/* <Typography variant="h6"><span >details2</span></Typography>
              <Typography variant="body1">{data.body} </Typography> */}
            </div>
        
        </React.Fragment>
    </div>
  );
};

export { ViewEmail };