import axios from "axios";
import { server } from "../_constants";

export const getListUsersAPI = token => {

  return axios.post(
    `${server}/graphql`,
    {
      query: `
        query {
            users {
                id
                confirmed
                blocked
                username
                email
                role {
                    id
                    name
                    type
                }
                firstName
                lastName
                contactPhone
                address1
                address2
                city
                state
                zip
                discountCode
                contractStart
                contractEnd
                photo {
                    id
                    name
                    url
                    provider
                    mime
                    provider_metadata
                }
                Territory

            }
        }
    `
    },
    {
      headers: {
        Authorization: "bearer " + token,
        "Content-Type": "application/json"
      }
    }
  );
};

export const getListUsersPublicAPI=()  => {

  return axios.post(
    `${server}/graphql`,
    {
      query: `
        query {
            users {
                id
                confirmed
                blocked
                username
                email
                role {
                    id
                    name
                    type
                }
                firstName
                lastName
                contactPhone
                address1
                address2
                city
                state
                zip
                discountCode
                contractStart
                contractEnd
                photo {
                    id
                    name
                    url
                    provider
                    mime
                    provider_metadata
                }
                Territory

            }
        }
    `
    },
    {
      headers: {
      
        "Content-Type": "application/json"
      }
    }
  );
};
export const getUserByIDAPI = (userID, token) => {
  return axios.post(
    `${server}/graphql`,
    {
      query: `
        query (
          $id: ID!    
        ) {
        user (
          id: $id
          ) {
            id
            confirmed
            blocked
            username
            email
            role {
                id
                name
                type
            }
            firstName
            lastName
            contactPhone
            address1
            address2
            city
            state
            zip
            discountCode
            contractStart
            contractEnd
         
            photo {
              id
              name
              url
              provider
              mime
              provider_metadata
            }
            Territory
          }
        }
      `,
      variables: {
        id: userID
      }
    },
    {
      headers: {
        Authorization: "bearer " + token,
        "Content-Type": "application/json"
      }
    }
  );
};

export const addNewUserAPI = (data, token) => {

  return axios.post(
    `${server}/graphql`,
    {
      query: `
      mutation (
        $username: String!, 
        $email: String!, 
        $password: String!, 
        $role: ID!,
        $firstName: String,
        $lastName: String,
        $contactPhone: String,
        $address1: String,
        $address2: String,
        $city: String,
        $Territory:String,
        $state: String,
        $zip: String,
        $discountCode: String,
        $contractStart: DateTime,
        $contractEnd: DateTime
        $photo: ID) {
      createUser(input: {
        data: {
          username: $username,
          email: $email,
          password: $password,
          role: $role,
          firstName: $firstName,
          lastName: $lastName,
          contactPhone: $contactPhone,
          address1: $address1,
          address2: $address2,
          city: $city,
          Territory:$Territory,
          state: $state,
          zip: $zip,
          discountCode: $discountCode,
          contractStart: $contractStart,
          contractEnd: $contractEnd,
          photo: $photo
        }
      }) {
        user {
          id
        }
      }
    }
      `,
      variables: {
        username: data.username,
        email: data.email,
        password: data.password,
        role: Number(data.role),
        firstName: data.firstName,
        lastName: data.lastName,
        contactPhone: data.contactPhone,
        address1: data.address1,
        address2: data.address2,
        city: data.city,
        Territory:data.Territory,
        state: data.state,
        zip: data.zip,
        discountCode: data.discountCode,
        contractStart: data.contractStart,
        contractEnd: data.contractEnd,
        photo: data.photo
      }
    },
    {
      headers: {
        Authorization: "bearer " + token,
        "Content-Type": "application/json"
      }
    }
  );
};

export const editUserAPI = (data, token) => {
  return axios.post(
    `${server}/graphql`,
    {
      query: `
      mutation (
        $id: ID!,
        $username: String!, 
        $email: String!, 
        $password: String!, 
        $role: ID!,
        $firstName: String,
        $lastName: String,
        $contactPhone: String,
        $address1: String,
        $address2: String,
        $city: String,
        $territory:String,
        $state: String,
        $zip: String,
        $discountCode: String,
        $contractStart: DateTime,
        $contractEnd: DateTime
        $photo: ID) {
        updateUser(input: {
            where: {
                id: $id
            }
            data: {
              username: $username,
              email: $email,
              password: $password,
              role: $role,
              firstName: $firstName,
              lastName: $lastName,
              contactPhone: $contactPhone,
              address1: $address1,
              address2: $address2,
              city: $city,
              Territory:$territory,
              state: $state,
              zip: $zip,
              discountCode: $discountCode,
              contractStart: $contractStart,
              contractEnd: $contractEnd,
              photo: $photo,
              IsLogin:true
            }
          }) {
            user {
              confirmed
              blocked
              username
              email
              firstName
              lastName
              contactPhone
              address1
              address2
              city
              Territory
              state
              zip
              discountCode
              contractStart
              contractEnd
              photo {
                id
                name
                url
                provider
                mime
                provider_metadata
              }
            }
          }
        }
      `,
      variables: {
        id: data.id,
        username: data.username,
        email: data.email,
        password: data.password,
        role: data.role.id,
        firstName: data.firstName,
        lastName: data.lastName,
        contactPhone: data.contactPhone,
        address1: data.address1,
        address2: data.address2,
        city: data.city,
        territory:data.Territory,
        state: data.state,
        zip: data.zip,
        discountCode: data.discountCode,
        contractStart: data.contractStart,
        contractEnd: data.contractEnd,
        photo: data.photo
      }
    },
    {
      headers: {
        Authorization: "bearer " + token,
        "Content-Type": "application/json"
      }
    }
  );
};
export const deleteUserAPI = (userID, token) => {

  return axios.post(
    `${server}/graphql`,
    {
      query: `mutation (
        $id: ID!
        ) {
          deleteUser(input: {
        where: {
          id: $id
        }
      }) {
        user {
          username
        }
      }
    }
    `,
      variables: {
        id: userID        
       
      }
    },
    {
      headers: {
        Authorization: "bearer " + token,
        "Content-Type": "application/json"
      }
    }
  );
};

export const getUserByEmail=(UserEmail,token)=>
{

  return axios.post(
    `${server}/graphql`,
    {
      query: `query  {
 
        users  (
               where: {email_contains:"${UserEmail.email.toString()}" }
              )  {   
                      id
                      confirmed
                      blocked
                      username
                      email
                      role {
                          id
                          name
                          type
                      }
                      firstName
                      lastName
                      contactPhone
                      address1
                      address2
                      city
                      state
                      zip
                      discountCode
                      contractStart
                      contractEnd
                      photo {
                          id
                          name
                          url
                          provider
                          mime
                          provider_metadata
                      }
                      Territory
      
                  }
      }
      
    `
    },
    {
      headers: {
        Authorization: "bearer " + token,
        "Content-Type": "application/json"
      }
    }
  );
};